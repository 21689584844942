import { MongooseDocument, MongooseResource } from "./_abstractResource";
import { SchemaDefinition, Schema, Types } from "mongoose";

/**
 * @type {CardReadingDocument} represents a reading of a card. @see {CardDocument}
 */
export interface CardReadingDocument extends MongooseDocument {
  /** Holds a reference to the reader which did the reading */
  readerId: Types.ObjectId;
  /** Date and time when the card was read */
  createdAt: Date;
  /** Internal RFID code of the card */
  rfid: string;
  /** Holds a reference to the card that was read - if known */
  cardId?: Types.ObjectId;
  /* Virtual field - convenience property to easily check if there is a linked card */
  isCardKnown: boolean;
}
/**
 * @type {CardReadingResource} defines the API
 * and database model for a RFID card reading
 * connected to the reader which read the card.
 */
export class CardReadingResource extends MongooseResource<CardReadingDocument> {
  /** CardReading database schema */
  static Schema: SchemaDefinition = {
    readerId: { type: Schema.Types.ObjectId, ref: "reader", required: true },
    cardId: { type: Schema.Types.ObjectId, ref: "card", required: false },
    rfid: { type: String, required: true },
  };

  constructor() {
    super();
    this.setName("cardreading");
    this.setSchema(CardReadingResource.Schema);
    this.addVirtualField("isCardKnown", this.isCardKnown);
  }

  /**
   * Returns the cardId field as a boolean for convenience.
   * @param document the card reading document
   */
  private isCardKnown(document: CardReadingDocument): boolean {
    return !!document.cardId;
  }
}
