import * as React from "react";
import { MobxComponent } from "../commons";

export class Toggle extends MobxComponent<{
  active?: boolean;
  disabled?: boolean;
  className?: string;
  onToggle: (state: boolean) => any;
}> {
  componentDidMount() {
    this.domElement.checkbox({
      onChecked: () => this.props.onToggle(true),
      onUnchecked: () => this.props.onToggle(false),
    });
  }

  render() {
    return (
      <span className={"ui toggle checkbox " + this.props.className}>
        <input
          type="checkbox"
          disabled={this.props.disabled}
          defaultChecked={this.props.active}
        />
      </span>
    );
  }
}
