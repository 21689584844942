import Lodash from "lodash";
import * as Mongoose from "mongoose";
import { Schema, Types } from "mongoose";
import { PrivilegeDocument } from "../schemas/privilege";
import { MongooseDocument, MongooseResource } from "./_abstractResource";
import { RoleDocument } from "./role.resource";
export { PrivilegeDocument as UserRole } from "../schemas/privilege";

export interface UserDocument
  extends MongooseDocument,
    Mongoose.PassportLocalDocument {
  id: string;
  username: string;
  _id: Mongoose.Types.ObjectId;
  _type: string;
  _roles: string[];
  passwordResetToken: string;
  passwordResetDate: Date;
  forcePasswordReset: boolean;
  facebookID?: string;
  organization: Types.ObjectId;
  rolesV2: RoleDocument[];
  isAdmin: boolean;
  roles: PrivilegeDocument[];
  personNr: string;
  orgNr: string;
  passcode: string;
  firstname: string;
  lastname: string;
  name: string;
  ice?: string;
  company?: string;
  email?: string;
  phoneNr?: string;
  selfRegistered?: boolean;
  /** Genetive name based on swedish language rules */ genetiveFirstname: string;
}

export class UserResource extends MongooseResource<UserDocument> {
  /** The authentication roles this user resource has */
  private roles: string[];

  static IsAUserDocument(object: any): object is UserDocument {
    return "firstname" in object;
  }

  constructor() {
    super();
    this.setName("default");

    // Set a subpath
    this.addToPath("user");

    // TODO: we should check this on the client side as it breaks encryption
    /** A method that fixes the personnr before it is saved in the database */
    function fixPersonNr(inputValue) {
      inputValue = inputValue.replace("-", ""); // No Dashes
      inputValue = inputValue.substring(inputValue.length - 10); // Last 10 characters
      return inputValue;
    }

    // Add schema fields
    this.setSchema({
      passwordResetToken: String,
      passwordResetDate: Date,
      forcePasswordReset: Boolean,
      facebookID: { type: String, required: false },
      username: { type: String, lowercase: true, trim: true },
      organization: {
        type: Schema.Types.ObjectId,
        ref: "organization",
        required: true,
      },
      isAdmin: { type: Boolean, default: false },
      rolesV2: [
        {
          type: Schema.Types.ObjectId,
          ref: "role",
          required: true,
        },
      ],
      roles: [Schema.Types.Mixed],
      personNr: {
        type: String,
        required: true,
        unique: true,
        /* set: fixPersonNr, */
      },

      orgNr: { type: String, required: true },
      passcode: { type: String },

      firstname: { type: String, required: true },
      lastname: { type: String, required: true },
      ice: String,
      company: { type: String },
      email: String,
      phoneNr: String,
      selfRegistered: { type: Schema.Types.Boolean, default: false },
    });

    this.addVirtualField("name", (user) => {
      return `${user.firstname} ${user.lastname}`;
    });
  }

  // Requests

  login(query: {
    username?: string;
    password?: string;
    personNr?: string;
    passcode?: string;
  }) {
    return this.sendRequest<UserDocument>("/passcodelogin", "post", {
      username: query.username,
      password: query.password,
      personNr: query.personNr,
      passcode: query.passcode,
    });
  }

  logout() {
    return this.sendRequest<boolean>("/logout", "post", {});
  }

  updateDocument(document: UserDocument, loginAsUser: boolean = false) {
    return new Promise<string>((resolve, reject) => {
      this.validateDocument(document).then(
        () =>
          resolve(
            this.sendRequest<string>("/update", "post", {
              document: document,
              login: loginAsUser,
            })
          ),
        (err) => {
          reject(err);
        }
      );
    });
  }

  createResetToken(_id: Mongoose.Types.ObjectId): any {
    return this.sendRequest<boolean>("/createResetToken", "post", { _id });
  }

  changePassword(
    _id: Mongoose.Types.ObjectId | string,
    newPassword: string,
    resetToken: string
  ) {
    return this.sendRequest<any>("/changePassword", "post", {
      _id,
      newPassword,
      resetToken,
    });
  }

  validateAuthentication(role?: string, username?: string, password?: string) {
    return this.sendRequest<boolean>("/authenticate", "post", {
      role,
      username,
      password,
    });
  }

  isAuthenticated() {
    return this.sendRequest<boolean>("/isauthenticated", "post", {});
  }

  findOneUser(query: Object) {
    return this.sendRequest<UserDocument>("/findOneUser", "post", { query });
  }

  // Other methods

  /** Checks if this user resource has the asked for role(s) */
  public documentHasRole(
    document: UserDocument,
    requiredRole: PrivilegeDocument
  ): boolean {
    let found = false;

    Lodash.forEach(document.roles, (UserRole) => {
      found = this.doesRoleMatch(requiredRole, UserRole);

      return !found; // continue iteration if not found
    });

    return found;
  }

  /** Checks that a given role validates against a searched for requestedRole  */
  private doesRoleMatch(
    requiredRole: PrivilegeDocument,
    attemptRole: PrivilegeDocument
  ): boolean {
    // System owners rulez
    if (
      attemptRole.type == "system.owner" &&
      requiredRole.type == "system.owner"
    ) {
      return true;
    }

    // Fail if we are searching for a role with an organization, and the attempt role either doesn't have an organization set, or the organization doesn't match.
    if (
      !!requiredRole.organization &&
      (!attemptRole.organization ||
        requiredRole.organization.toString() !=
          attemptRole.organization.toString())
    ) {
      return false;
    }

    // Organization managers that have access to all properties in that organization
    if (
      (requiredRole.type == "property.crew" ||
        requiredRole.type == "property.manager") &&
      attemptRole.type == "organization.manager"
    ) {
      return true;
    }

    // Fail if the types of the searched for role and the attempt roles doesn't match
    if (requiredRole.type != attemptRole.type) {
      return false;
    }

    // Fail if we are searching for a role with a property field, and the attempt role either doesn't have an property set, or the property doesn't match.
    if (
      !!requiredRole.property &&
      (!attemptRole.property ||
        requiredRole.property.toString() != attemptRole.property.toString())
    ) {
      return false;
    }

    // Nothing left to rule out!
    return true;
  }
}
