import * as React from "react";
import { Alert, Field, Form, Input, KosmicComponent, Rules } from "../commons";

import { CardResource } from "../../resources/card.resource";
import { PropertyDocument } from "../../resources/property.resource";
import { RoleResource } from "../../resources/role.resource";
import { UserResource } from "../../resources/user.resource";

interface Props {
  propertyDocument?: PropertyDocument;
}

interface State {
  /**
   * Indicates that the social security number already exists
   * in the database.
   */
  personNrExists: boolean;
  /**
   * True when application has sent a request to see if
   * the social security number already exist, and is waiting for
   * a response.
   */
  findingPersonNr: boolean;
  /**
   * Indicates that the personal card number already exists
   * in the database.
   */
  personalCardNrExists: boolean;
  /**
   * True when application has sent a request to see if
   * the personal card number already exist, and is waiting for
   * a response.
   */
  findingPersonalCardNr: boolean;
}

/**
 * @class AddEmployeeView renders a form that allows for
 * creation of @type UserDocument
 */
export class AddEmployeeView extends KosmicComponent<Props, State> {
  // Constructor initializes the state of the component
  constructor(props: Props) {
    super(props);
    this.state = {
      personNrExists: false,
      findingPersonNr: false,
      personalCardNrExists: false,
      findingPersonalCardNr: false,
    };
  }

  // Called as an onSubmit callback. Creates the actual
  // document and associated card document.
  addEmployee = async (values, resolve, reject) => {
    try {
      const personNr = String($('[name="Personnummer"]')!.val())!
        .replaceAll("-", "")
        .trim();

      if (
        !$('[name="orgNr"]').val() ||
        !$('[name="Personalkortsnummer"]').val() ||
        !personNr.length ||
        !$('[name="Förnamn"]').val() ||
        !$('[name="Efternamn"]').val()
      ) {
        Alert.show(
          "Du måste ange en organisationsnummer, personalkortsnummer, personummer, förnamn och efternamn.",
          "Det gick inte att spara till databasen",
          "error",
          "försök igen"
        );
        return reject();
      }

      const resource = new UserResource();
      const role = await new RoleResource().findOne({
        organization: this.globals.session.currentUser!.organization,
        name: "Organization Member",
      });

      const document = resource.createDocument({
        organization: this.globals.session.currentUser!.organization,
        roles: [],
        personNr: personNr,
        passcode: $('[name="Personlig kod"]').val(),
        firstname: $('[name="Förnamn"]').val(),
        lastname: $('[name="Efternamn"]').val(),
        phoneNr: $('[name="Telefonnummer"]').val(),
        email: $('[name="E-post"]').val(),
        company: $('[name="company"]').val(),
        orgNr: $('[name="orgNr"]').val(),
        username: personNr,
        rolesV2: [role._id],
      });

      const values = {
        rfid: $('[name="Personalkortsnummer"]').val(),
        user: document._id,
      };

      const cardResource = new CardResource();
      const cardDocument = cardResource.createDocument(values);
      await cardResource.updateDocument(cardDocument);
      await resource.updateDocument(document);
      this.globals.session.navigateTo("/admin/employees");
      return resolve();
    } catch (err) {
      console.log(err);
      Alert.show(
        "Kontrollera att du har en internetanslutning, om problemet kvarstår, kontakta support.",
        "Det gick inte att spara till databasen",
        "error",
        "försök igen"
      );
      return reject();
    }
  };

  // Callback running when the social security number has changed.
  handlePersonNrInput = async (value) => {
    await this.setState({ findingPersonNr: true });

    let personNr = value.replace("-", "");
    personNr = personNr.substring(personNr.length - 10);

    if ($('[name="Personlig kod"]').val() == "") {
      $('[name="Personlig kod"]').val(personNr.substring(6));
    }

    const result = await new UserResource().find({ personNr: personNr });
    this.setState({
      findingPersonNr: false,
      personNrExists: !!result && result.length > 0,
    });
  };

  //Callback running when the personal card number has been changed.
  handlePersonalCardInput = async (value) => {
    await this.setState({ findingPersonalCardNr: true });

    let rfid = value;

    const personalCardNrResult = await new CardResource().find({ rfid });
    this.setState({
      findingPersonalCardNr: false,
      personalCardNrExists:
        !!personalCardNrResult && personalCardNrResult.length > 0,
    });
  };

  navigateBack = () => this.globals.session.navigateTo("/admin/employees");

  render() {
    const searchInputClasses =
      "ui icon input " + (this.state.findingPersonNr ? "loading " : "");
    const searchInputIcon =
      this.state.personNrExists && !this.state.findingPersonNr
        ? "remove"
        : "user";

    return (
      <div>
        <h2>
          <i>Lägg till ny personal</i>
        </h2>
        <Form onSubmit={this.addEmployee} className="ui attached segment">
          <Field label="Personnummer">
            <Input
              placeholder="ååmmddxxxx"
              name="Personnummer"
              icon={searchInputIcon}
              className="required"
              onChange={this.handlePersonNrInput}
              rules={[
                Rules.ExactLength(10),
                Rules.CustomRule({
                  validator: (value) =>
                    !this.state.personNrExists && !this.state.findingPersonNr,
                  prompt: "Personnumret är redan registrerat!",
                }),
              ]}
            />
          </Field>
          <Field label="Förnamn">
            <Input
              name="Förnamn"
              className="required"
              rules={[Rules.NotEmpty()]}
            />
          </Field>
          <Field label="Efternamn">
            <Input
              name="Efternamn"
              className="required"
              rules={[Rules.NotEmpty()]}
            />
          </Field>
          <br />
          <Field label="Personlig kod">
            <Input
              name="Personlig kod"
              className="required"
              rules={[Rules.IsANumber(), Rules.ExactLength(6)]}
            />
          </Field>
          <Field label="Byggverksamhetsbedrivare">
            <Input
              name="company"
              placeholder="företagsnamn"
              className="required"
            />
          </Field>
          <Field>
            <Input
              name="orgNr"
              placeholder="organisationsnummer"
              className="required"
              rules={[Rules.NotEmpty()]}
            />
          </Field>
          <br />

          <Field label="Epost">
            <Input name="E-post" />
          </Field>
          <Field label="Telefonnummer">
            <Input name="Telefonnummer" />
          </Field>
          <br />

          <Field label="Personalkortsnummer">
            <Input
              name="Personalkortsnummer"
              placeholder="1234567890123456789"
              onChange={this.handlePersonalCardInput}
              rules={[
                Rules.NotEmpty(),
                Rules.CustomRule({
                  validator: (value) =>
                    !this.state.personalCardNrExists &&
                    !this.state.findingPersonalCardNr,
                  prompt: "Personalkortsnumret är redan registrerat",
                }),
              ]}
            />
          </Field>
          <br />

          <a className="ui red button" onClick={this.navigateBack}>
            <i className="left arrow icon" />
            Avbryt
          </a>
          <a className="ui green submit right floated button">
            <i className="save icon" />
            Spara ny personal
          </a>
        </Form>
      </div>
    );
  }
}
