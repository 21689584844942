import Lodash from "lodash";
import React, { createContext, useContext, useState } from "react";

/** The default timeout before a search is performed, in milliseconds. */
const DEFAULT_TIMEOUT = 200;
/** The default item template. */
export const DefaultItemTemplate: React.FC<{ item: any }> = (props) => (
  <div>{props.item.toString()}</div>
);
export const DefaultCreateNewItemTemplate: React.FC<{}> = () => (
  <div className="ui fluid blue button">
    <i className="large plus icon" />
  </div>
);
// The default predicate to use if none is specified. This predicate basically
// searches for items containing all words (separated by spaces) in the search
// text.
export const DefaultPredicate = (text, item) => {
  if (text === undefined || text.length == 0) {
    // No search text means we pass all elements through the filter.
    return true;
  }

  if (!item) {
    // Falsy items never pass through the filter.
    return false;
  }

  let words: string = text.match(/(".*?"|\S+)/g);

  // If the item contains any of the entered words, we consider it a match.
  for (let i = 0; i < words.length; i++) {
    let word = words[i];

    // Ignore empty words.
    if (word.length == 0) {
      continue;
    }

    // Remove citation: "multi word" --> multi word
    if (word[0] == '"' && word[word.length - 1] == '"') {
      word = word.slice(1, -1);
    }

    if (item.toString().toLowerCase().indexOf(word.toLowerCase()) < 0) {
      // The word we're looking for couldn't be found in this item, so
      // filter it out.
      return false;
    }
  }

  // Match found - the tested item contained all require words.
  return true;
};

interface Props<T extends { id: any }> {
  className?: string;
  wrapper?: React.ComponentType<any>;
  predicate?: any;
  timeout?: number;
  items?: T[];
  template?: any;
  additionalTemplateProps?: any;
  createNewItemTemplate?: React.ComponentType<any>;
  showDefaultSearchBar?: boolean;
  onCreateNewItem?: any;
  chunkSize?: number;
  sortByFields?: string[];
}

const SearchableListContext = createContext<any | undefined>(undefined);

const useSearchableList = () => {
  const context = useContext(SearchableListContext);
  if (!context) {
    throw new Error(
      "useSearchableList must be used within a SearchableListProvider"
    );
  }
  return context;
};

export const SearchListBar: React.FC<{
  className?: string;
  placeholder?: string;
}> = (props) => {
  const list = useSearchableList();
  return (
    <input
      value={list.text}
      className={"ui input " + props.className}
      type="text"
      placeholder={props.placeholder}
      onChange={list.searchHandler}
    />
  );
};

// Create a provider component to wrap your app with the context
export const SearchableList: React.FC<Props<any>> = (props) => {
  let predicate = props.predicate || DefaultPredicate;
  let timeout = props.timeout || DEFAULT_TIMEOUT;
  let chunkSize = props.chunkSize || 30;

  const [text, setText] = useState("");
  const [nrOfChunksToDisplay, setNrOfChunksToDisplay] = useState(1);
  const [reverseSort, setReverseSort] = useState(false);

  const displayNextChuckOfItems = () => {
    setNrOfChunksToDisplay(nrOfChunksToDisplay + 1);
  };

  const getFilteredItems = () => {
    return (props.items || []).filter((item) => predicate(text, item));
  };

  const getSortedItems = () => {
    const sortedItems = Lodash.sortBy(
      getFilteredItems(),
      props.sortByFields || []
    );
    return reverseSort ? Lodash.reverse(sortedItems) : sortedItems;
  };

  const getDisplayedItems = () => {
    // Slice to chunk size and pages shown
    return getSortedItems().slice(0, nrOfChunksToDisplay * chunkSize);
  };

  const isAllItemsDisplayed =
    getDisplayedItems().length === getFilteredItems().length;

  const searchHandler = (text) => {
    setNrOfChunksToDisplay(1);
    setText(text);
  };

  // Render items
  const template = props.template || DefaultItemTemplate;
  let renderedItems = Lodash.map(getDisplayedItems(), (item) =>
    React.createElement(
      template,
      Lodash.merge({ key: item.id, item: item }, props.additionalTemplateProps)
    )
  );

  const SearchBarComponent = props.showDefaultSearchBar ? (
    <div className="ui fluid small action input">
      <SearchListBar />
      <div className="ui small icon button">
        <i className="search icon" />
      </div>
    </div>
  ) : undefined;

  const WrapperComponent =
    props.wrapper ||
    ((props: any) => (
      <div style={props.style}>
        {props.searchBar}
        <div className={props.className}>{props.children}</div>
      </div>
    ));

  const LoadMoreItemsButton = isAllItemsDisplayed ? undefined : (
    <button
      className="ui large fluid blue button"
      style={{ marginTop: "2em" }}
      onClick={displayNextChuckOfItems}
    >
      Visa fler...
    </button>
  );

  return (
    <SearchableListContext.Provider
      value={{ searchHandler, getDisplayedItems }}
    >
      <div>
        <WrapperComponent
          style={{ minHeight: "10em" }}
          searchBar={SearchBarComponent}
        >
          {renderedItems}
        </WrapperComponent>
        <div id="bottomOfList" />
        {LoadMoreItemsButton}
      </div>
    </SearchableListContext.Provider>
  );
};
