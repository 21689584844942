/*
 * This file contains small utility functions used across many components
 * in the application
 */
import Moment from "moment-timezone";
import { DefaultPredicate } from "./commons";
import { UserDocument } from "../resources/user.resource";

/** Returns the current year in 4 digit format: 1990 */
export const currentYear = () => Moment().format("YYYY");

/** Returns the current month in 2 digit format: 07 */
export const currentMonth = () => Moment().format("MM");

/** Formats a given duration in the format: 13h 37m */
export const formatDuration = (
  /** The duration in minutes */ duration: number
) => {
  let hours: number = 0;
  let minutes: number = 0;

  while (duration > 60.0) {
    hours++;
    duration -= 60.0;
  }

  while (duration > 1.0) {
    minutes++;
    duration -= 1.0;
  }

  return `${hours}h ${minutes}m`;
};

/** Returns a search predicate usable for filtering users */
export function EmployeeSearchPredicate(text: string, item: UserDocument) {
  return DefaultPredicate(
    text,
    item.firstname.toString() +
      item.lastname.toString() +
      item.personNr.toString()
  );
}
