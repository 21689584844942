import Moment from "moment-timezone";
import * as QRCode from "qrcode";
import * as React from "react";
import { PropertyDocument } from "../../resources/property.resource";
import {
  Alert,
  CopyToClipboardButton,
  Field,
  Form,
  Input,
  Rules,
  SubmitButton,
} from "../commons";
import RangedDatePicker from "./datePicker";

interface Props {
  /** Any preselected property passed from the UI, used to autofill the tax agency id */
  selectedProperty?: PropertyDocument;
}

interface State {
  /** If set, ondicates that the form has been successfully submitted and that we have received a valid url */
  xmlUrl: string | undefined;
  /** The QR code image for any set xmlUrl */
  xmlQrCode: string | undefined;
  dateRange: string[] | undefined;
}

/** Form component for generating a XML link for the tax agency (Skatteverket) */
export class InspectionXmlForm extends React.Component<Props, State> {
  state: State = {
    xmlUrl: undefined,
    xmlQrCode: undefined,
    dateRange: undefined,
  };

  /** Create a xml url from the gven properties */
  /** TODO: (old) @Andilutten här kan en annan kod generas */
  private createUrl(
    identifier: string,
    password: string,
    startQuery: string,
    endQuery: string
  ) {
    const { protocol, host } = window.location;
    return `${protocol}//${host}/api/property/xml/${identifier}/?password=${password}${startQuery}${endQuery}`;
  }

  /** Fetching the xml url when the form is submitted */
  private async onSubmit(values, resolve, reject) {
    const { identifier, password } = values;
    const range = this.state.dateRange;

    const method = "GET";
    const startQuery = range && range.length == 2 ? `&start=${range[0]}` : "";
    const endQuery = range && range.length == 2 ? `&end=${range[1]}` : "";
    const xmlUrl = this.createUrl(identifier, password, startQuery, endQuery);
    const xmlQrCode = await QRCode.toDataURL(xmlUrl);

    // Verify the URL before saving it in state
    try {
      const res = await fetch(xmlUrl, { method });
      if (res.status != 200) {
        throw new Error("Invalid response status " + res.status);
      }
      await this.setState({ xmlUrl, xmlQrCode });
      return resolve();
    } catch (err) {
      // If any errors occured
      console.error(err);
      $(".ui.segment").transition("shake");
      Alert.show(
        "Kontrollera att du har en internetanslutning och att åtkomstkoden du angett är korrekt, om problemet kvarstår, var snäll och kontakta support så hjälper vi dig!",
        "Det gick inte att hämta XML filen",
        "error",
        "ok"
      );
      reject(err);
    }
  }

  private setDate = (date: Date[]) => {
    this.setState({ xmlUrl: undefined });
    const formatDates = (dates: Date[]) => {
      if (!dates) {
        return undefined;
      }

      return dates.map((date) => Moment(date).format("YYYY-MM-DDTHH:mm:ss"));
    };

    this.setState({ dateRange: formatDates(date) });
  };

  render() {
    const { selectedProperty } = this.props;
    return (
      <div className="ui segment">
        <Form onSubmit={this.onSubmit.bind(this)}>
          <Field label={"Anläggningens ID från Skatteverket"}>
            <Input
              placeholder="PL0000AA0000"
              type="text"
              name="identifier"
              icon="user"
              rules={[Rules.NotEmpty()]}
              defaultValue={
                selectedProperty ? selectedProperty.taxAgencyIdentification : ""
              }
              onChange={() => this.setState({ xmlUrl: undefined })}
            />
          </Field>
          <Field label={"Åtkomstkoden för Skatteverket"}>
            <Input
              placeholder="Åtkomstkod"
              type="password"
              name="password"
              icon="lock"
              onChange={() => this.setState({ xmlUrl: undefined })}
              rules={[Rules.NotEmpty()]}
            />
          </Field>
          <p>
            Koden finns i informationsrutan "Min organisation" på sidan för
            Kontoinställningar i admin-gränssnittet."
          </p>
          <div style={{ height: "2em" }} />
          <Field
            label={"Ange vid behov en frivillig tidsram"}
            style={{ width: "100%" }}
          >
            <RangedDatePicker setDateRange={this.setDate} />
          </Field>

          {/* Leave this here incase we need to quickly switch back.  */}
          {/* <Field label={"Ange vid behov en frivillig tidsram"}>
            <Input
              placeholder={
                "fr.o.m. (frivilligt) exempelvis: " +
                Moment().format("YYYY-MM-DDTHH:mm:ss")
              }
              type="text"
              name="start"
              icon="play"
              onChange={() => this.setState({ xmlUrl: undefined })}
              rules={[
                Rules.CustomRule({
                  validator: this.dateValidator.bind(this),
                  prompt:
                    "Måste vara en tidsstämpel enligt YYYY-MM-DDTHH:mm:ss",
                }),
              ]}
            />
          </Field>
          <Field>
            <Input
              placeholder={
                "t.o.m. (frivilligt) exempelvis: " +
                Moment().format("YYYY-MM-DDTHH:mm:ss")
              }
              type="text"
              name="end"
              icon="stop"
              onChange={() => this.setState({ xmlUrl: undefined })}
              rules={[
                Rules.CustomRule({
                  validator: this.dateValidator,
                  prompt:
                    "Måste vara en tidsstämpel enligt YYYY-MM-DDTHH:mm:ss",
                }),
              ]}
            />
          </Field> */}

          {!this.state.xmlUrl && (
            <SubmitButton>Skapa länk för Skatteinspektion</SubmitButton>
          )}
          {!this.state.xmlUrl && (
            <p>
              När du trycker på länken genereras en webb-länk med tillhörande
              qr-kod som kan användas av skatteverket för att genomföra sin
              inspektion. Länken leder till en fil i XML format.
            </p>
          )}
        </Form>
        {this.state.xmlUrl && (
          <>
            <hr />
            <h3>Länken till översiktsbilden har skapats</h3>
            <p>
              Länken leder till en fil i XML format för ovanstående anläggning.
              QR Koden (mönsterbilden) kan skannas av de flesta smartphones via
              dess kamera-app för att öppna länken på den enheten.
            </p>
            <img src={this.state.xmlQrCode} />
            <hr />
            <p>Vid behov kan du manuellt skriva av eller kopia länken:</p>
            <h4>{this.state.xmlUrl}</h4>
            <CopyToClipboardButton
              textToCopy={this.state.xmlUrl}
              size="medium"
              buttonText="Kopiera länken för skatteverket"
            />
            <div style={{ height: "1em" }} />
            <p>
              När du trycker på "Kopiera länk" så kan du sedan klistra in länken
              i exempelvis appen mail eller meddelanden.
            </p>
          </>
        )}
      </div>
    );
  }
}
