import * as React from "react";
import { MobxComponent } from "../commons";
import { ParamsContext, Consume } from "../../contexts";
import { InspectionXmlForm } from "../commons/inspectionXmlForm";

interface Props {}
interface State {}

export class PropertyInspectionView extends MobxComponent<Props, State> {
  @Consume(ParamsContext)
  private params!: ParamsContext;

  // Initialize component default state.
  public state: State = {};

  render() {
    const { property } = this.params;

    return (
      <div>
        <h2>
          Hämta en digital ögonblicksbild i XML format för{" "}
          {this.params.property!.name}
        </h2>
        <div className="ui container">
          <div className="ui middle aligned center aligned grid">
            <div
              className="column"
              style={{ maxWidth: "540px", color: "black" }}
            >
              <InspectionXmlForm selectedProperty={property} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
