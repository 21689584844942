import Lodash from "lodash";
import * as React from "react";
import { KosmicComponent } from "../commons";

import { OrganizationResource } from "../../resources/organization.resource";
import { UserDocument, UserResource } from "../../resources/user.resource";

export class StatisticsView extends KosmicComponent<
  {},
  { statisticsData: { organization: string; nrOfUsers: number }[] }
> {
  state = {
    statisticsData: [],
  };

  componentDidMount() {
    new OrganizationResource().find({}).then((organizations) => {
      new UserResource().find({}).then((users) => {
        this.setState({
          statisticsData: Lodash.map(organizations, (organization) => ({
            organization: organization.name,
            nrOfUsers: Lodash.size(
              Lodash.filter(
                users,
                (user) => user.organization == organization._id
              )
            ),
          })),
        });
      });
    });
  }

  render() {
    return (
      <div>
        <h2 className="ui header" style={{ color: "white" }}>
          Statistik för personalboken
          <div className="sub header" style={{ color: "#fff" }}>
            Denna sida visas bara för dig,{" "}
            {(this.globals.session.currentUser as UserDocument).name}, eftersom
            du är en ägare till systemet!
          </div>
        </h2>
        <StatisticsTable data={this.state.statisticsData} />
      </div>
    );
  }
}

class StatisticsTable extends React.Component<
  { data: { organization: string; nrOfUsers: number }[] },
  {}
> {
  render() {
    if (!this.props.data || !this.props.data.length) {
      return (
        <div>
          <br />
          <br />
          <div className="ui active centered inline inverted large loader" />
        </div>
      );
    }
    return (
      <table className="ui striped table">
        <thead>
          <tr>
            <th>Namn</th>
            <th>Antal användare</th>
          </tr>
        </thead>
        <tbody>
          {Lodash.map(this.props.data, (row, i) => (
            <tr key={row.organization + i}>
              <td>{row.organization}</td>
              <td>{row.nrOfUsers} st</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
