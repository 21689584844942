import * as React from "react";
import Lodash from "lodash";
import { MongooseDocument } from "../../resources/_abstractResource";

type SortDirection = "asc" | "desc";

interface Props<T> {
  /** Semantic table classnames - defaults to "ui striped table" */ className?: string;
  /** Use this render prop to define each table row */ renderRow: (
    data: T
  ) => JSX.Element;
  /** The list to present in the table */ dataList?: T[];
  /** Whether to sort on a column at mount */ initSortColumn?: keyof T & string;
  /** Headers for the table, including a sorting property */ headers: {
    /** Column name */ title: string | JSX.Element;
    /** Sort works with string & number */ sortBy?: keyof T & string;
    /** How many columns this header should cober  */ colSpan?: number;
    /** Defaults to asc */ defaultSortDirection?: SortDirection;
  }[];
}

interface State<T> {
  sortedData?: T[];
  sortDirection?: SortDirection;
  sortColumn?: string;
}

export class Table<T extends MongooseDocument> extends React.Component<
  Props<T>,
  State<T>
> {
  public constructor(props: Props<T>) {
    super(props);

    if (props.initSortColumn && props.dataList && props.dataList.length) {
      this.state = this.getNextStateAfterSort(
        props.dataList,
        props.initSortColumn
      );
    } else {
      this.state = { sortedData: this.props.dataList };
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props<T>) {
    if (nextProps.dataList) {
      if (this.state.sortColumn) {
        const header = Lodash.find(
          nextProps.headers,
          (header) => header.sortBy == this.state.sortColumn
        )!;
        let sortedData = this.sort(
          nextProps.dataList,
          header.sortBy!,
          header.defaultSortDirection
        );
        if (
          this.state.sortDirection !== (header.defaultSortDirection || "asc")
        ) {
          sortedData = Lodash.reverse(sortedData);
        }
        this.setState({ sortedData });
      } else if (nextProps.initSortColumn && !this.state.sortColumn) {
        this.setState(
          this.getNextStateAfterSort(
            nextProps.dataList,
            nextProps.initSortColumn
          )
        );
      } else {
        this.setState({ sortedData: nextProps.dataList });
      }
    }
  }

  private getNextStateAfterSort(
    dataList: T[],
    column: string,
    defaultSortDirection?: SortDirection
  ): State<T> {
    // Try to calculate initial sort direction
    const { headers } = this.props;
    defaultSortDirection =
      defaultSortDirection ||
      (function () {
        const c = headers.find((c) => c.sortBy == column);
        if (c == undefined) return undefined;
        return c.defaultSortDirection;
      })();

    if (this.state && column == this.state.sortColumn) {
      return {
        sortedData: Lodash.reverse(dataList),
        sortDirection: this.state.sortDirection === "asc" ? "desc" : "asc",
        sortColumn: column,
      };
    }
    return {
      sortedData: this.sort(dataList, column, defaultSortDirection),
      sortDirection: defaultSortDirection || "asc",
      sortColumn: column,
    };
  }

  private sort(
    dataList: T[],
    column: string,
    defaultSortDirection?: SortDirection
  ): T[] {
    const sortFunction = (data: T) =>
      typeof data[column] === "string"
        ? Lodash.toLower(data[column])
        : data[column];
    return Lodash.orderBy(
      dataList,
      [sortFunction, "id"],
      [defaultSortDirection || "asc"]
    );
  }

  private onHeaderClicked(
    column: string,
    defaultSortDirection?: SortDirection
  ) {
    if (this.state.sortedData) {
      this.setState(
        this.getNextStateAfterSort(
          this.state.sortedData,
          column,
          defaultSortDirection
        )
      );
    }
  }

  private getSortCaret(column: string) {
    if (this.state.sortColumn == column) {
      const direction = this.state.sortDirection === "asc" ? "up" : "down";
      return (
        <i
          className={"ui caret " + direction + " icon"}
          style={{ margin: 0, width: "17px" }}
        />
      );
    }
    // Prevents the jumping of columns
    return <div style={{ display: "inline-block", width: "17px" }} />;
  }

  private get headers() {
    return this.props.headers.map((header, index) => {
      if (header.sortBy) {
        return (
          <th
            key={index}
            colSpan={header.colSpan}
            style={{ cursor: "pointer" }}
            onClick={this.onHeaderClicked.bind(
              this,
              ...[header.sortBy, header.defaultSortDirection]
            )}
          >
            {header.title} {this.getSortCaret(header.sortBy)}
          </th>
        );
      }
      return (
        <th key={index} colSpan={header.colSpan}>
          {header.title}
        </th>
      );
    });
  }

  private get activeLoader() {
    if (!this.state.sortedData) {
      return (
        <tr>
          <td colSpan={this.props.headers.length}>
            <div
              className="ui huge active centered inline text loader"
              style={{ margin: "2em 0" }}
            />
          </td>
        </tr>
      );
    }
  }

  public render() {
    return (
      <table className={this.props.className || "ui striped table"}>
        <thead>
          <tr>{this.headers}</tr>
        </thead>
        <tbody>
          {this.activeLoader}
          {this.state.sortedData &&
            this.state.sortedData.map((data) => this.props.renderRow(data))}
        </tbody>
      </table>
    );
  }
}
