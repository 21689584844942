/**
 * This component lets the user edit and create
 * card readers.
 * FIXME: (old) As of now this component can only be used
 * for editing. Before we can create new readers
 * we need to figure out how to assign reader ids.
 */
import Lodash from "lodash";
import * as React from "react";
import {
  PropertyDocument,
  PropertyResource,
} from "../../resources/property.resource";
import {
  CardReaderDocument,
  CardReaderResource,
} from "../../resources/reader.resource";
import {
  Dropdown,
  DropdownItem,
  Field,
  Form,
  Input,
  KosmicComponent,
  SubmitButton,
} from "../commons";

interface Props {
  /**
   * Original document for editing. If undefined is passed
   * the component will create a new one instead.
   */
  document?: CardReaderDocument;
  onCancel?: () => void;
  onSubmit?: (doc: CardReaderDocument) => void;
}
interface State {
  document?: CardReaderDocument;
  properties?: PropertyDocument[];
  loaded: boolean;
}

// TODO: (old) Form validation rules
export class CardReaderForm extends KosmicComponent<Props, State> {
  public state: State = {
    loaded: false,
  };

  private async submit(values, resolve, reject) {
    const document = this.state.document!;
    const resource = new CardReaderResource();
    const { name, project } = values;
    document.label = name;
    document.property = project;

    try {
      await resource.updateDocument(document);
    } catch (err) {
      console.error(err);
      return reject();
    }
    this.props.onSubmit && this.props.onSubmit(document);
    return resolve();
  }

  public async componentDidMount() {
    // Fetch all properties for current organization
    const properties = await new PropertyResource().findAccessableProperties();

    // Create a stateful document clone.
    const organization = this.globals.session.currentOrganizationId;
    const document = this.props.document
      ? Lodash.cloneDeep(this.props.document)
      : new CardReaderResource().createDocument({
          organization,
          property: {},
        });

    // Update state
    this.setState({ loaded: true, document, properties });
  }

  public render() {
    const { document, loaded, properties } = this.state;
    if (!loaded || !document) {
      return <div className="ui active loader" />;
    }

    return (
      <Form onSubmit={this.submit.bind(this)}>
        <Field label="Läsarnamn">
          <Input name="name" defaultValue={document.label} />
        </Field>
        <Field label="Projekt">
          <Dropdown
            name="project"
            fluid
            searchable
            defaultValue={document.property ? document.property.id : undefined}
          >
            {properties!.map((property) => (
              <DropdownItem key={property.id} value={property.id}>
                {property.name}
              </DropdownItem>
            ))}
          </Dropdown>
        </Field>
        <div style={{ display: "flex", width: "100%" }}>
          <div onClick={this.props.onCancel} className="ui button fluid red">
            <i className="left arrow icon" />
            Gå tillbaka
          </div>
          <SubmitButton className="fluid green">Spara ändringar</SubmitButton>
        </div>
      </Form>
    );
  }
}
