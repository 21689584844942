import * as React from "react";
import { KosmicComponent } from "../../commons";
import { PropertyDocument } from "../../../resources/property.resource";
import { InspectionXmlForm } from "../../commons/inspectionXmlForm";

interface Props {
  selectedProperty?: PropertyDocument;
}

/** Allows Skatteverket to download an XML file for inspection */
export class InspectionView extends KosmicComponent<Props> {
  render() {
    return (
      <div className="ui container">
        <div className="ui middle aligned center aligned grid">
          <div className="column" style={{ maxWidth: "540px" }}>
            <h3 style={{ textAlign: "center", width: "100%" }}>
              Hämta en digital ögonblicksbild i XML format
            </h3>
            <InspectionXmlForm selectedProperty={this.props.selectedProperty} />
          </div>
        </div>
      </div>
    );
  }
}
