import { Schema, SchemaDefinition, Types } from "mongoose";
import { MongooseDocument, MongooseResource } from "./_abstractResource";
import { UserDocument, UserResource } from "./user.resource";

/**
 * @type {CardDocument} represents a RFID
 * card used to authenticate a user at a
 * reader. @see {CardReaderDocument}
 */
export interface CardDocument extends MongooseDocument {
  /** Holds a reference to the user that owns this card */
  userId: Types.ObjectId;
  /** Internal RFID code of the card */
  rfid: string;
  /** Determines if a card has been self registered */
  selfRegistered?: boolean;

  /** Populated user document */
  user: UserDocument;

  id06?: string;
}

/**
 * @type {CardResource} defines the API
 * and database model for a RFID card
 * connected to a user.
 */
export class CardResource extends MongooseResource<CardDocument> {
  /** Card database schema */
  static Schema: SchemaDefinition = {
    user: { type: Schema.Types.ObjectId, ref: "default", required: true },
    rfid: { type: String, required: true, unique: true },
    id06: { type: String, unique: true },
    selfRegistered: { type: Schema.Types.Boolean, default: false },
  };

  constructor() {
    super();
    this.setName("card");
    this.setSchema(CardResource.Schema);
    this.addVirtualField("userId", this.userId);
  }

  /**
   * Returns the user id from a document.
   * User as a virtual field.
   *
   * @param document Document to get user id from
   */
  private userId(document: CardDocument): Types.ObjectId {
    return UserResource.IsAUserDocument(document.user)
      ? document.user._id
      : (document.user as any as Types.ObjectId);
  }

  populateCardUsers(query: Object) {
    return this.sendRequest<CardDocument[]>("/populateCardUsers", "post", {
      query,
    });
  }
}
