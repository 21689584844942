import * as React from "react";
import {
  Form,
  Field,
  Input,
  SubmitButton,
  Alert,
  Accordion,
  Rules,
  KosmicComponent,
} from "../commons";
import { ProtectedTableCell } from "../commons/protectedTableCell";
import { UserDocument, UserResource } from "../../resources/user.resource";
import {
  OrganizationDocument,
  OrganizationResource,
} from "../../resources/organization.resource";

interface Props {}
interface State {
  updatedPasscode?: string;
  organization?: OrganizationDocument;
  componentIsInitialized: boolean;
}

function isOrganization(
  org: OrganizationDocument | string
): org is OrganizationDocument {
  return (org as OrganizationDocument).managers != undefined;
}

export class AccountSettingsView extends KosmicComponent<Props, State> {
  public state: State = { componentIsInitialized: false };

  async componentDidMount() {
    const organization = this.globals.session.currentOrganization;
    if (isOrganization(organization)) {
      return this.setState({
        componentIsInitialized: true,
        organization: this.globals.session.currentOrganization,
      });
    }
    const document = await new OrganizationResource().get(organization);
    this.setState({
      componentIsInitialized: true,
      organization: document,
    });
  }

  private changePassword = async (values, resolve, reject) => {
    try {
      const result = await new UserResource().changePassword(
        this.globals.session.currentUser!._id,
        values["newpassword"],
        ""
      ); // FIXME: (old) does this work without a password reset token
      Alert.show("Ditt lösenord har uppdaterats!", "Ok", "success", "ok");
      return resolve();
    } catch (err) {
      Alert.show(
        "Kontrollera att du har en internetanslutning och är inloggad, om problemet kvarstår, var snäll och kontakta support så hjälper vi dig!",
        "Det gick inte att spara det nya lösenordet",
        "error",
        "försök igen"
      );
      throw reject();
    }
  };

  private setOrgNumber = async (values, resolve, reject) => {
    try {
      const resource = new OrganizationResource();
      const document = this.state.organization!;

      document.orgNumber = values["orgNumber"];
      await resource.updateDocument(document);
      this.setState({ organization: document });

      Alert.show(
        "Ditt organisationsnummer har uppdaterats!",
        "Ok",
        "success",
        "ok"
      );
      return resolve();
    } catch (err) {
      console.error(err);
      Alert.show(
        "Kontrollera att du har en internetanslutning och är inloggad, om problemet kvarstår, var snäll och kontakta support så hjälper vi dig!",
        "Det gick inte att spara organisationsnummret",
        "error",
        "försök igen"
      );
      throw reject();
    }
  };

  private setTaxAgencyPassword = async (values, resolve, reject) => {
    try {
      const resource = new OrganizationResource();
      const document = this.state.organization!;

      document.taxAgencyPassword = values["taxAgencyPassword"];
      await resource.updateDocument(document);
      this.setState({ organization: document });

      Alert.show("Åtkomstkoden har uppdaterats.", "Ok", "success", "ok");
      return resolve();
    } catch (err) {
      Alert.show(
        "Kontrollera att du har en internetanslutning och är inloggad, om problemet kvarstår, var snäll och kontakta support så hjälper vi dig!",
        "Det gick inte att spara den nya åtkomstkoden",
        "error",
        "försök igen"
      );
      throw reject();
    }
  };

  changePasscode = async (values, resolve, reject) => {
    if (!this.globals.session.currentUser) {
      return;
    }

    const resource = new UserResource();
    const document = resource.createDocument(this.globals.session.currentUser);

    document.passcode = values["passcode"];
    resource
      .updateDocument(document)
      .then((result) => {
        this.setState({ updatedPasscode: values["passcode"] });
        (this.globals.session.currentUser as UserDocument).passcode =
          values["passcode"];
        this.globals.session.updateLocalStorage();
        Alert.show("Din säkerhetskod har uppdaterats!", "Ok", "success", "ok");
        resolve();
      })
      .catch((err) => {
        Alert.show(
          "Kontrollera att du har en internetanslutning och är inloggad, om problemet kvarstår, var snäll och kontakta support så hjälper vi dig!",
          "Det gick inte att spara den nya säkerhetskoden",
          "error",
          "försök igen"
        );
        reject();
      });
  };

  render() {
    if (
      !this.globals.session.currentUser ||
      !this.state.componentIsInitialized ||
      !this.state.organization
    ) {
      return <div className="ui active loader" />;
    }

    return (
      <div>
        <h2>
          Inloggad som {(this.globals.session.currentUser as UserDocument).name}
        </h2>
        <div className="ui stackable two columns grid">
          <div className="column">
            <div className="ui segment" style={{ color: "#000" }}>
              <h4>För adminsidan</h4>
              <p>
                Till admingränsnittet loggar du in med ditt användarnamn och
                valt lösenord.
              </p>
              <table className="ui basic table">
                <tbody>
                  <tr>
                    <td>Användarnamn</td>
                    <td>{this.globals.session.currentUser.username}</td>
                  </tr>
                </tbody>
              </table>
              <p>
                Ditt lösenordet visas inte här av säkerhetsskäl. Du kan använda
                formuläret nedanför för att ändra ditt lösenord
              </p>

              <Accordion
                title={
                  <h5>
                    <i className="dropdown icon" /> Välj ett nytt lösenord
                  </h5>
                }
              >
                <Form onSubmit={this.changePassword}>
                  <Field>
                    <Input
                      name="newpassword"
                      placeholder="Skriv ditt nya lösenord här"
                      protectedCharacters
                      rules={[
                        Rules.NotEmpty(),
                        Rules.MinimumLength(
                          3,
                          "Lösenordet måste vara minst 3 tecken långt"
                        ),
                      ]}
                    />
                  </Field>
                  <Field>
                    <Input
                      name="repeatednewpassword"
                      placeholder="Upprepa lösenordet igen"
                      protectedCharacters
                      rules={[
                        Rules.NotEmpty(),
                        Rules.TheSameAs(
                          "newpassword",
                          "Lösenorden matchar inte"
                        ),
                      ]}
                    />
                  </Field>
                  <div className="ui compact orange message">
                    <i className="warning icon" />
                    Tänk på att ett bra lösenord är lätt att komma ihåg men
                    svårt att gissa, du behöver inte blanda siffror och
                    bokstäver, men längre lösenord är bättre
                  </div>
                  <SubmitButton className="green small fluid">
                    Spara nytt lösenord
                  </SubmitButton>
                </Form>
              </Accordion>
            </div>
          </div>
          <div className="column">
            <div className="ui segment" style={{ color: "#000" }}>
              <h4>I appen</h4>
              <p>
                Du kan precis som andra anställda checka in och ut ur appen, och
                använda den till administrera arbetsplaser.
                <br />
                För att göra allt i appen måste du ibland ange ditt personnummer
                och din personliga säkerhetskod:
              </p>
              <table className="ui basic table">
                <tbody>
                  <tr>
                    <td>Ditt personnummer</td>
                    <td>
                      {
                        (this.globals.session.currentUser as UserDocument)
                          .personNr
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Din säkerhetskod</td>
                    <ProtectedTableCell
                      content={
                        this.state.updatedPasscode ||
                        (this.globals.session.currentUser as UserDocument)
                          .passcode
                      }
                    />
                  </tr>
                </tbody>
              </table>
              <div className="ui blue message">
                <i className="info icon" />
                Du kommer snart kunna använda ditt lösenord överallt i appen
                istället för säkerhetskoden.
              </div>

              <Accordion
                title={
                  <span>
                    <h5>
                      <i className="dropdown icon" />
                      Ändra din säkerhetskod
                    </h5>
                  </span>
                }
              >
                <Form onSubmit={this.changePasscode}>
                  <Field>
                    <Input
                      name="passcode"
                      rules={[
                        Rules.NotEmpty(),
                        Rules.IsAnInteger("Koden får bara innehålla siffror"),
                        Rules.ExactLength(
                          6,
                          "Koden måste vara exakt 6 siffor lång"
                        ),
                      ]}
                      placeholder="XXXXXX"
                    />
                  </Field>
                  <SubmitButton className="green small fluid">
                    Spara vald säkerhetskod
                  </SubmitButton>
                </Form>
              </Accordion>
            </div>
          </div>
          <div className="column">
            <div className="ui segment" style={{ color: "#000" }}>
              <h4>Min organisation</h4>
              <p>
                Här kan du ändra uppgifter för din organisation{" "}
                <b>{this.state.organization.name}</b>
                <br />
                <b>Skatteverkets åtkomstkod</b> kan behöva uppges över telefon
                eller sms om skatteverket begär det
              </p>

              {!this.state.organization.orgNumber ||
              !this.state.organization.orgNumber.length
                ? [
                    <div className="ui negative message">
                      <div className="header">
                        Vi saknar ditt organisationsnummer 😿
                      </div>
                      <p>
                        För att alla tjänster ska fungera korrekt måste du ange
                        ert organisationsnummer
                      </p>
                    </div>,
                    <Form onSubmit={this.setOrgNumber}>
                      <Field>
                        <Input
                          name="orgNumber"
                          placeholder="Organisationsnummer"
                          rules={[Rules.NotEmpty()]}
                        />
                      </Field>
                      <SubmitButton className="green small fluid">
                        Spara organisationsnummer
                      </SubmitButton>
                    </Form>,
                  ]
                : [
                    <table className="ui basic table">
                      <tbody>
                        <tr>
                          <td>Organisationsnummer</td>
                          <td>{this.state.organization.orgNumber}</td>
                        </tr>
                        <tr>
                          <td>Skatteverkets åtkomstkod</td>
                          <ProtectedTableCell
                            content={this.state.organization.taxAgencyPassword}
                          />
                        </tr>
                      </tbody>
                    </table>,
                    <Accordion
                      title={
                        <span>
                          <h5>
                            <i className="dropdown icon" />
                            Ändra ditt organisationsnummer
                          </h5>
                        </span>
                      }
                    >
                      <Form onSubmit={this.setOrgNumber}>
                        <Field>
                          <Input
                            name="orgNumber"
                            placeholder="Organisationsnummer"
                            rules={[Rules.NotEmpty()]}
                          />
                        </Field>
                        <SubmitButton className="green small fluid">
                          Spara organisationsnummer
                        </SubmitButton>
                      </Form>
                    </Accordion>,
                    <Accordion
                      title={
                        <span>
                          <h5>
                            <i className="dropdown icon" />
                            Ändra skatteverkets åtkomstkod
                          </h5>
                        </span>
                      }
                    >
                      <Form onSubmit={this.setTaxAgencyPassword}>
                        <Field>
                          <Input
                            name="taxAgencyPassword"
                            placeholder="Åtkomstkod"
                            rules={[Rules.NotEmpty()]}
                          />
                        </Field>
                        <SubmitButton className="green small fluid">
                          Spara ny åtkomstkod
                        </SubmitButton>
                      </Form>
                    </Accordion>,
                  ]}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
