import Lodash from "lodash";
import Moment from "moment-timezone";
import * as React from "react";
import * as ReactRouter from "react-router";
import { MobxComponent } from "../commons";
import { Link } from "../navigation";

import {
  ObservationDocument,
  ObservationResource,
} from "../../resources/observation.resource";
import { PropertyDocument } from "../../resources/property.resource";
import { UserDocument, UserResource } from "../../resources/user.resource";

interface Props {
  match: ReactRouter.match<{ employee: string; month: string; year: string }>;
}
interface State {
  loading: boolean;
  error: boolean;
  periodStart?: Date;
  user?: UserDocument;
  observations?: ObservationDocument[];
}

export class EmployeeStatisticsView extends MobxComponent<Props> {
  // Initialize component default state.
  public state: State = {
    loading: true,
    error: false,
  };

  // Used to prevent infinite loop in
  // componentDidUpdate
  private isUpdating: boolean = false;

  // Calculates periodEnd value.
  private get periodEnd(): Date {
    return Moment(this.state.periodStart).add(1, "month").toDate();
  }

  // Returns state user or create one if state user is undefined.
  private get user() {
    return this.state.user || new UserResource().createDocument({});
  }

  // Returns the total amount of checkins.
  private get totalCheckins(): number {
    return (this.state.observations || []).length;
  }

  // Pagination url forward
  private get nextMonthURL() {
    const nextPeriod = Moment(this.state.periodStart).add(1, "month");
    return `/admin/employees/statistics/${
      this.state.user!.id
    }/${nextPeriod.format("YYYY")}/${nextPeriod.format("MM")}`;
  }

  // Pagination url backwards
  private get previousMonthURL() {
    const prevPeriod = Moment(this.state.periodStart).subtract(1, "month");
    return `/admin/employees/statistics/${
      this.state.user!.id
    }/${prevPeriod.format("YYYY")}/${prevPeriod.format("MM")}`;
  }

  // Returns the total amount of minutes that the
  // user has been checked in.
  private get totalMinutes(): number {
    return Lodash.sumBy(this.state.observations || [], (observation) =>
      Moment.duration(
        Moment(observation.end || new Date()).diff(observation.beginning)
      ).asMinutes()
    );
  }

  // Returns the total minutes as a formatted string
  private get formattedMinutes(): string {
    return this.formatter(this.totalMinutes);
  }

  // Assembles statistics per property for user
  private get propertyStatisticsEntries(): {
    name: string;
    checkIns: number;
    minutes: number;
  }[] {
    const mapFunc = (observations: ObservationDocument[]) => ({
      name: observations.length
        ? (observations[0].property as PropertyDocument).name
        : "",
      checkIns: observations.length,
      minutes: Lodash.sumBy(observations, (observation) =>
        Moment.duration(
          Moment(observation.end || new Date()).diff(observation.beginning)
        ).asMinutes()
      ),
    });
    const groups = Lodash.groupBy(this.state.observations || [], "property.id");
    return Lodash.map(groups, mapFunc);
  }

  // Takes minutes and string formats the value.
  private formatter(minutes: number) {
    const hours = Math.floor(minutes / 60);
    const remainder = Math.floor(minutes % 60);

    return hours > 0
      ? `${hours} timmar & ${remainder} minuter`
      : `${remainder} minuter`;
  }

  // Sets the start of the periodStart.
  private setPeriod(year, month): void {
    year = !year || isNaN(parseFloat(year)) ? Moment().year() : parseInt(year);
    month =
      !month || isNaN(parseFloat(month))
        ? Moment().month()
        : Math.max(0, Math.min(11, parseInt(month) - 1));
    const periodStart = Moment()
      .year(year)
      .month(month)
      .startOf("month")
      .toDate();
    this.setState({ periodStart });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setPeriod(nextProps.match.params.year, nextProps.match.params.month);
  }

  private async updateObservations(user: UserDocument) {
    const { periodStart } = this.state;

    if (!periodStart || !user) {
      return;
    }

    this.setState({ loading: true });
    // Calculate new query values
    const startOfPeriod = Moment(periodStart).startOf("month");
    const endOfPeriod = Moment(periodStart).endOf("month");
    const includeOngoing = Moment().isSame(periodStart, "M"); // If we are viewing the current month
    const query = {
      employee: user._id,
      $or: [
        {
          beginning: {
            $gte: startOfPeriod.toDate(),
            $lt: endOfPeriod.toDate(),
          },
        },
        { end: { $gte: startOfPeriod.toDate(), $lt: endOfPeriod.toDate() } },
      ],
    };
    if (includeOngoing) {
      query.$or.push({ end: { $exists: false } } as any);
    }
    // Execute query
    let observations =
      await new ObservationResource().populateObservationProperty(query);

    observations = observations.map((observation) => {
      observation.beginning = Moment.max(
        Moment(observation.beginning),
        startOfPeriod
      ).toDate();
      observation.end = Moment.min(
        Moment(observation.end),
        endOfPeriod
      ).toDate();
      return observation;
    });
    this.setState({ observations, error: false, loading: false });
  }

  // Update observation values
  async componentDidUpdate(_: Props, prevState: State) {
    if (prevState.periodStart == this.state.periodStart) {
      return;
    }

    try {
      if (this.state.user) {
        this.updateObservations(this.state.user);
      }
    } catch (err) {
      this.setState({ error: true });
    } finally {
      // Remove safeguard
      this.isUpdating = false;
    }
  }

  async UNSAFE_componentWillMount() {
    // Initialize periodStart
    this.setPeriod(this.props.match.params.year, this.props.match.params.month);
    // Initialize user
    try {
      const user = await new UserResource().get(
        this.props.match.params.employee
      );

      this.setState({
        user: user,
        error: false,
        loading: false,
      });

      this.updateObservations(user);
    } catch (err) {
      this.setState({ error: true });
    }
  }

  render() {
    const { user, loading, error, periodStart } = this.state;

    // Check if component is loading user
    if (!user && loading) {
      return (
        <div className="ui active centered inline inverted small loader" />
      );
    }

    // Check if component errord while loading user
    if (!user && error) {
      return (
        <h3>
          Det gick inte att hitta den valda användaren i databasen, eller så är
          du inte ansluten till internet
        </h3>
      );
    }

    // Initialize render values.
    const currentDatetime: string = Moment().format("dddd DD MMMM YYYY HH:mm");
    const selectedMonth = Moment(periodStart).format("MMMM");
    const selectedYear = Moment(periodStart).format("YYYY");

    return (
      <div>
        <h2>
          {this.user.genetiveFirstname} aktivitet under {selectedMonth}{" "}
          {selectedYear}
        </h2>
        <p>
          Visar sammanställd aktivitet under månaden. Sidan hämtades{" "}
          {currentDatetime}
        </p>
        <Link to={this.previousMonthURL} className="ui labeled button">
          <i className="arrow left icon" /> föregående månad
        </Link>
        <Link
          to={this.nextMonthURL}
          style={{ float: "right" }}
          className="ui right labeled button"
        >
          nästa månad <i className="arrow right icon" />
        </Link>
        <table className="ui celled table stackable">
          <thead>
            <tr>
              <th>Projekt</th>
              <th>Incheckningar</th>
              <th>Total tid</th>
            </tr>
          </thead>
          <tbody>
            {error ? (
              <tr>
                <td colSpan={4}>
                  <div className="ui fluid error message">
                    Statistiken gick inte att hämta
                  </div>
                </td>
              </tr>
            ) : loading ? (
              <tr>
                <td colSpan={4}>
                  <div className="ui active centered inline loader" />
                </td>
              </tr>
            ) : (
              Lodash.map(this.propertyStatisticsEntries, (entry) => (
                <tr key={entry.name}>
                  <td>{entry.name}</td>
                  <td>{entry.checkIns}</td>
                  <td>{this.formatter(entry.minutes)}</td>
                </tr>
              ))
            )}
            <tr>
              <td style={{ textAlign: "right" }}>
                <strong>Totalt: &nbsp; </strong>
              </td>
              <td>
                <strong>{this.totalCheckins} st incheckningar</strong>
              </td>
              <td>
                <strong>{this.formattedMinutes}</strong>
              </td>
            </tr>
          </tbody>
        </table>

        <h3 className="ui inverted header">
          <div className="content">
            Total har {user!.name} arbetat <i>{this.formattedMinutes}</i> under{" "}
            {Moment(periodStart).format("MMMM")}
          </div>
        </h3>
      </div>
    );
  }
}
