import { Schema, Types } from "mongoose";
import { MongooseDocument, MongooseResource } from "./_abstractResource";

import { CheckinType } from "../schemas/checkInType";
import { PropertyDocument } from "./property.resource";
import { CardReaderResource } from "./reader.resource";
import { UserDocument } from "./user.resource";

export interface ObservationDocument extends MongooseDocument {
  employee: Types.ObjectId & UserDocument;
  property: Types.ObjectId & PropertyDocument;
  beginning: Date;
  end?: Date;
  checkinType?: CheckinType;
}

export class ObservationResource extends MongooseResource<ObservationDocument> {
  /**
   * The observation resource holds a card reader resource for making sure observation changes updates to websocket clients
   * NOTE: (old) In the future the card reader resource should be used directly by components, and it should be thought of as a
   * general resource for reading/updating observations
   */
  private cardReaderResource: CardReaderResource;

  constructor() {
    super();

    this.setName("observation");

    this.setSchema({
      employee: { type: Schema.Types.ObjectId, ref: "default", required: true },
      property: {
        type: Schema.Types.ObjectId,
        ref: "property",
        required: true,
      },
      checkinType: { type: String, required: false },
      beginning: { type: Date, required: true },
      end: Date,
    });

    this.cardReaderResource = new CardReaderResource();
  }

  // Requests

  switch(
    property: PropertyDocument,
    employee: UserDocument,
    checkinType: CheckinType
  ) {
    return this.cardReaderResource.switch(property._id, employee._id);
  }

  begin(
    property: PropertyDocument,
    employee: UserDocument,
    checkinType: CheckinType
  ) {
    // NOTE: (old) checkinType was never used, and is still never used as of this hotfix
    return this.cardReaderResource.switch(property._id, employee._id, false);
  }

  end(property: PropertyDocument, employee: UserDocument) {
    return this.cardReaderResource.switch(property._id, employee._id, false);
  }

  whereIsTheEmployee(employee: UserDocument) {
    return this.sendRequest<ObservationDocument | undefined>(
      "/whereis",
      "post",
      {
        employeeId: employee._id,
      }
    );
  }

  listofobservations(query?: Object) {
    return this.sendRequest<ObservationDocument[]>(
      "/listofobservations",
      "post",
      {
        query: query,
      }
    );
  }

  listAllOngoingObservations() {
    return this.sendRequest<ObservationDocument[]>(
      "/listongoingobservations",
      "post",
      {}
    );
  }

  populateObservationProperty(query: Object) {
    return this.sendRequest<ObservationDocument[]>(
      "/populateObservationProperty",
      "post",
      { query }
    );
  }
}
