import * as React from 'react'
import { Component } from './abstractComponent'

export class Transition extends Component<{
    animation: "toggle" | "scale" | "fade" | "fade up" | "fade down" | "fade left" | "fade right" | "horizontal flip" | "vertical flip" | "drop" | "fly left" | "fly right"  | "fly down"  | "fly up"  | "swing left"  | "swing right"  | "swing up"  | "swing down"  | "slide down"  | "slide left"  | "slide right"  | "slide up"  | "jiggle"  | "flash"  | "shake"  | "pulse"  | "tada"  | "bounce",
    reversed?: boolean,
    in?: boolean,
    looping?: boolean,
    /* Length of the animation in milliseconds */ duration?: number,
    delay?: number,
    onComplete?: () => void
}>
{
    componentDidMount() {
        let element = $(this.domElement);
        let animation = this.props.animation as string

        if (this.props.in) {
            element = element.transition('hide')
            animation = animation + " in"
        }

        if (this.props.looping) {
            element = element.transition('set looping');
        }

        const transitionProcess = () => {
            element = element.transition({
                animation: this.props.animation,
                duration: (this.props.duration || 1500),
                onComplete: this.props.onComplete,
                queue: true,
                reverse: this.props.reversed
            })
        }

        if (this.props.delay) {
            setTimeout(transitionProcess, this.props.delay)
        }
        else {
            transitionProcess()
        }
    }

    render() {
        return React.Children.only(this.props.children as any)
    }
}