import Cookie from "js-cookie";
import * as Moment from "moment-timezone";
import "moment/locale/sv";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, useHistory } from "react-router-dom";
import { GlobalDeviceManager, GlobalSessionManager } from "./stores";

import { AppContent } from "./components/app/content";
import { AppProvider } from "./components/app/context";
import { Alert } from "./components/commons/alert";
import { Redirect, Route, SecureRoute, Switch } from "./components/navigation";
import { currentMonth, currentYear } from "./components/utils";
import { ParamsRoute } from "./contexts";

import { CardReaderView } from "./components/admin/cardReaderView";
import { ListOfEmployeesView } from "./components/admin/listOfEmployeesView";
import { ListOfObservationsView } from "./components/admin/listOfObservationsViews";
import { ListOfPropertiesView } from "./components/admin/listOfPropertiesView";
import { LoginView } from "./components/admin/loginView";
import { OngoingActivitiesView } from "./components/admin/ongoingActivitiesView";
import { PropertyDetailsView } from "./components/admin/propertyDetailsViews";

import { AccountSettingsView } from "./components/admin/accountSettingsView";
import { AddEmployeeView } from "./components/admin/addEmployeeView";
import { AddPropertyView } from "./components/admin/addPropertyView";
import { EditPropertyCrewListView } from "./components/admin/editPropertyCrewList";
import { EmployeeDetailsView } from "./components/admin/employeeDetailsView";
import { PropertyCrewList } from "./components/admin/propertyCrewList";
import { PropertyInspectionView } from "./components/admin/propertyInspectionView";
import { PropertyStatisticsView } from "./components/admin/propertyStatisticsView";
import { SystemAdminViews } from "./components/admin/systemAdminViews";
import { AdminTemplate } from "./components/templates/adminTemplate";

import { EmployeeStatisticsView } from "./components/admin/employeeStatisticsView";
import { MigrateView } from "./components/admin/migrate";
import { CookieConsentBanner } from "./components/commons/cookieConsentBanner";
import { DeviceProvider } from "./contexts";
import { UserDocument } from "./resources/user.resource";
// FIXME: (2023) re-add? import { GetXmlView } from "./components/admin/getXmlView";

export const Root: React.FC = () => {
  //const history = useHistory();
  GlobalSessionManager.currentHistory = useHistory();

  return (
    <Route path="/">
      <Switch>
        {/* Default redirect */}
        <Redirect exact from="/" to="/app" />
        {/* App Routes */}
        <Route exact path="/app">
          <AppProvider
            currentUser={
              GlobalSessionManager.currentUser as UserDocument | undefined
            }
          >
            <AppContent />
          </AppProvider>
        </Route>
        {/* Admin Routes */}
        <Route path="/">
          <div className="pushable">
            <Alert />
            <div id="scroll-container" className="pusher">
              <CookieConsentBanner />
              <Switch>
                <Redirect exact from="/" to="/admin" />
                <Redirect from="/information" to="/admin" />
                <Route path="/admin/login" component={LoginView} />

                {/* <Route exact path="/xml" component={GetXmlView} />
                      <Route path="/xml/:taxId" component={GetXmlView} /> */}

                <SecureRoute
                  path="/admin"
                  restrictedTo={{ type: "organization.manager" }}
                  redirectTo="/admin/login"
                >
                  <AdminTemplate>
                    <Switch>
                      <SecureRoute
                        path="/admin/system"
                        restrictedTo={{ type: "system.owner" }}
                        redirectTo="/admin/login"
                      >
                        <SystemAdminViews />
                      </SecureRoute>
                      {/* TODO: Remove after migration */}
                      <SecureRoute
                        path="/admin/migrate"
                        restrictedTo={{ type: "system.owner" }}
                      >
                        <MigrateView />
                      </SecureRoute>
                      {/* TODO: Remove after migration */}
                      <Route
                        exact
                        path="/admin/ongoing"
                        component={OngoingActivitiesView}
                      />
                      <Redirect exact path="/admin" to="/admin/ongoing" />

                      <ParamsRoute path="/admin/observations/:year/:month">
                        <ListOfObservationsView />
                      </ParamsRoute>
                      <Redirect
                        exact
                        path="/admin/observations"
                        to={`/admin/observations/${currentYear()}/${currentMonth()}`}
                      />

                      <Route exact path="/admin/properties">
                        <ListOfPropertiesView />
                      </Route>
                      <Route exact path="/admin/properties/archived">
                        <ListOfPropertiesView showArchived />
                      </Route>
                      <Route
                        path="/admin/properties/add"
                        component={AddPropertyView}
                      />

                      <ParamsRoute
                        exact
                        path="/admin/properties/statistics/:property/:year/:month"
                      >
                        <PropertyStatisticsView />
                      </ParamsRoute>
                      <ParamsRoute
                        exact
                        path="/admin/properties/inspection/:property"
                      >
                        <PropertyInspectionView />
                      </ParamsRoute>

                      <Redirect
                        exact
                        path="/admin/properties/statistics/:property"
                        to={`/admin/properties/statistics/:property/${currentYear()}/${currentMonth()}`}
                      />

                      <ParamsRoute path="/admin/properties/:property/">
                        <PropertyDetailsView>
                          <Switch>
                            <Route
                              exact
                              path="/admin/properties/:property/crew"
                              component={PropertyCrewList}
                            />
                            <Route
                              path="/admin/properties/:property/crew/edit"
                              component={EditPropertyCrewListView}
                            />
                            <Redirect
                              exact
                              path="/admin/properties/:property"
                              to="/admin/properties/:property/crew"
                            />
                          </Switch>
                        </PropertyDetailsView>
                      </ParamsRoute>

                      <Route
                        exact
                        path="/admin/employees"
                        component={ListOfEmployeesView}
                      />
                      <Route exact path="/admin/employees/archived">
                        <ListOfEmployeesView showArchived />
                      </Route>
                      <Route
                        path="/admin/employees/add"
                        component={AddEmployeeView}
                      />
                      <ParamsRoute exact path="/admin/employees/:employee">
                        <EmployeeDetailsView />
                      </ParamsRoute>
                      <Route
                        path="/admin/account"
                        component={AccountSettingsView}
                      />

                      <Route
                        exact
                        path="/admin/card-readers"
                        component={CardReaderView}
                      />

                      <Route
                        path="/admin/employees/statistics/:employee/:year/:month"
                        component={EmployeeStatisticsView}
                      />
                      <Redirect
                        exact
                        path="/admin/employees/statistics/:employee"
                        to={`/admin/employees/statistics/:employee/${currentYear()}/${currentMonth()}`}
                      />
                    </Switch>
                  </AdminTemplate>
                </SecureRoute>
              </Switch>
            </div>
          </div>
        </Route>
      </Switch>
    </Route>
  );
};

// On run
// Mount the component as soon as the DOM has loaded
// Ignored when in a node environment
if (RUNTIME_ENVIRONMENT == "browser") {
  document.addEventListener("DOMContentLoaded", async function (_) {
    const mountId = "root";

    // Make sure Moment uses the correct language
    Moment.locale("sv");

    // Set the correct device type
    GlobalDeviceManager.type = (Cookie.get("deviceType") as any) || "browser";

    // Get the initial time when the page was requested from the server if set
    // FIXME: (2023) Removed, used for SSR.
    //const initialNow = new Date(Cookie.get("initialNow") as any);

    // Render the client
    const rootElement = document.getElementById(mountId);
    const root = createRoot(rootElement!);
    root.render(
      <BrowserRouter basename="/">
        <DeviceProvider>
          <Root />
        </DeviceProvider>
      </BrowserRouter>
    );
  });
}
