import Lodash from "lodash";
import * as React from "react";
import { Icon } from "./_icon";
import { FormMemberProps, FormStatus } from "./form";
import { TextFormatRule } from "./rules";

/**
 * Type declaring valid autoComplete values
 * @see https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofilling-form-controls%3A-the-autocomplete-attribute
 */
type ValidAutoCompleteValue =
  // Option A
  | "name"
  | "honorific-prefix"
  | "given-name"
  | "additional-name"
  | "family-name"
  | "honorific-suffix"
  | "nickname"
  | "username"
  | "new-password"
  | "current-password"
  | "organization-title"
  | "organization"
  | "street-address"
  | "address-line1"
  | "address-line2"
  | "address-line3"
  | "address-level4"
  | "address-level3"
  | "address-level2"
  | "address-level1"
  | "country"
  | "country-name"
  | "postal-code"
  | "cc-name"
  | "cc-given-name"
  | "cc-additional-name"
  | "cc-family-name"
  | "cc-number"
  | "cc-exp"
  | "cc-exp-month"
  | "cc-exp-year"
  | "cc-csc"
  | "cc-type"
  | "transaction-currency"
  | "transaction-amount"
  | "language"
  | "bday"
  | "bday-day"
  | "bday-month"
  | "bday-year"
  | "sex"
  | "url"
  | "photo"
  // Option B 1
  | "tel"
  | "tel-country-code"
  | "tel-national"
  | "tel-area-code"
  | "tel-local"
  | "tel-local-prefix"
  | "tel-local-suffix"
  | "tel-extension"
  // Option B 2
  | "email"
  | "impp"
  | "home"
  | "work"
  | "mobile"
  | "fax"
  | "pager";

interface InputProps extends FormMemberProps {
  /** ID */ id?: string;
  /** Style for inputfield */ inputStyle?: {};
  /** An icon to display inside the input */ icon?: Icon;
  /** Autocomplete field **/ autoComplete?: ValidAutoCompleteValue;
  /** A list of rules that applies to this component */ rules?: TextFormatRule[];
  /** A placeholder shown inside the input when otherwise empty */ placeholder?: string;
  /** Hide characters made to this input */ protectedCharacters?: boolean;
  /** Specifies type of the input field */ type?: string;
  /** Makes the input field take up the width of it's prarent container */ fluid?: boolean;
  /** Adds a prefix to the input */ label?: React.ReactNode;
  /** The value set for when it hasn't been otherwise modified */ defaultValue?: string;
  /** A prop to manually set the component value */ value?: string;
  /** Called each time the component's value is changed */ onChange?: (
    value: string
  ) => any;
}

/**
 * An input is a versitile changable component for getting text strings from the user
 */
export const Input: React.FunctionComponent<InputProps> = (props) => {
  const [isLoading, setLoading] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(false);
  const form = React.useContext(FormStatus);
  const [value, setValue] = React.useState<string>("");

  // Set status
  const loading = isLoading || form.loading;
  const disabled = isDisabled || isLoading || form.disabled || form.loading;

  // Lifecycle
  React.useEffect(() => {
    if (props.rules && props.rules.length) {
      form.addValidationRules(props.name, props.rules as any, !!props.optional);
    } else {
      form.removeValidationRules(props.name);
    }
  }, [props.rules]);
  React.useEffect(() => {
    if (props.value && props.value != value) {
      setValue(props.value);
    } else if (defaultValue && !value) {
      setValue(defaultValue);
    }
  }, [props.defaultValue, props.value]);
  React.useEffect(() => {
    if (value) {
      form.addValue(props.name, value);
    } else {
      form.removeValue(props.name);
    }
  }, [value]);

  // Set default properties for the input element
  const inputProps = {
    mask: undefined as any,
    guide: "true",
    autoComplete: props.autoComplete,
    placeholderchar: "_",
    disabled: disabled || props.disabled,
    pattern: undefined as any,
    type: props.type || "text",
    name: props.name,
    placeholder: undefined as any,
    maxLength: undefined as any,
    onChange: (event) => {
      const newValue = event.target.value;
      if (newValue == value) {
        return;
      }
      setValue(newValue);
      if (props.onChange) {
        props.onChange(newValue);
      }
      // FIXME: update form values
      return;
    },
  };

  // Load properties from input rules
  for (const rule of props.rules || []) {
    if (rule.keyboard) {
      inputProps.type = rule.keyboard;
    }

    if (rule.placeholder) {
      inputProps.placeholder = rule.placeholder;
    }

    if (rule.mask) {
      inputProps.mask = rule.mask.definition.toString();

      if (rule.mask.guided) {
        inputProps.guide = rule.mask.guided ? "true" : "false";
      }
      if (rule.mask.placeholderCharacter) {
        inputProps.placeholderchar = rule.mask.placeholderCharacter;
      }
    }

    if (
      rule.type.substring(0, 9) == "maxLength" ||
      rule.type.substring(0, 11) == "exactLength"
    ) {
      inputProps.maxLength = (rule.type.match(/\d+/) as any)[0];
    }
  }

  // Numeric keyboards are triggered by pattern and not type, so let's fix that
  if (inputProps.type == "integer") {
    inputProps.type = "text";
    inputProps.pattern = "[0-9]*";
  }

  // Set the placeholder for the input
  inputProps.placeholder = props.placeholder || inputProps.placeholder;

  // Lastly and most important, we protect entered character for this input if this input is protected
  if (props.protectedCharacters) {
    inputProps.type = "password";
  }

  // Make sure defaultValue is not an undefined string
  const defaultValue =
    props.defaultValue == "undefined" ? undefined : props.defaultValue;

  // Set style
  const style =
    props.type == "hidden"
      ? { display: "none" }
      : { WebkitTransform: "translateZ(0px)" };

  // Set className
  const className =
    "ui input " +
    (!!props.className ? props.className : "") +
    (loading || props.loading ? " loading" : "") +
    (disabled || props.disabled ? " disabled" : "") +
    (props.icon ? " left icon" : "") +
    (props.fluid ? " fluid" : "") +
    (props.label ? " labeled" : "");
  return (
    <div
      className={className}
      key={props.name}
      style={Lodash.merge(style, props.style)}
    >
      {props.label && <div className="ui label">{props.label}</div>}
      {props.icon && <i className={props.icon + " ui icon"} />}
      {props.defaultValue ? (
        <input
          {...inputProps}
          defaultValue={defaultValue}
          style={props.inputStyle}
          key={props.name}
        />
      ) : (
        <input
          {...inputProps}
          value={value}
          style={props.inputStyle}
          key={props.name}
        />
      )}
    </div>
  );
};
