import * as React from "react";
import * as ReactDom from "react-dom";

export interface AccordionProps {
  style?: React.CSSProperties;
  /** Title of the accordion */ title: React.ReactNode;
  /** A styled accordion adds basic formatting */ styled?: boolean;
  /** A dropdown icon is shown before the title */ titleIcon?: boolean;
  /** Sets the color of the title */ titleColor?: string;
  /** An accordion can take up the width of its container */ fluid?: boolean;
  /** An accordion can be formatted to appear on dark backgrounds */ inverted?: boolean;
  /** An accordion can be formatted to have no padding */ compact?: boolean;
  /** Adds a border to the accordion */ bordered?: boolean;
  /** A callback that is called with true if opened or vice versa */ onStateChanged?: (
    isOpen: boolean
  ) => void;
  children?: any;
}

/** Semantic UI Accordion */
export class Accordion extends React.Component<AccordionProps, {}> {
  protected domElement;

  public componentDidMount() {
    this.domElement = $(ReactDom.findDOMNode(this) as Element);
    this.domElement.accordion();
  }

  public open = () => {
    this.domElement.accordion("open", 0);
  };

  public close = () => {
    this.domElement.accordion("close", 0);
  };

  /** @prop returns true if accordion is open */
  public get isOpen(): boolean {
    return this.domElement.children("div.title").hasClass("active");
  }

  private get dropdownIcon() {
    if (this.props.titleIcon) {
      return (
        <span>
          <i className="dropdown icon" />
        </span>
      );
    }
  }

  render() {
    const className =
      "ui accordion" +
      (this.props.styled ? " styled" : "") +
      (this.props.inverted ? " inverted" : "") +
      (this.props.fluid ? " fluid" : "");

    const title = this.props.title || (
      <span>
        <i className="dropdown icon" />
        Visa mer...
      </span>
    );

    const titleStyle: React.CSSProperties = {
      padding: this.props.compact ? 0 : undefined,
      color: this.props.titleColor ? this.props.titleColor : undefined,
    };

    return (
      <div className={className} style={this.props.style || {}}>
        <div className="title" style={titleStyle}>
          {this.dropdownIcon}
          {title}
        </div>
        <div className="content">{this.props.children}</div>
      </div>
    );
  }
}
