//  This file contains functions and objects for making sure text inputs and other changable components
//  are filled out correctly

import Lodash from "lodash";
import Moment from "moment-timezone";

// Import some predinfed masks from the text-mask library
// https://github.com/text-mask/text-mask/tree/master/addons
import emailMaskDefinition from "text-mask-addons/dist/emailMask";
import createNumberMaskDefinition from "text-mask-addons/dist/createNumberMask";

// FIXME: (old) The MaskedInput has been removes from the input component because of multiple reported issues form customers.
// FIXME: (old This means that the mask definitions in this file won't be applied.

/**
 * A text mask is used for stricly controlling what information is entered in an text input component
 */
export interface TextMask {
  /**
   * An array containing either a fixed string, or an regex expression
   * this is an example for a phone number: ['+46', /\d/, /\d/, '-', /\d{5,7}/
   * It can also be a function returning such an array.
   */
  definition: ((value: string) => (string | RegExp)[]) | (string | RegExp)[];

  /** A character used for fillable spots when the guide is shown, defaults to '_' */
  placeholderCharacter?: string;

  /** Whenever to always show the static parts of the text mask, otherwise they are shown only when reached by the user input, default to true */
  guided?: boolean;
}

/**
 * An format rule is used to validate changable components (inputs, selections, checkboxes) inside a form
 */
export interface FormatRule {
  /** A unique identifer for this rule, a Semantic UI form validation rule when applicable */ type: string;
  /** A prompt displayed when the input is invalid */ prompt?: string;
  /** A placeholder shown inside the input when otherwise empty */ placeholder?: string;
}

/**
 * An text format rule is used to validate changable components that accecpts text input
 */
export interface TextFormatRule extends FormatRule {
  /** An text mask for the input */ mask?: TextMask;
  /** The type of keyboard to use on devices with virtual keyboads */ keyboard?:
    | "text"
    | "tel"
    | "url"
    | "integer"; // types email and number is not supported by the text-mask library & other con
}

// ----------------------------------
// Predefined format rules
// ----------------------------------

/** The value can not be empty */
export const NotEmpty = (prompt?: string): FormatRule & TextFormatRule => {
  if (!prompt) {
    prompt = "Du måste skriva in något här";
  }
  return {
    type: "empty",
    prompt: prompt,
  };
};

/** The value must be a valid as an url */
export const IsAnUrl = (prompt?: string): TextFormatRule => {
  if (!prompt) {
    prompt = "Var snäll och ange en fungerande url";
  }
  return {
    type: "url",
    keyboard: "url",
    prompt: prompt,
  };
};

/** The value must be a valid email address */
export const IsAnEmailAddress = (prompt?: string): TextFormatRule => {
  if (!prompt) {
    prompt = "Var snäll och ange en riktig e-post adress";
  }
  return {
    type: "email",
    mask: {
      definition: emailMaskDefinition,
    },
    placeholder: "name@web.com",
    prompt: prompt,
  };
};

/** The value must contains this string */
export const Containing = (
  value: string,
  caseSensitive?: boolean,
  prompt?: string
): TextFormatRule => {
  return {
    type: "contains" + (caseSensitive ? "Exactly" : "") + "[" + value + "]",
    prompt: prompt || "",
  };
};

/** The value can't contain this string */
export const NotContaining = (
  value: string,
  caseSensitive?: boolean,
  prompt?: string
): TextFormatRule => {
  return {
    type:
      "doesntContain" + (caseSensitive ? "Exactly" : "") + "[" + value + "]",
    prompt: prompt || "",
  };
};

/** The value must be exactly this string value */
export const Exactly = (
  value: string,
  caseSensitive?: boolean,
  prompt?: string
): TextFormatRule => {
  return {
    type: "is" + (caseSensitive ? "Exactly" : "") + "[" + value + "]",
    prompt: prompt || "",
  };
};

/** The value must be different from this value */
export const NotExactly = (
  text: string,
  caseSensitive?: boolean,
  prompt?: string
): TextFormatRule => {
  return {
    type: "not" + (caseSensitive ? "Exactly" : "") + "[" + text + "]",
    prompt: prompt || "",
  };
};

/** The value can't be longer than this length */
export const MaximumLength = (
  length: number,
  prompt?: string
): TextFormatRule => {
  if (!prompt) {
    prompt = "Var snäll och ange mindre än " + length + " siffror";
  }
  return {
    type: "maxLength[" + length + "]",
    prompt: prompt,
  };
};

/** The value can't be shorter than this length */
export const MinimumLength = (
  length: number,
  prompt?: string
): TextFormatRule => {
  if (!prompt) {
    prompt = "Var snäll och ange minst " + length + " siffror";
  }
  return {
    type: "minLength[" + length + "]",
    prompt: prompt,
  };
};

/** The value must have this length exactly */
export const ExactLength = (
  length: number,
  prompt?: string
): TextFormatRule => {
  if (!prompt) {
    prompt = "Var snäll och ange " + length + " siffror";
  }
  return {
    type: "exactLength[" + length + "]",
    prompt: prompt,
  };
};

/** The value must be an integer number */
export const IsAnInteger = (prompt?: string): TextFormatRule => {
  if (!prompt) {
    prompt = "Var snäll och ange ett giltigt nummer";
  }
  return {
    type: "integer",
    keyboard: "integer",
    mask: {
      definition: createNumberMaskDefinition({
        prefix: "",
        suffix: "",
        includeThousandsSeparator: false,
        allowDecimal: false,
        allowNegative: false,
      }),
      guided: false,
    },
    prompt: prompt,
  };
};

/** The value must be an integer within this range */
export const IsAnIntegerBetween = (
  start: number,
  stop: number,
  prompt?: string
): TextFormatRule => {
  if (!prompt) {
    prompt = "Var snäll och ange ett giltigt nummer";
  }
  return {
    type: "integer[" + start + ".." + stop + "]",
    mask: {
      definition: createNumberMaskDefinition({
        prefix: "",
        suffix: "",
        includeThousandsSeparator: false,
        allowDecimal: false,
        allowNegative: false,
      }),
      guided: false,
    },
    prompt: prompt,
  };
};

/** The value must be a decimal number */
export const IsADecimal = (
  allowNegative?: boolean,
  prompt?: string
): TextFormatRule => {
  if (!prompt) {
    prompt = "Var snäll och ange ett giltigt nummer";
  }
  return {
    type: "decimal",
    mask: {
      definition: createNumberMaskDefinition({
        prefix: "",
        suffix: "",
        includeThousandsSeparator: false,
        allowDecimal: true,
        decimalSymbol: ".",
        decimalLimit: 100,
        allowNegative: allowNegative || true,
        requireDecimal: true,
      }),
      guided: false,
    },
    prompt: prompt,
  };
};

/** The value must be a number, either decimal or integer */
export const IsANumber = (
  allowNegative?: boolean,
  prompt?: string
): TextFormatRule => {
  if (!prompt) {
    prompt = "Var snäll och ange ett giltigt nummer";
  }
  return {
    type: "number",
    mask: {
      definition: createNumberMaskDefinition({
        prefix: "",
        suffix: "",
        includeThousandsSeparator: false,
        allowDecimal: true,
        decimalSymbol: ",",
        decimalLimit: 100,
        allowNegative: allowNegative || true,
      }),
      guided: false,
    },
    prompt: prompt,
  };
};

/** The value must match exactly the value of the input with this key */
export const TheSameAs = (key: string, prompt?: string): FormatRule => {
  return {
    type: "match[" + key + "]",
    prompt: prompt || "",
  };
};

/** The value must be different from the value of the input with this key */
export const NotTheTheSameAs = (key: string, prompt?: string): FormatRule => {
  return {
    type: "different[" + key + "]",
    prompt: prompt || "",
  };
};

/** Checkbox must be checked */
export const IsChecked = (prompt?: string): FormatRule => {
  return {
    type: "checked",
    prompt: prompt || "",
  };
};

/** A multiple select field must contain at minimum this many selections */
export const MinimumNumberOfSelections = (
  count: number,
  prompt?: string
): FormatRule => {
  if (!prompt) {
    prompt = "Du måste välja minst " + count;
  }
  return {
    type: "minCount[" + count + "]",
    prompt: prompt,
  };
};

/** A multiple select field must contain exactly this many selections */
export const ExactNumberOfSelections = (
  count: number,
  prompt?: string
): FormatRule => {
  if (!prompt) {
    prompt = "Var snäll och ange " + count + " st";
  }
  return {
    type: "exactCount[" + count + "]",
    prompt: prompt,
  };
};

/** A multiple select field can't contain more than this many selections */
export const MaximumNumberOfSelections = (
  count: number,
  prompt?: string
): FormatRule => {
  if (!prompt) {
    prompt = "Du kan inte välja fler än " + count;
  }
  return {
    type: "maxCount[" + count + "]",
    prompt: prompt,
  };
};

// Custom validation

/** This number keeps tracks of custom rules added to the window */
let _nrOfCustomRules: number = 0;

/** A custom form validation rule */
export const CustomRule = (
  options: {
    validator: (fieldValue: string) => boolean;
    prompt?: string;
    mask?: TextMask;
    keyboard?: "text" | "tel" | "url" | "integer";
  },
  extendsRule?: FormatRule
): FormatRule => {
  // Create a unique identifier and add it to the browser
  const identifier = (++_nrOfCustomRules).toString();

  if ($.fn.form.settings.rules) {
    $.fn.form.settings.rules[identifier] = options.validator;
  }

  // Create the rule object and return it
  const rule = {} as FormatRule;

  if (!!extendsRule) {
    Lodash.assign(rule, extendsRule);
  }

  Lodash.assign(rule, {
    type: identifier,
    prompt: options.prompt,
    mask: options.mask,
    keyboard: options.keyboard,
  });

  return rule;
};

// TODO: (old) I made this which does not trigger when the value is empty (all our rules should behave like this)
export const IsIntegerOneOrHigher = (prompt?: string): FormatRule => {
  if (!prompt) {
    prompt = "Du måste ange ett heltal som är större än 0";
  }
  return CustomRule({
    validator: (value) => !value || /^[1-9][0-9]*$/.test(value),
    prompt: prompt,
    keyboard: "integer",
  });
};

/** The value may only contain digits or be empty */
export const IsOnlyDigits = (prompt?: string): FormatRule => {
  if (!prompt) {
    prompt = "Du får endast ange siffror";
  }
  return CustomRule({
    validator: (value) => /^[0-9]*$/.test(value),
    prompt: prompt || "Du får endast ange siffror",
    keyboard: "integer",
  });
};

/** The value may only contain digits or be empty */
export const IsEmptyOrDecimal = (
  allowNegative?: boolean,
  prompt?: string
): FormatRule => {
  if (!prompt) {
    prompt = "Var snäll och ange ett decimal tal";
  }
  return CustomRule({
    validator: (value) => !value || /^(0|[1-9]\d*)(\.\d+)?$/.test(value),
    mask: {
      definition: createNumberMaskDefinition({
        prefix: "",
        suffix: "",
        includeThousandsSeparator: false,
        allowDecimal: true,
        decimalSymbol: ".",
        decimalLimit: 20,
        allowNegative: allowNegative || true,
        requireDecimal: true,
      }),
      guided: false,
    },
    prompt: prompt,
    keyboard: "integer",
  });
};

/** The value may only contain digits or be empty */
export const IsEmptyOrInteger = (prompt?: string): FormatRule => {
  if (!prompt) {
    prompt = "Var snäll och ange ett heltal";
  }
  return CustomRule({
    validator: (value) => !value || /^[0-9][0-9]*$/.test(value),
    prompt: prompt,
    keyboard: "integer",
  });
};

/** The value must be a valid date */
export const IsValidDate = (dateFormat: string): FormatRule => {
  let prompt = `Var snäll och ange ett giltigt datum (${dateFormat})`;

  return CustomRule({
    validator: (value) => !value || Moment(value, dateFormat, true).isValid(),
    prompt: prompt,
  });
};

/** Validates a string as a potential IBAN number */
export const IsIBAN = (): FormatRule => {
  let prompt = "Var snäll och ange ett giltigt iban";

  return CustomRule({
    validator: (value) =>
      !value || (/^[A-Z]{2}[0-9A-Z]*$/.test(value) && value.length > 14),
    prompt,
  });
};
