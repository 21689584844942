import { Schema, SchemaDefinition, Types } from "mongoose";
import { MongooseDocument, MongooseResource } from "./_abstractResource";
import { CardDocument } from "./card.resource";
import { PropertyDocument, PropertyResource } from "./property.resource";

/**
 * @type {CardReaderDocument} represents
 * a card reader used to authenticate users
 * via RFID. @see {CardDocument}.
 */
export interface CardReaderDocument extends MongooseDocument {
  /** Holds a reference to the property the reader is stationed at */
  propertyId: Types.ObjectId;
  /** The organization this reader belongs to */
  organization: Types.ObjectId;
  /** Gives a reader a *human-readable* identifier. */
  label: string;
  /** A predefined reader id. */
  readerId: string;
  /** Populated property document */
  property: PropertyDocument;
}

/**
 * @type {CardReaderResource} defines the
 * API and database model for card readers.
 */
export class CardReaderResource extends MongooseResource<CardReaderDocument> {
  /** CardReader database schema */
  public static Schema: SchemaDefinition = {
    readerId: { type: String, required: true, unique: true },
    property: { type: Schema.Types.ObjectId, ref: "property", required: true },
    organization: {
      type: Schema.Types.ObjectId,
      ref: "organization",
      required: true,
    },
    label: { type: String, required: true },
  };

  constructor() {
    super();
    this.setName("reader");
    this.setSchema(CardReaderResource.Schema);
    this.addVirtualField("propertyId", this.propertyId);
  }

  /**
   * Returns the property id from a document.
   * Used as a virtual field.
   *
   * @param document Document to get property id of
   */
  private propertyId(document: CardReaderDocument): Types.ObjectId {
    return PropertyResource.IsAPropertyDocument(document.property)
      ? document.property._id
      : (document.property as any as Types.ObjectId);
  }

  /**
   * Read card by reader.
   * Return true if the user of the card was
   * checked in to the property and false
   * if the user was checked out.
   *
   * @param reader The reader that reads the card.
   * @param card The card that is being read by the reader.
   */
  public async read(reader: CardReaderDocument, card: CardDocument) {
    const payload = { reader: reader.readerId, rfid: card.rfid };
    return this.sendRequest<{
      status: "present" | "away";
      timestamp: Date;
      observing: boolean;
    }>("/read", "post", payload);
  }

  /**
   * Manually switch the current status of the given user at the given property.
   * Return true if the user of the card was
   * checked in to the property and false
   * if the user was checked out.
   *
   * NOTE: (old) This is now done by the card reader resource as to limit the changes needed server side and in the GUI.
   * In the future either the observation resource should integrate server side with websocket clients or
   * this resource should be rebranded to handle all changing of observation status.
   * NOTE: (old) expectedResultingObservationStatus is used to debug any possible errors from not using the previous methods .end() and .begin() in the
   * Observation Resource.
   *
   * @param propertyId The id of the property to switch status at.
   * @param userId The id of the user to switch status of.
   * @param expectedResultingObservationStatus Keep track, for debugging purposes, what the result of the method call should be.
   */
  public async switch(
    propertyId: CardReaderDocument["propertyId"],
    userId: CardDocument["userId"],
    expectedResultingObservationStatus: boolean | null = null
  ) {
    const payload = { propertyId, userId };
    const results = await this.sendRequest<{
      status: "present" | "away";
      timestamp: Date;
      observing: boolean;
    }>("/switch", "post", payload);
    const { observing } = results;

    if (
      expectedResultingObservationStatus != null &&
      expectedResultingObservationStatus != observing
    ) {
      console.warn(
        `Warning: attempted to ${
          expectedResultingObservationStatus ? "sign in" : "sign out"
        } the user ${userId} but the call resulted in the user being ${
          observing ? "signed in" : "signed out"
        }.`
      );
    }

    return results;
  }

  public async populateProperty(query: Object) {
    return this.sendRequest<CardReaderDocument[]>(
      "/populateProperty",
      "post",
      query
    );
  }
}
