import { Schema, Types } from "mongoose";

export type PrivilegeType =
  | "organization.manager"
  | "property.manager"
  | "property.crew"
  | "system.owner";

export interface PrivilegeDocument {
  type?: PrivilegeType;
  organization?: Types.ObjectId;
  property?: Types.ObjectId;
}

export var PrivilegeSchema = new Schema(
  {
    type: { type: String, required: false },
    organization: {
      type: Schema.Types.ObjectId,
      ref: "organization",
      required: false,
    },
    property: { type: Schema.Types.ObjectId, ref: "property", required: false },
  },
  { _id: false }
);
