import { Types } from "mongoose";
import * as React from "react";
import { ObservationResource } from "../../../resources/observation.resource";
import {
  PropertyDocument,
  PropertyResource,
} from "../../../resources/property.resource";
import { CheckinType } from "../../../schemas/checkInType";
import { KosmicComponent } from "../../commons";
import { Toggle } from "../../commons/toggle";
import { AppContext, Consume } from "../context";

interface Props {
  property: PropertyDocument;
}

export class PropertyCard extends KosmicComponent<Props> {
  @Consume(AppContext)
  protected app!: AppContext;

  /** Callback that handles the check-in/out toggle button */
  private async onToggle(status: boolean) {
    const { property } = this.props;
    const { selectedEmployee } = this.app;

    if (!selectedEmployee) {
      throw new Error(
        "Tried to toggle the status without any employee selected in the provider."
      );
    }

    // Check in
    if (status == true) {
      const observation = await new ObservationResource().whereIsTheEmployee(
        selectedEmployee
      );
      if (observation) {
        observation.property = (await new PropertyResource().get(
          observation.property
        )) as Types.ObjectId & PropertyDocument;
        const confirmation = await this.app.showQuestionsDimmer({
          title: "Vill du byta arbetsplats?",
          body: `Du är redan incheckad på ${observation.property.name} och kommer att checkas ut därifrån.`,
          continueText: "Byt arbetsplats",
          continueColor: "red",
          icon: "building outline",
        });

        if (!confirmation) {
          this.app.updateState({});
          return;
        }
      }
      await new ObservationResource().begin(
        property,
        selectedEmployee,
        CheckinType.application
      );
      this.app.updateState({});
    }
    // Check out
    else {
      await new ObservationResource().end(property, selectedEmployee);
      this.app.updateState({});
    }
  }

  /** Callback for when the 'administer' option of a property is selected */
  private async onAdminister(property: PropertyDocument) {
    await this.app.updateState({ selectedProperty: property });
  }

  private get isActive() {
    return (
      this.app.activePropertyId &&
      this.app.activePropertyId.toString() == this.props.property.id
    );
  }

  private get isManager() {
    return this.globals.session.userHasRole({
      type: "property.manager",
      property: this.props.property._id,
    });
  }

  render() {
    const { property } = this.props;
    return (
      <div
        className={`item ${this.isActive ? "active" : ""}`}
        key={property.id}
        style={{
          display: "flex",
          flexWrap: "wrap",
          padding: 0,
          alignItems: "center",
        }}
      >
        <div style={{ flex: "4 0 15rem", margin: "1rem" }}>
          <span>{this.isActive && "Incheckad på"}</span>
          &nbsp;
          <b>{property.name}</b>
        </div>
        <div
          style={{
            flex: "1 0 15rem",
            margin: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {this.isManager ? (
            <a
              style={{ cursor: "pointer" }}
              onClick={() => this.onAdminister(property)}
            >
              <i className="settings icon" />
              Administrera
            </a>
          ) : (
            <span />
          )}
          <span style={{ float: "right" }}>
            <Toggle
              active={this.isActive}
              onToggle={(status) => this.onToggle(status)}
            />
          </span>
        </div>
      </div>
    );
  }
}
