import Lodash from "lodash";
import * as React from "react";

export class ProtectedTableCell extends React.Component<
  { content: string },
  { hidden: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
    };
  }

  toggleDisplayOfPasscode = () => {
    this.setState({
      hidden: !this.state.hidden,
    });
  };

  /*componentDidMount = () => this.componentDidUpdate();
    componentDidUpdate()
    {
        const buttons = $(ReactDom.findDOMNode(this)).find('.ui.compact.icon.button');

        buttons.popup({
            exclusive: false,
            delay: {
                show: 600,
                hide: 0
            }
        });
    }*/

  render() {
    const contentElement = this.state.hidden ? (
      <span>{Lodash.repeat("●", this.props.content.length)}</span>
    ) : (
      <span>{this.props.content}</span>
    );
    const iconElement = this.state.hidden ? (
      <i className="unhide icon" />
    ) : (
      <i className="hide icon" />
    );
    const buttonHint = this.state.hidden ? "Visa" : "Göm";

    return (
      <td
        className="protected"
        style={{ display: "flex", alignItems: "center" }}
      >
        {contentElement}
        <div className="ui basic buttons" style={{ marginLeft: "8px" }}>
          <button
            data-content={buttonHint}
            className="ui compact icon button"
            onClick={this.toggleDisplayOfPasscode}
          >
            {iconElement}
          </button>
        </div>
      </td>
    );
  }
}
