import * as React from "react";
import Moment from "moment-timezone";
import Lodash from "lodash";
import { KosmicComponent } from "../commons";
import { formatDuration } from "../utils";
import { ProtectedTableCell } from "../commons/protectedTableCell";
import { ConfirmButton } from "../commons/confirmButton";

import {
  ObservationDocument,
  ObservationResource,
} from "../../resources/observation.resource";
import { CheckinType } from "../../schemas/checkInType";

interface State {
  /** The list of observations to display */
  observations: ObservationDocument[];
  /** Indicates that observations are being loaded */
  loading: boolean;
}

export class OngoingActivitiesView extends KosmicComponent<{}, State> {
  state: State = {
    loading: true,
    observations: [],
  };

  /** Fetches all ongoing observations and store them in state */
  private async fetchOngoingObservations() {
    const observations =
      await new ObservationResource().listAllOngoingObservations();
    await this.setState({
      observations,
      loading: false,
    });
  }

  /** Ends an ongoing observation and refresh the view */
  private async endObservationAndRefresh(observation: ObservationDocument) {
    await new ObservationResource().end(
      observation.property,
      observation.employee
    );
    await this.fetchOngoingObservations();
  }

  async componentDidMount() {
    // Fetch ongoing observations when the view is loaded
    await this.fetchOngoingObservations();
  }

  private renderObservation(observation: ObservationDocument) {
    const duration =
      formatDuration(
        Moment.duration(Moment().diff(observation.beginning)).asMinutes()
      ) +
      ". Checkade in " +
      Moment(observation.beginning).calendar().toLowerCase();

    const checkinIcon = (function () {
      switch (observation.checkinType) {
        case CheckinType.rfid:
          return <i className="credit card outline icon" />;
        case CheckinType.ipad:
          return <i className="tablet alternate icon" />;
        case CheckinType.application:
        case undefined:
          return <i className="mobile alternate icon" />;
      }
    })();

    if (observation.employee) {
      return (
        <tr key={observation.id}>
          <td className="single line">{observation.employee.name}</td>
          <td className="single line">
            {checkinIcon} {duration}
          </td>
          <ProtectedTableCell content={observation.employee.personNr} />
          <td className="single line">{observation.employee.company}</td>
          <td className="floated right single line">
            <ConfirmButton
              action={() => this.endObservationAndRefresh(observation)}
            />
          </td>
        </tr>
      );
    }
  }

  private renderTables() {
    const observationsPerProperty = Lodash.groupBy(
      this.state.observations,
      (observation) => observation.property.id
    );
    return Lodash.map(observationsPerProperty, (observations) => {
      const { property } = observations[0];
      if (!property.archived) {
        return (
          <div key={property.id} style={{ marginBottom: "32px" }}>
            <div className="container">
              <h4>{property.name}</h4>
              <table className="ui celled selectable striped structured table">
                <thead>
                  <tr>
                    <th className="three wide">Namn</th>
                    <th className="two wide">Aktivitet</th>
                    <th className="two wide">Personnummer</th>
                    <th className="four wide">Företag</th>
                    <th className="three wide"></th>
                  </tr>
                </thead>
                <tbody>
                  {Lodash.map(observations, (observation) =>
                    this.renderObservation(observation)
                  )}
                </tbody>
              </table>
            </div>
          </div>
        );
      }
    });
  }

  render() {
    return (
      <div>
        <h2>Pågående aktivitet</h2>
        <p>Här visas personer som just nu är aktiva på ett projekt</p>
        {this.state.loading ? (
          <div
            className="ui active centered inline inverted large loader"
            style={{ marginTop: "2em" }}
          />
        ) : (
          this.renderTables()
        )}
      </div>
    );
  }
}
