import * as React from "react";
import { Accordion, MobxComponent } from "../../commons";

interface Props {}

/** Documentations on how to install and resolve potential issues */
export class DocumentationView extends MobxComponent<Props> {
  render() {
    const isMobile = this.globals.device.isMobileSize;
    return (
      <div className="ui container">
        <h1 className="ui header">
          <i className="question circle icon"></i>
          <div className="content">Så här gör du:</div>
        </h1>

        <div className="ui compact grid" style={{ margin: "5rem 0" }}>
          <div
            className="three wide column"
            style={{ alignSelf: "center", textAlign: "right", padding: 0 }}
          >
            <i
              className={`${isMobile ? "big" : "huge"} id card outline icon`}
            />
          </div>
          <div
            className="thirteen wide column"
            style={{ alignSelf: "center", padding: 0, paddingLeft: "1rem" }}
          >
            {isMobile ? (
              <h3>Tryck på ditt kort i listan för att checka in eller ut</h3>
            ) : (
              <h2>Tryck på ditt kort i listan för att checka in eller ut</h2>
            )}
          </div>
        </div>

        <div className="ui grid" style={{ margin: "5rem 0" }}>
          <div
            className="three wide column"
            style={{ alignSelf: "center", textAlign: "right", padding: 0 }}
          >
            <i className={`${isMobile ? "big" : "huge"} id badge icon`} />
          </div>
          <div
            className="thirteen wide column"
            style={{ alignSelf: "center", padding: 0, paddingLeft: "1rem" }}
          >
            {isMobile ? (
              <h3>Du kan även skanna ditt kort för att checka in</h3>
            ) : (
              <h2>Du kan även skanna ditt kort för att checka in</h2>
            )}
          </div>
        </div>

        <div className="ui grid" style={{ margin: "5rem 0" }}>
          <div
            className="three wide column"
            style={{ alignSelf: "center", textAlign: "right", padding: 0 }}
          >
            <i
              className={`${isMobile ? "big" : "huge"} pencil alternate icon`}
            />
          </div>
          <div
            className="thirteen wide column"
            style={{ alignSelf: "center", padding: 0, paddingLeft: "1rem" }}
          >
            {isMobile ? (
              <h3>Ny här? Registrera dig direkt i iPaden!</h3>
            ) : (
              <h2>Ny här? Registrera dig direkt i iPaden!</h2>
            )}
          </div>
        </div>

        <div
          style={{
            fontSize: "1.2rem",
            marginTop: "8rem",
            marginBottom: "12rem",
          }}
        >
          <h2 className="ui header">
            <i className="id badge icon"></i>
            <div className="content">Kortläsaren</div>
          </h2>

          <Accordion
            fluid
            styled
            style={{ marginTop: "2rem" }}
            title={
              <h3 className="ui header" style={{ margin: "0.5rem 0" }}>
                <i className="flag icon" />
                Kom igång (för dig som är administratör)
              </h3>
            }
          >
            <p>
              Se först till att rätt iPad är ihop med rätt kortläsare. Numret på
              kortläsaren hittas på kortläsarens baksida och skall matcha
              nummret som står på iPaden (tex Kortpad 2).
            </p>
            <p>
              Koppla sedan in både läsare och iPaden i ett eluttag på önskad
              plats. När kortläsarna är påslagna kopplar dom automatiskt upp sig
              till sina respektive iPad's. Ibland kan man behöva slå av och på
              internet-delningen i iPaden så att läsaren kan koppla upp sig.
            </p>
            <p>
              Gå in i Appen och lås den till en specifik anläggning så synkas
              iPaden till den konfigurerade läsaren.
            </p>
          </Accordion>

          <Accordion
            fluid
            styled
            style={{ marginTop: "1rem" }}
            title={
              <h3 className="ui header" style={{ margin: "0.5rem 0" }}>
                <i className="user icon" />
                Användning
              </h3>
            }
          >
            <p>
              När kortläsaren och Appen i iPaden är igång och synkade kan kort
              läggas på kortläsaren för att check in/ut en anställd. När en
              in/utchekning genomförts uppdateras appen i iPaden automatiskt för
              att visa resultatet av in/ut checkningen.
            </p>
            <p>
              Om en arbetare skannar ett (för systemet) okänt kort, exempelvis
              ditt egna ID06 kort, kommer appen öppna ett formulär för
              självregistrering. Arbetaren får då möjligheten att enkelt kunna
              lägga till sig själv och sitt kort så att det kan användas för
              in/ut checkning i framtiden.
            </p>
            <p>
              Utöver informationen som visas i appen så visar även kortläsaren
              om in/ut checkningen lyckades. Kortläsaren lyser grönt om kortet
              gick att läsa och blinkar sedan grönt eller rött beroende på om
              in/ut checkningen lyckades.
            </p>
          </Accordion>

          <Accordion
            fluid
            styled
            style={{ marginTop: "1rem" }}
            title={
              <h3 className="ui header" style={{ margin: "0.5rem 0" }}>
                <i className="exclamation icon" />
                Felsökning
              </h3>
            }
          >
            <ul className="ui relaxed list">
              <div className="item" style={{ marginBottom: "1rem" }}>
                <b>Inga lampor lyser</b>
                <ul>
                  <li>
                    Detta är normalt och så länge läsaren är inkopplad i ett
                    eluttag ska allt vara okej.
                  </li>
                  <li>
                    <b>Åtgärd</b>
                    <ol className="ui ordered list">
                      <li>
                        Dubbelkolla att en en blå lampa lyser igenom på baksidan
                        av kortläsaren.
                      </li>
                      <li>
                        Se till att kortläsaren är inkopplad i ett eluttag.
                      </li>
                    </ol>
                  </li>
                </ul>
              </div>
              <div className="item" style={{ marginBottom: "1rem" }}>
                <b>Kortläsare blinkar rött och grönt</b>
                <ul>
                  <li>
                    Detta innebär att kortläsaren försöker koppla upp sig mot
                    iPaden's WiFi. Lyckas kortläsaren koppla upp sig blinkar den
                    gröna lampan, går något fel vid uppkopplingen blinkar den
                    röda lampan.
                  </li>
                  <li>
                    <b>Åtgärd</b>
                    <ol className="ui ordered list">
                      <li>
                        Vänta några sekunder och se om den kopplar upp sig och
                        slutar blinka.
                      </li>
                      <li>
                        Gå in iPad'ens inställningar och välj Internetdelning.
                        Slå av och på internetdelningen, vänta några sekunder
                      </li>
                      <li>
                        Starta om läsaren genom att dra ur och sätta i kabeln,
                        gör om från punkt 1 ovan.
                      </li>
                    </ol>
                  </li>
                </ul>
              </div>
            </ul>
          </Accordion>
        </div>
      </div>
    );
  }
}
