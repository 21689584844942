import * as React from "react";
import { DefaultComponentProps } from "../abstractComponent";
import { FormatRule } from "./rules";

interface FormProps extends DefaultComponentProps {
  /** A method called when the form is complete, valid and should be processed, contains two callbacks that can be used to confirm success or failure of the action */
  onSubmit?: (
    values: { [id: string]: any },
    resolve?: (result?: any) => any,
    reject?: (error: any) => any
  ) => void;

  /** Indicates if this form is currently performing a blocking action */ loading?: boolean;
  /** Indicates if this form is currently disabled from futher input */ disabled?: boolean;
}

/** Context for value in a form */
export const FormValues = React.createContext<Map<string, any>>(new Map());

/** Context for the status of the form */
export const FormStatus = React.createContext<{
  loading: boolean;
  disabled: boolean;
  valid: boolean;
  addValidationRules: (
    name: string,
    rules: FormatRule[],
    optional: boolean
  ) => void;
  removeValidationRules: (name: string) => void;
  addValue: (name: string, value: any) => void;
  removeValue: (name: string) => void;
}>({
  loading: false,
  disabled: false,
  valid: true,
  addValidationRules: () => {},
  removeValidationRules: () => {},
  addValue: () => {},
  removeValue: () => {},
});

/**
 * A Form component is used to wrap changable components (inputs)
 * and get a lot of information from the user all at once
 */
export const Form: React.FunctionComponent<FormProps> = (props) => {
  const [isLoading, setLoading] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(false);
  const [isValid, setValid] = React.useState(true);
  const _domElement = React.useRef<any>(null);
  const [values, setValues] = React.useState(new Map<string, any>());
  const _validationFields = new Map<string, any>();

  // private validationResolver?: (valid: boolean) => void;

  /** Adds a value to the form, usually called internally from input components that are members of this form */
  const addValue = (name: string, value: any): void => {
    values.set(name, value);
    setValues(values);
    initializeFormValidation();
  };

  /** Removes a value from the form, usually called internally from input components that are members of this form */
  const removeValue = (name: string): void => {
    values.delete(name);
    setValues(values);
    initializeFormValidation();
  };

  /** Used to initialize and update the form validation and semantic ui goodness */
  const initializeFormValidation = () => {
    if (!_domElement.current) {
      return;
    }

    $(_domElement.current)
      .form("destroy" as any)
      .form({
        inline: true,
        transition: "fade in",
        revalidate: true,
        keyboardShortcuts: true,
        fields: Object.fromEntries(_validationFields.entries()),
        onSuccess: (event, fields) => {
          event.preventDefault();
          setValid(true);
          if (
            $(_domElement.current).form("is valid") &&
            !(isLoading || isDisabled) &&
            !!props.onSubmit
          ) {
            setLoading(true);

            new Promise((resolve, reject) => {
              if (!!props.onSubmit) {
                props.onSubmit(
                  Object.fromEntries(values.entries()),
                  resolve,
                  reject
                );
              }
              setLoading(false);
            }).catch(() => {
              setLoading(false);
              $(_domElement.current).transition("shake");
            });
          }

          return onSubmitEvent(event);
        },
        onFailure: () => {
          setValid(false);
          onSubmitEvent();
        },
        onValid: () => setValid(true),
        onInvalid: () => setValid(false),
      });
  };

  /** Adds a validation rule to the form, usually called internally from input components that are members of this form */
  const addValidationRules = (
    inputName: string,
    rules: FormatRule[],
    optionalField: boolean
  ): void => {
    _validationFields[inputName] = {
      identifier: inputName,
      rules: rules.map((rule) => ({
        type: rule.type,
        prompt: rule.prompt || "Fältet innehåller fel",
      })),
      optional: optionalField,
    };

    // Reinitiaze form validation
    initializeFormValidation();
  };

  /** Remove a validation rule from this form, usually called internally from input components that are members of this form */
  const removeValidationRules = (inputName: string): void => {
    _validationFields.delete(inputName);

    // Reinitiate form validation
    initializeFormValidation();
  };

  const onSubmitEvent = (event?: any) => {
    if (event) {
      event.preventDefault();
    }
    return false;
  };

  // Initialize the form validation if the form ref changes
  React.useEffect(() => {
    initializeFormValidation();
  }, [_domElement]);

  // FIXME: (2023) Fix needed for this?
  //   componentDidUpdate(_: FormProps, prevState: State) {
  //     // Prevent possible jquery vs react race conditions...
  //     if (this.state.onlyValidate && !prevState.onlyValidate) {
  //       this.domElement.form("validate form");
  //     } else if (
  //       !this.state.onlyValidate &&
  //       prevState.onlyValidate &&
  //       this.validationResolver
  //     ) {
  //       this.validationResolver(this.valid);
  //       delete this.validationResolver;
  //     }
  //   }

  const className =
    "ui form " +
    (props.className ? props.className : "") +
    (isDisabled || isLoading || props.disabled || props.loading
      ? " disabled"
      : "");

  return (
    <FormValues.Provider value={values}>
      <FormStatus.Provider
        value={{
          loading: isLoading || props.loading || false,
          disabled: isDisabled || props.disabled || false,
          valid: isValid,
          addValidationRules,
          removeValidationRules,
          addValue,
          removeValue,
        }}
      >
        <form
          style={props.style}
          onSubmit={onSubmitEvent}
          ref={_domElement}
          className={className}
        >
          {props.children}
        </form>
      </FormStatus.Provider>
    </FormValues.Provider>
  );
};

/** Default properties for form member components */
export interface FormMemberProps extends DefaultComponentProps {
  /** A unique name is required for all form member components */ name: string;
  /** A list of format rules that applies to this component */ rules?: FormatRule[];
  /** Indicates if this component is currently performing an blocking action */ loading?: boolean;
  /** Indicates if this component is currently disabled from futher input */ disabled?: boolean;
  /** Indicates if the field is optional, this will override rules when empty */ optional?: boolean;
}
