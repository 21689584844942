import * as React from "react";
import Cookie from "js-cookie";
import { Types } from "mongoose";
import {
  PropertyDocument,
  PropertyResource,
} from "../../resources/property.resource";
import { UserDocument } from "../../resources/user.resource";
import { Provider, ProviderContext } from "../../contexts";
import {
  ObservationDocument,
  ObservationResource,
} from "../../resources/observation.resource";
import {
  OrganizationDocument,
  OrganizationResource,
} from "../../resources/organization.resource";
import { Props as QuestionDimmerProps } from "./dimmer/questionDimmerContent";
import { Props as PasscodeDimmerProps } from "./dimmer/passcodeDimmerContent";

/** Export the consume decorator */
export { Consume } from "../../contexts";

/** The public interface of the application context */
interface Interface {
  /** Update the state of the context */
  updateState: (
    newState: Partial<Omit<Interface, "guiDimmer">>
  ) => Promise<void>;
  /** Shows a password entry dimmer and returns the result of the user interaction in a promise  */
  showPasscodeDimmer: (props: PasscodeDimmerProps) => Promise<boolean>;
  /** Shows a question for the user in a dimmer modal and returns the result of the user interaction in a promise  */
  showQuestionsDimmer: (props: QuestionDimmerProps) => Promise<boolean>;
  /** The currently selected property if selected in the app interface */
  selectedProperty?: PropertyDocument;
  /** The employees listed or currently visiting the selected property */
  selectedPropertyEmployees: UserDocument[];
  /** The currently selected propertys owning organization, if a property is selected */
  selectedOrganization?: OrganizationDocument;
  /** If type is is not 'none', indicates that the application is currently waiting for a a user ineraction through a dimmer modal, the dimmer can be closed through the resolve method */
  guiDimmer: {
    resolve?: (newStatus: boolean) => void;
    type: "none" | "passcode" | "question";
    props: PasscodeDimmerProps | QuestionDimmerProps | object;
  };
  /** Indicates that the application is locked to the public view of any selected property */
  guiLocked: boolean;
  /** Indicates that are in the process of signing in a person manually at the selected property */
  guiSigningInManually: boolean;
  /** Indicated that application is in the view to download an xml file for expection */
  guiInspectionView: boolean;
  /** Docs view to help users with potential issues */
  guiDocumentationView: boolean;
  /** The current employee document when signed in */
  selectedEmployee?: UserDocument | null;
  /** Any ongoing observation for the logged in employee  */
  ongoingObservation?: ObservationDocument;
  /** List of properties the selected employee has access to */
  availableProperties: PropertyDocument[];
  /** The property the selected employee is currently active at, if any */
  activePropertyId: Types.ObjectId | undefined;
  /** Indicates the the UI is being rendered in the native wrapper app */
  isNative: boolean;
  /** Indicates that a notch and home bar is known to be present over the UI */
  hasNotch: boolean;
  /** Indicates that a new context state is being caluclated */
  loading: boolean;
}

/** Provider internal state */
interface State {}

/** Provider properties */
interface Props {
  /** Any user that has been signed-in/authentenicated through Kosmic */
  currentUser: UserDocument | undefined;
}

/** The Application Context Implementation */
export const AppContext = React.createContext(
  {}
) as any as React.Context<Interface>;

/** The Application Context Interface */
export type AppContext = ProviderContext<Interface>;

/** Location in local storage to save data */
const localStorageKey = "personalboken-app-context";

/**
 * The Application Context Provider keeps tracks of the overall state of the
 * app interface
 */
export class AppProvider extends Provider<Interface, Props, State> {
  /** Exposes the context to use */
  protected use(): React.Context<Interface> {
    return AppContext;
  }

  /** Sets the initial state of the provider */
  protected initialState(): Interface & State {
    return {
      selectedEmployee: undefined,
      selectedProperty: undefined,
      selectedOrganization: undefined,
      selectedPropertyEmployees: [],
      guiDimmer: { resolve: undefined, type: "none", props: {} },
      guiLocked: false,
      guiSigningInManually: false,
      guiInspectionView: false,
      guiDocumentationView: false,
      availableProperties: [],
      ongoingObservation: undefined,
      activePropertyId: undefined,
      isNative: Cookie.get("deviceType") == "wapp",
      hasNotch:
        Cookie.get("deviceIdentifier") == "iphone-x" &&
        Cookie.get("deviceType") == "wapp",
      loading: false,
      showPasscodeDimmer: this.showDimmer.bind(this, "passcode"),
      showQuestionsDimmer: this.showDimmer.bind(this, "question"),
      updateState: this.updateState.bind(this),
    };
  }

  async componentDidMount() {
    // Make sure the context interface is synced when the provider mounts (i.e. the app is loaded)
    // Loads any cached state stored in local storage
    await this.updateState(
      JSON.parse(window.localStorage.getItem(localStorageKey) || "{}")
    );
  }

  /**
   * Markes the dimmer of the selected type to be visible and sets
   * a resolvable promise to determine the outcome of the user interaction
   */
  public async showDimmer(
    type: Interface["guiDimmer"]["type"],
    props: Interface["guiDimmer"]["props"]
  ): Promise<boolean> {
    const status = new Promise<boolean>((onResolve) => {
      // Create a callback for closing the modal
      const resolve = async (newStatus: boolean) => {
        this.setState({
          guiDimmer: { type: "none", props: {}, resolve: undefined },
        });
        onResolve(newStatus);
      };

      this.setState({ guiDimmer: { type, props, resolve } });
    });

    // Return the unfufilled status promise
    return status;
  }

  /** Stores or retrives a few selected key of current state in local storage */
  protected async cacheState() {
    const cachedState: Partial<Interface & State> = {
      selectedProperty: this.state.selectedProperty,
      selectedOrganization: this.state.selectedOrganization,
      guiLocked: this.state.guiLocked,
      guiSigningInManually: this.state.guiSigningInManually,
      guiInspectionView: this.state.guiInspectionView,
      guiDocumentationView: this.state.guiDocumentationView,
    };

    window.localStorage.setItem(localStorageKey, JSON.stringify(cachedState));
  }

  /**
   * Updates the state of the provider.
   * It will automagicaly sync the current context status
   * from the server and legacy implementations
   */
  protected async updateState(newState: Partial<Interface>) {
    this.setState({ loading: true });

    // Set the passed in state
    this.setState(newState as Interface);

    // Confirm that we have a signed in user passed from props
    const currentUser =
      newState.selectedEmployee ||
      this.props.currentUser ||
      this.state.selectedEmployee;

    // Reset the state if we are signed out
    if (!currentUser || newState.selectedEmployee === null) {
      this.setState(this.initialState());
    }
    // Update the server side status of the signed in user
    else {
      const ongoingObservation =
        await new ObservationResource().whereIsTheEmployee(currentUser);
      let availableProperties =
        await new PropertyResource().findAccessableProperties();

      // If not listen on the current active property, add it to the list of available properties
      if (
        ongoingObservation &&
        !availableProperties.find(
          (property) => property._id == ongoingObservation.property
        )
      ) {
        const activeUnlistedProperty = await new PropertyResource().get(
          ongoingObservation.property
        );
        availableProperties = [activeUnlistedProperty, ...availableProperties];
      }

      // Filter out any archived properties
      availableProperties = availableProperties.filter(
        (property) => !property.archived
      );

      this.setState({
        selectedEmployee: currentUser,
        availableProperties,
        ongoingObservation,
        activePropertyId: ongoingObservation
          ? ongoingObservation.property
          : undefined,
      });
    }

    // Check if any property is selected
    const currentProperty = this.state.selectedProperty;

    // Update information for the any currently selected property
    if (currentProperty) {
      const selectedPropertyEmployees =
        await new PropertyResource().employeesAtLocation(currentProperty._id);
      const selectedOrganization = await new OrganizationResource().get(
        currentProperty.organization
      );

      this.setState({ selectedPropertyEmployees, selectedOrganization });
    }
    // Remove any hanging information
    else {
      this.setState({
        selectedPropertyEmployees: [],
        selectedOrganization: undefined,
        guiLocked: false,
        guiSigningInManually: false,
      });
    }

    // Stop loading
    this.setState({ loading: false });

    // Cache the current state
    this.cacheState();
  }
}
