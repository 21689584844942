import React from "react";

//TODO: remove after migrate

export const MigrateView = () => {
  const [success, setSuccess] = React.useState(false);
  const [encrypting, setEncrypting] = React.useState(false);

  const migrate = async (collection: string) => {
    setEncrypting(true);
    const body = { collection: collection };
    const response = await fetch(`/api/migrate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      setSuccess(true);
      setEncrypting(false);
    }
  };

  return (
    <div>
      {encrypting ? <div>Encrypting fields</div> : null}
      {success ? <div>Success!</div> : null}
      {/*  <button
        onClick={() => {
          setSuccess(false);
          migrate("user");
        }}
      >
        Users
      </button>
      <button
        onClick={() => {
          setSuccess(false);
          migrate("property");
        }}
      >
        Properties
      </button>
      <button
        onClick={() => {
          setSuccess(false);
          migrate("organization");
        }}
      >
        Organizations
      </button> */}
    </div>
  );
};
