import * as React from "react";
import {
  Alert,
  Field,
  Fields,
  Form,
  Input,
  KosmicComponent,
  Rules,
  SubmitButton,
} from "../commons";

import { UserDocument, UserResource } from "../../resources/user.resource";

import {
  OrganizationDocument,
  OrganizationResource,
} from "../../resources/organization.resource";
import { RoleResource } from "../../resources/role.resource";

interface Props {}
interface State {
  orgName: string;
}

/**
 * @class AddOrganizationView renders a form for
 * creation of @type OrganizationDocument
 */
export class AddOrganizationView extends KosmicComponent<Props, State> {
  // Initialize default component state
  public state: State = {
    orgName: "",
  };

  // Calculates the value to display based
  // on the value of orgName in state.
  public get orgName() {
    const { orgName } = this.state;
    if (orgName && orgName.length >= 3) {
      return orgName;
    }
    return "den nya organisationen";
  }

  // OnSubmit callback. Creates a organization document.
  createOrganization = (values, resolve, reject) => {
    const personNr = values["personNr"].replace("-", "").trim();
    const orgResource = new OrganizationResource();
    const userResource = new UserResource();
    const roleResource = new RoleResource();
    const orgDocument = orgResource.createDocument({
      name: values["orgName"],
      managers: [],
    });

    const orgRoleDocument = roleResource.createDocument({
      name: "Organization Manager",
      organization: orgDocument._id,
      privileges: ["*"],
    });

    const defaultOrgRole = roleResource.createDocument({
      name: "Organization Member",
      organization: orgDocument._id,
      privileges: [],
    });

    const userDocument = userResource.createDocument({
      organization: orgDocument._id,
      roles: [
        {
          type: "organization.manager",
          organization: orgDocument._id,
        },
      ],
      personNr: personNr,
      passcode: values["passcode"],
      firstname: values["firstname"],
      lastname: values["lastname"],
      username: values["email"],
      email: values["email"],
      password: values["password"],
      company: orgDocument.name,
      rolesV2: [orgRoleDocument._id, defaultOrgRole._id],
    });

    orgResource
      .updateDocument(orgDocument)
      .then(() => {
        return userResource.updateDocument(userDocument);
      })
      .then(() => {
        roleResource.updateDocument(defaultOrgRole);
        roleResource.updateDocument(orgRoleDocument);
      })
      .then(() => {
        return userResource.changePassword(
          userDocument._id,
          values["password"],
          ""
        );
      }) // FIXME: (old) will this work without a resetToken?
      .then(() => {
        Alert.show(
          orgDocument.name +
            " har skapats med administratören " +
            userDocument.name,
          "Ok",
          "success",
          "ok"
        );
        this.globals.session.navigateTo("/admin/system/statistics");
        resolve();
      })
      .catch((err) => {
        console.error(err);
        Alert.show(
          "Kontrollera att du har en internetanslutning och är inloggad, om problemet kvarstår, var snäll och kontakta support så hjälper vi dig!",
          "Det gick inte att spara den nya organisationen",
          "error",
          "försök igen"
        );
        reject();
      });
  };

  render() {
    return (
      <div>
        <h2 className="ui header" style={{ color: "white" }}>
          Lägg till en ny organisation
          <div className="sub header" style={{ color: "#fff" }}>
            Denna sida visas bara för dig,{" "}
            {(this.globals.session.currentUser as UserDocument).name}, eftersom
            du är en ägare till systemet!
          </div>
        </h2>
        <Form
          onSubmit={this.createOrganization}
          className="ui segment"
          style={{ color: "black" }}
        >
          <Field>
            <Input
              key="orgNameInput"
              name="orgName"
              className="large"
              onChange={(value) => this.setState({ orgName: value })}
              placeholder="Namn på organisationen"
              rules={[
                Rules.NotEmpty(),
                Rules.MinimumLength(
                  4,
                  "Namnet måste vara minst 3 tecken långt"
                ),
              ]}
            />
          </Field>
          <div className="ui stackable two columns dividing grid">
            <div className="column">
              <div className="ui blue fluid icon message">
                <span style={{ textAlign: "right" }}>
                  Awesome! Grymt att vi lägger upp en ny organisation i
                  systemet. Bra jobbat allihopa! 👏 👏 👏
                </span>
              </div>
            </div>
            <div className="column">
              <h5>Skapa en administratör</h5>
              <p>
                Du måste också skapa en första administratör, denna användare
                kommer kunna administera personal och anläggningar på{" "}
                {this.orgName}
              </p>
              <Field label="E-postadress för att logga in">
                <Input
                  name="email"
                  rules={[Rules.NotEmpty(), Rules.IsAnEmailAddress()]}
                />
              </Field>
              <Field label="Personnummer">
                <Input
                  name="personNr"
                  placeholder="ååmmddxxxx"
                  rules={[
                    Rules.CustomRule({
                      validator: () => true,
                      prompt: "Du måste ange ett giltigt personnummer",
                      mask: {
                        definition: [
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ],
                        placeholderCharacter: " ",
                      },
                      keyboard: "integer",
                    }),
                    Rules.ExactLength(10),
                    Rules.NotEmpty(),
                  ]}
                />
              </Field>
              <Fields width={16}>
                <Field label="Förnamn" width={16}>
                  <Input name="firstname" rules={[Rules.NotEmpty()]} />
                </Field>
                <Field label="Efternamn" width={16}>
                  <Input name="lastname" rules={[Rules.NotEmpty()]} />
                </Field>
              </Fields>
              <br />
              <Field label="Säkerhetskod">
                <Input
                  name="passcode"
                  placeholder="xxxxx"
                  rules={[
                    Rules.NotEmpty(),
                    Rules.IsAnInteger(),
                    Rules.ExactLength(
                      6,
                      "Säkerhetskoden måste vara 6 siffror lång"
                    ),
                  ]}
                />
              </Field>
              <Field label="Lösenord">
                <Input
                  name="password"
                  placeholder="lösenord"
                  rules={[
                    Rules.NotEmpty(),
                    Rules.MinimumLength(
                      3,
                      "Lösenordet måste vara minst 3 tecken långt"
                    ),
                  ]}
                />
              </Field>
              <br />
              <br />
            </div>
          </div>
          <br />
          <SubmitButton className="large green right floated">
            Skapa {this.orgName}
          </SubmitButton>
        </Form>
      </div>
    );
  }
}
