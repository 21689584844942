import * as React from "react";
import { KosmicComponent, SearchableList } from "../../commons";
import { EmployeeSearchPredicate } from "../../utils";
import { AppContext, Consume } from "../context";
import { EmployeeCard } from "../commons/employeeCard";
import { ReaderCommunicationContext } from "../../../contexts/readerComs";
import { ObservationDocument } from "../../../resources/observation.resource";

/** Allows for public management of employees at the selected property */
export class PropertyPublicView extends KosmicComponent<{}> {
  @Consume(AppContext)
  protected app!: AppContext;

  @Consume(ReaderCommunicationContext)
  private readerComs!: ReaderCommunicationContext;

  private onObservation = (observation: ObservationDocument) => {
    const crewList = this.app.selectedPropertyEmployees;
    const index = crewList.findIndex((crew) => {
      return crew._id.toString() == observation.employee.toString();
    });
    if (index === -1) {
      // if employee is missing - reload state and fetch the updated crew list
      this.app.updateState({});
    }
  };

  componentDidMount() {
    // Setup listener for observation updates
    this.readerComs.addObservationListener(this.onObservation);
  }

  componentWillUnmount() {
    // Remove listener for observation updates
    this.readerComs.removeObservationListener(this.onObservation);
  }

  render() {
    return <PropertyPublicComponent app={this.app} />;
  }
}

/**
 * PureComponent enforces a non-update policy on this component
 * which fixes an issue where EmployeeCard would unmount when
 * the app context would change.
 */
class PropertyPublicComponent extends React.PureComponent<{ app: AppContext }> {
  private renderListOfEmployees() {
    const { selectedPropertyEmployees } = this.props.app;

    if (!selectedPropertyEmployees.length) {
      return <span>Det finns ingen personal på arbetsplatsen</span>;
    }

    return (
      <SearchableList
        className="very padded doubling three column stackable ui grid"
        showDefaultSearchBar={false}
        predicate={EmployeeSearchPredicate}
        items={selectedPropertyEmployees}
        template={(props) => (
          <div className="column">
            <EmployeeCard employee={props.item} />
          </div>
        )}
      />
    );
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return <div className="ui content">{this.renderListOfEmployees()}</div>;
  }
}
