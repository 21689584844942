import * as React from "react";
import Moment from "moment-timezone";
import { Fields, Field, Input, Rules, Button } from "../commons";
import { CardDocument, CardResource } from "../../resources/card.resource";
import { UserDocument } from "../../resources/user.resource";
import { IDevice, Consume, DeviceProvider } from "../../contexts";

interface Props {
  employee: UserDocument;
  cards: CardDocument[];
  onChange: (cards: CardDocument[]) => void;
}

interface State {
  cards: CardDocument[];
}

export class CardFields extends React.Component<Props, State> {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  constructor(props: Props) {
    super(props);
    this.state = { cards: [...props.cards] };
  }

  removeCard = (index: number) => {
    const cards = [...this.state.cards];
    cards.splice(index, 1);

    this.setState({ cards });
    this.props.onChange(cards);
  };

  addCard = () => {
    const newCardDocument = new CardResource().createDocument({
      rfid: "",
      user: this.props.employee,
      userId: this.props.employee.id,
    });
    const cards = [...this.state.cards, newCardDocument];
    this.setState({ cards });
    this.props.onChange(cards);
  };

  onRfidChange = (rfid: string, index: number) => {
    const card = { ...this.state.cards[index], rfid } as CardDocument;
    this.updateCard(card, index);
  };

  onId06Change = (id06: string, index: number) => {
    const card = { ...this.state.cards[index], id06 } as CardDocument;
    this.updateCard(card, index);
  };

  updateCard = (card: CardDocument, index: number) => {
    const cards = [...this.state.cards];
    cards.splice(index, 1, card);

    this.setState({ cards });
    this.props.onChange(cards);
  };

  readonly id06CardRule = Rules.CustomRule({
    validator: (x) => /(^[A-Z]{2}-[0-9]{4}-[0-9]{6}$)|^$/.test(x),
    prompt: "Du måste ange enligt följande format: AA-0000-00000",
  });

  render() {
    return (
      <div style={{ marginBottom: "2rem" }}>
        <div className="ui grid">
          <div className="eleven wide column">
            <h5 style={{ color: "black", fontSize: "0.9rem", marginBottom: 0 }}>
              Personalkort
            </h5>
          </div>
          <div className="five wide column" style={{ paddingLeft: 0 }}>
            {this.device.size !== "mobile" && (
              <h5
                style={{ color: "black", fontSize: "0.9rem", marginBottom: 0 }}
              >
                Självregisterat
              </h5>
            )}
          </div>
        </div>
        {this.state.cards.map((card, index) => (
          <Fields
            key={card.id}
            style={{ marginBottom: "1rem", color: "black" }}
          >
            <Field width="six wide" style={{ marginTop: ".5rem" }}>
              <Input
                rules={[Rules.NotEmpty()]}
                fluid
                name={"Personalkort " + card.id}
                placeholder="Ange kortkod (1234567890)"
                defaultValue={card.rfid}
                onChange={(rfid) => this.onRfidChange(rfid, index)}
              />
            </Field>
            <Field width="seven wide" style={{ marginTop: ".5rem" }}>
              <Input
                rules={[this.id06CardRule]}
                fluid
                name={"id06" + card.id}
                placeholder="ID06 kod (fylls i vid självregistreringen)"
                defaultValue={card.id06}
                onChange={(id06) => this.onId06Change(id06, index)}
              />
            </Field>
            <Field
              width="four wide"
              style={{
                marginTop: ".5rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <span>
                {this.device.size === "mobile" && "Självregistrerat: "}
                <i
                  className={`ui ${
                    card.selfRegistered ? "check" : "close"
                  } icon`}
                />
                &nbsp;
                {Moment(card.createdAt).format("lll")}
              </span>
            </Field>
            <Field width="two wide" style={{ marginTop: ".5rem" }}>
              <Button
                className="ui right floated red basic icon button"
                onClick={() => this.removeCard(index)}
              >
                <i className="trash icon" />
              </Button>
            </Field>
          </Fields>
        ))}
        <Button className="ui primary basic icon button" onClick={this.addCard}>
          <i className="plus icon" /> Lägg till ett nytt personalkort
        </Button>
      </div>
    );
  }
}
