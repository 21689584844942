import * as React from "react";
import { KosmicComponent } from "../../commons";
import { AppContext, Consume } from "../context";
import { PasscodeDimmerContent } from "./passcodeDimmerContent";
import { QuestionDimmerContent } from "./questionDimmerContent";
import { Props as QuestionDimmerProps } from "./questionDimmerContent";
import { Props as PasscodeDimmerProps } from "./passcodeDimmerContent";

/**
 * The dimmer component renders what dimmer-content needs to be wrapped in to be displayed correctly
 * and handles the communication with the app provider to determine the visibily status
 */
export class Dimmer extends KosmicComponent<{}> {
  @Consume(AppContext)
  app!: AppContext;

  public render() {
    const { guiDimmer } = this.app;
    const { resolve, type, props } = guiDimmer;

    // Only render the dimmer if the type is set and resolve method exists
    if (type == "none" || !resolve) {
      return null;
    }

    return (
      <div
        onScroll={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onTouchMove={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        className="ui center aligned middle aligned content"
        style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          height: "100vh",
          background: "rgba(0,0,0,0.85)",
          color: "white",
          zIndex: 99999,
          pointerEvents: "all",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {
          /* Render the correct dimmer content */
          type == "passcode" ? (
            <PasscodeDimmerContent
              {...(props as PasscodeDimmerProps)}
              onHide={resolve}
            />
          ) : (
            <QuestionDimmerContent
              {...(props as QuestionDimmerProps)}
              onHide={resolve}
            />
          )
        }
      </div>
    );
  }
}
