import * as React from "react";
import { io, Socket } from "socket.io-client";
import { AppContext } from "../components/app/context";
import { ObservationDocument } from "../resources/observation.resource";
import { ProviderContext } from "./_interfaces";
import { Consume } from "./consume";
import { Provider } from "./provider";

type ObservationListener = (observation: ObservationDocument) => void;

const DefaultState: ReaderCommunicationContext & any = {
  addObservationListener: (() => null) as any,
  removeObservationListener: (() => null) as any,
  setState: (() => null) as any,
  socket: {} as Socket,
};

export type ReaderCommunicationContext = ProviderContext<{
  manualSignInRFID?: string;
  addObservationListener: (listener: ObservationListener) => void;
  removeObservationListener: (listener: ObservationListener) => void;
  setState: (state: Partial<ReaderCommunicationContext>) => void;
  socket: Socket;
}>;
export const ReaderCommunicationContext =
  React.createContext<ReaderCommunicationContext>(DefaultState);

interface State {
  readerId: string;
}
interface Props {}

export class ReaderCommunicationProvider extends Provider<
  ReaderCommunicationContext,
  Props,
  State
> {
  @Consume(AppContext)
  private app!: AppContext;

  private eventTable = {
    connect: this.registerPropertyToConfiguredReader.bind(this),
    observation: this.onObservation.bind(this),
    registered: this.onRegistered.bind(this),
    "deleted-card-notification": this.onDeletedCard.bind(this),
    "manual-login-requested": this.onManualLoginRequested.bind(this),
  };
  private observationListeners: ObservationListener[] = [];
  private _socket: Socket;
  protected use() {
    return ReaderCommunicationContext;
  }

  protected initialState(): ReaderCommunicationContext & State {
    return {
      ...DefaultState,
      readerId: "",
      addObservationListener: this.addObservationListener.bind(this),
      removeObservationListener: this.removeObservationListener.bind(this),
      setState: this.setState.bind(this),
      socket: this._socket,
    };
  }

  componentDidMount() {
    this._socket = io({
      transports: ["websocket", "polling"],
    });

    for (const event in this.eventTable) {
      this._socket.on(event, this.eventTable[event]);
    }

    // Set the socket in the context
    this.setState({ socket: this._socket });
  }

  componentWillUnmount() {
    if (this.app.selectedProperty) {
      let propertyId: string = this.app.selectedProperty.id;
      this._socket.emit("unregister", { propertyId });
    }

    // Add disconnect event listener to socket
    this._socket.close();
  }

  private addObservationListener(l: ObservationListener) {
    this.observationListeners.push(l);
  }
  private removeObservationListener(l: ObservationListener) {
    this.observationListeners = this.observationListeners.filter(
      (ll) => ll != l
    );
  }

  /* Emit new events to server */
  /* ------------------------- */

  private async registerPropertyToConfiguredReader() {
    // TODO: (2023) Added this if, should this really be mounted without selectedProperty?
    if (this.app.selectedProperty) {
      let propertyId: string = this.app.selectedProperty.id;
      this._socket.emit("registration", { propertyId });
    }
  }

  /* Handle emitted events from server */
  /* --------------------------------- */

  private onObservation(observation: ObservationDocument) {
    for (const listener of this.observationListeners) {
      listener(observation);
    }
  }

  private onRegistered() {
    // TODO: (old) show confirmation toast
  }

  private async onManualLoginRequested({ rfid }: { rfid: string }) {
    const shouldRegister = await this.app.showQuestionsDimmer({
      title: "Självregistrering krävs",
      body: "Vill du registrera dig så att du kan logga in med kortet nu och i framtiden?",
      icon: "card",
      continueText: "Ja",
      continueColor: "green",
    });

    if (shouldRegister) {
      this.setState({ manualSignInRFID: rfid }, () =>
        this.app.updateState({ guiSigningInManually: true })
      );
    }
  }

  private async onDeletedCard() {
    const shouldCheckinManually = await this.app.showQuestionsDimmer({
      title: "Ogiltigt kort",
      body: "Det här kortet har inaktiverats, var god kontakta administratör.",
      icon: "card",
      continueText: "Checka in manuellt",
      continueColor: "green",
    });

    if (shouldCheckinManually) {
      this.app.updateState({ guiSigningInManually: true });
    }
  }
}
