import { Schema, Types } from "mongoose";
import { MongooseDocument, MongooseResource } from "./_abstractResource";
import { UserDocument } from "./user.resource";

export interface RoleDocument extends MongooseDocument {
  name: string;
  organization: Types.ObjectId;
  property?: Types.ObjectId;
  privileges: string[];
}

export class RoleResource extends MongooseResource<RoleDocument> {
  constructor() {
    super();

    this.setName("role");

    this.setSchema({
      name: { type: String, required: true },
      organization: { type: Schema.Types.ObjectId, required: true },
      property: { type: Schema.Types.ObjectId, required: false },
      privileges: [{ type: String, required: true }],
    });
  }

  findOne(query: Object) {
    return this.sendRequest<RoleDocument>("/findOne", "post", { query });
  }

  updateUserRole(data: {
    user: Types.ObjectId | string;
    roleName: string;
    organization: Types.ObjectId;
    add: boolean;
    property?: Types.ObjectId;
  }) {
    return this.sendRequest<UserDocument>("/updateUserRole", "post", data);
  }
}

// export const workerRole = {
//   name: "Crew",
//   organization: new Types.ObjectId("a"),
//   property: new Types.ObjectId("b"),
//   privileges: [] as string[],
// } as RoleDocument;

// export const propertyRole = {
//   name: "Property Manager",
//   organization: new Types.ObjectId("a"),
//   property: new Types.ObjectId("b"),
//   privileges: ["observation.*", "property.*", "user.read", "card.*"],
// } as RoleDocument;

// export const organizationRole = {
//   name: "Organization Manager",
//   organization: new Types.ObjectId("a"),
//   privileges: ["*"],
// } as RoleDocument;
