import * as React from "react";
import {
  Form,
  Field,
  Alert,
  KosmicComponent,
  Input,
  Rules,
  SubmitButton,
} from "../commons";
import { Consume, ParamsContext } from "../../contexts";
import {
  PropertyResource,
  PropertyDocument,
} from "../../resources/property.resource";

interface State {
  /** Indicates that the form has been edited and can be saved */
  edited?: boolean;
}

/** View for displaying the details of, and editing, a property */
export class PropertyDetailsView extends KosmicComponent<{}, State> {
  state: State = {};

  @Consume(ParamsContext)
  private params!: ParamsContext;

  private async onSubmit(values, resolve, reject) {
    const resource = new PropertyResource();
    const document = resource.createDocument({
      _id: this.params.property!._id,
      name: values.name,
      district: values.district,
      classification: values.classification,
      passcode: values.passcode,
      taxAgencyIdentification: values.taxAgencyIdentification,
      organization: this.globals.session.currentOrganization,
    } as PropertyDocument);
    try {
      await resource.updateDocument(document);
      this.setState({ edited: false });
      resolve();
    } catch (err) {
      Alert.show(
        "Kontrollera att du har en internetanslutning, om problemet kvarstår, kontakta support.",
        "Det gick inte att spara till databasen",
        "error",
        "försök igen"
      );
      reject();
    }
  }

  /** Sets the state as 'has been edited' when a form input is changed */
  private async onChange() {
    if (!this.state.edited) {
      await this.setState({ edited: true });
    }
  }

  render() {
    const { property } = this.params;

    if (!property) {
      return null;
    }

    return (
      <div>
        <h2>Projekt: {property.name}</h2>
        <Form onSubmit={this.onSubmit.bind(this)} className="ui segment">
          <Field label="Projektidentifikation">
            <Input
              name="name"
              className="required"
              defaultValue={property.name}
              rules={[
                Rules.MinimumLength(
                  2,
                  "Projektidentifikationen måste vara minst 2 tecken lång"
                ),
              ]}
              onChange={this.onChange.bind(this)}
            />
          </Field>
          <br />
          <Field label="Område">
            <Input
              name="district"
              className="required"
              defaultValue={property.district}
              rules={[Rules.NotEmpty("Du måste ange ett område för projektet")]}
              onChange={this.onChange.bind(this)}
            />
          </Field>
          <Field label="Klassificering">
            <Input
              name="classification"
              className="required"
              defaultValue={property.classification}
              rules={[
                Rules.NotEmpty("Du måste ange en klassificering för projektet"),
              ]}
              onChange={this.onChange.bind(this)}
            />
          </Field>
          <br />
          <Field label="Projektets säkerhetskod">
            <Input
              name="passcode"
              className="required"
              defaultValue={property.passcode}
              rules={[
                Rules.IsOnlyDigits("Koden får bara innehålla siffror"),
                Rules.ExactLength(6, "Koden måste vara 6 siffror lång"),
              ]}
              onChange={this.onChange.bind(this)}
            />
          </Field>
          <br />
          <Field label="Identifikationsnummer för Skatteverket">
            <Input
              name="taxAgencyIdentification"
              defaultValue={property.taxAgencyIdentification}
              rules={[
                Rules.CustomRule({
                  validator: (value) => /PL\d{4}[A-Z]{2}\d{5}/.test(value),
                  prompt:
                    "Identifikationsnumret måste följa skatteverkets specifikation",
                }),
              ]}
              onChange={this.onChange.bind(this)}
            />
          </Field>
          {this.state.edited && (
            <div>
              <a
                onClick={() =>
                  this.globals.session.navigateTo("/admin/properties")
                }
                className="ui red button"
              >
                <i className="left arrow icon" />
                Avbryt
              </a>
              <SubmitButton className="floated right green ">
                <i className="save icon" />
                Spara ändringar
              </SubmitButton>
            </div>
          )}
          {!this.state.edited && (
            <div
              onClick={() =>
                this.globals.session.navigateTo("/admin/properties")
              }
              className="ui button"
            >
              <i className="left arrow icon" />
              Gå tillbaka
            </div>
          )}
        </Form>
        {this.props.children}
      </div>
    );
  }
}
