import Lodash from "lodash";
import Moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import { ParamsContext } from "../../contexts";
import {
  ObservationDocument,
  ObservationResource,
} from "../../resources/observation.resource";
import { GlobalSessionManager } from "../../stores";
import { formatDuration } from "../utils";

/** Lists all observations on the current organization sorted by month */
export const ListOfObservationsView: React.FC = () => {
  /** The list of observations to display */
  const [observations, setObservations] = useState<ObservationDocument[]>([]);
  /** Indicates that observations are being loaded */
  const [loading, setLoading] = useState<boolean>(true);

  /** URL params */
  const params = useContext<ParamsContext>(ParamsContext as any);

  /** Fetches a selection of observations matching the current year and month */
  useEffect(() => {
    setLoading(true);
    const { year, month } = params;
    if (!year || !month) {
      return;
    }
    const beginning = new Date(year, month).toISOString();
    const end = new Date(year, month - 1).toISOString();

    const observations = new ObservationResource()
      .listofobservations({
        beginning: { $lt: beginning },
        end: { $gt: end },
      })
      .then((observations) => {
        setObservations(observations);
        setLoading(false);
      });
  }, [params]);

  /** Navigates to the previous or next month view */
  const navigate = async (to: "previous" | "next") => {
    const { year, month } = params;
    if (!year || !month) {
      return;
    }

    let target = { year, month };

    switch (to) {
      case "next":
        target.month += 1;
        if (target.month > 12) {
          target.month = 1;
          target.year++;
        }
        break;
      case "previous":
        target.month -= 1;
        if (target.month < 1) {
          target.month = 12;
          target.year--;
        }
        break;
    }

    GlobalSessionManager.navigateTo(
      `/admin/observations/${target.year}/${target.month}`
    );
  };

  const renderObservation = (observation: ObservationDocument) => {
    const duration = formatDuration(
      Moment.duration(
        Moment(observation.end).diff(Moment(observation.beginning))
      ).asMinutes()
    );

    return (
      <tr key={observation.id}>
        <td>{observation.property.name}</td>
        <td>{observation.employee ? observation.employee.company : ""}</td>
        <td>{observation.employee ? observation.employee.name : ""}</td>
        <td>{observation.employee ? observation.employee.personNr : ""}</td>
        <td>{Moment(observation.beginning).format("YYYY:MM:DD HH:mm")}</td>
        <td>{Moment(observation.end).format("YYYY:MM:DD HH:mm")}</td>
        <td>{duration}</td>
      </tr>
    );
  };

  // Don't render if no year/month is set
  const { year, month } = params;
  if (!year || !month) {
    return null;
  }

  // Create readable strings for the current datetime and selected month, year to view
  const currentDatetime: string = Moment().format("dddd DD MMMM YYYY HH:mm");
  const selectedMonth = Moment()
    .month(month - 1)
    .format("MMMM");
  const selectedYear = Moment().year(year).format("YYYY");

  return (
    <div>
      <h2>
        Aktivitet under {selectedMonth} {selectedYear}
      </h2>
      <p>Visar all aktivitet under månaden. Sidan hämtades {currentDatetime}</p>
      <div onClick={() => navigate("previous")} className="ui labeled button">
        <i className="arrow left icon" /> föregående månad
      </div>
      <div
        onClick={() => navigate("next")}
        style={{ float: "right" }}
        className="ui right labeled button"
      >
        nästa månad <i className="arrow right icon" />
      </div>
      <table className="ui celled structured selectable collapsable stackable table">
        <thead>
          <tr>
            <th rowSpan={2}>Projekt</th>
            <th rowSpan={2}>Namn på näringsidkare</th>
            <th rowSpan={2}>Namn</th>
            <th rowSpan={2} className="three wide">
              Personnummer
            </th>
            <th colSpan={3}>Arbetspass</th>
          </tr>
          <tr>
            <th>start</th>
            <th>slut</th>
            <th>tid</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr key={selectedYear + selectedMonth}>
              <td colSpan={7} style={{ textAlign: "center" }}>
                <div className="ui inline active loader" />
              </td>
            </tr>
          )}
          {!loading && !observations.length && (
            <tr key={selectedYear + selectedMonth}>
              <td colSpan={7} style={{ textAlign: "center" }}>
                {" "}
                Det finns ingen aktivitet under {selectedMonth}{" "}
              </td>
            </tr>
          )}
          {Lodash.map(observations, renderObservation)}
        </tbody>
      </table>
      <div onClick={() => navigate("previous")} className="ui labeled button">
        <i className="arrow left icon" /> föregående månad
      </div>
      <div
        onClick={() => navigate("next")}
        style={{ float: "right" }}
        className="ui right labeled button"
      >
        nästa månad <i className="arrow right icon" />
      </div>
    </div>
  );
};
