import * as React from "react";
import { KosmicComponent, Maybe, Transition } from "../commons";
import { NavLink } from "../navigation";

interface Props {}

export class AdminTemplate extends KosmicComponent<Props> {
  render() {
    return (
      <div style={{ padding: "1rem" }}>
        <Transition in animation="fade down">
          <h1>
            Adminöversikt
            <span
              className="mobile hidden"
              style={{ float: "right", color: "#1d292f" }}
            >
              Personalboken
            </span>
          </h1>
        </Transition>
        <Transition in animation="fade up">
          <div className="ui stackable pointing menu">
            <NavLink
              exact
              className="orange item"
              activeClassName="active"
              to="/admin/ongoing"
            >
              Aktiva&nbsp;&nbsp;
            </NavLink>
            <NavLink
              className="teal item"
              activeClassName="active"
              to="/admin/observations"
            >
              Månadsöversikt
            </NavLink>
            <NavLink
              className="brown item"
              activeClassName="active"
              to="/admin/properties"
            >
              Projekt
            </NavLink>
            <NavLink
              className="violet item"
              activeClassName="active"
              to="/admin/employees"
            >
              Personer
            </NavLink>
            <NavLink
              className="pink item"
              activeClassName="active"
              to="/admin/card-readers"
            >
              Kortläsare
            </NavLink>
            <div className="right menu">
              <Maybe if="system.owner">
                <NavLink
                  className="blue item"
                  activeClassName="active"
                  to="/admin/system"
                >
                  <i className="calculator icon" /> Systemet
                </NavLink>
              </Maybe>
              <NavLink
                className="item"
                activeClassName="active"
                to="/admin/account"
              >
                <i className="user icon" /> Kontoinställningar
              </NavLink>
              <a
                className="red item"
                onClick={() =>
                  this.globals.session.deauthenticate("/admin/login")
                }
              >
                <i className="user icon" /> logout
              </a>
            </div>
          </div>
        </Transition>
        <div style={{ height: 30 }} />
        <Transition in animation="fade">
          <div>{this.props.children}</div>
        </Transition>
        <div style={{ height: 60 }} />
      </div>
    );
  }
}
