import * as React from "react";
import { Route } from "../components/navigation";
import { CardDocument, CardResource } from "../resources/card.resource";
import {
  PropertyDocument,
  PropertyResource,
} from "../resources/property.resource";
import { UserDocument, UserResource } from "../resources/user.resource";
import { ProviderContext } from "./_interfaces";
import { Provider } from "./provider";

/** The public interface of the context */
interface Interface {
  /**a selected property document @param :propertyId */
  property?: PropertyDocument;
  /** a selected employee document @param :employeeId */
  employee?: UserDocument;
  /** a selected employee card document @param :employeeId */
  cards: CardDocument[];
  /** a selected year @param :year */
  year?: number;
  /** a selected month @param :month */
  month?: number;
}

/** Provider properties */
interface Props {
  /** The path to mount the route at */
  path: string;
  /** Match the route exactly or not */
  exact?: boolean;
  children?: any;
}

/** Provider internal state */
interface State {
  /** Indicates that a new state is being caluclated */
  loading: boolean;
}

/** The Params Route Context Implementation */
export const ParamsContext = React.createContext({});

/** The Params Route Context Interface */
export type ParamsContext = ProviderContext<Interface>;

/**
 * The Params Route is a combined provider and react-router route that looks for url params
 * in the current path and fetches related data and  makes itavailable for consumers.
 * NOTE: (old) it could be made a generic component/factory to be usable in other applications besides
 * Personalboken
 */
export class ParamsRoute extends Provider<Interface, Props, State> {
  /** Exposes the context to use */
  protected use(): React.Context<Interface> {
    return ParamsContext as any as React.Context<Interface>;
  }

  /** Sets the initial state of the provider */
  protected initialState() {
    return {
      loading: false,
      updateState: this.setState.bind(this),
      cards: [],
    };
  }

  /** Calculates the current state when the component renders */
  protected calculateState(routeProps: any): Interface & State {
    const { match } = routeProps;
    let loading = false;
    let year: Interface["year"] = undefined;
    let month: Interface["month"] = undefined;

    // The property needs to be updated
    if (
      match.params.property &&
      match.params.property.length &&
      (!this.state.property || this.state.property.id != match.params.property)
    ) {
      loading = true;
      this.updateProperty(match.params.property);
    }

    // The employee needs to be updated
    if (
      match.params.employee &&
      match.params.employee.length &&
      (!this.state.employee || this.state.employee.id != match.params.employee)
    ) {
      loading = true;
      this.updateEmployee(match.params.employee);
    }

    // Set the current year
    if (match.params.year) {
      // NOTE: (2023) Removed if parseInt != NaN
      year = parseInt(match.params.year);
    }

    // Set the current month
    if (match.params.month) {
      // NOTE: (2023) Removed if parseInt != NaN
      month = parseInt(match.params.month);
    }

    return { ...this.state, year, month, loading };
  }

  /**
   * Search for the employee with the given id and set it as selected
   */
  private async updateEmployee(id: string) {
    const foundEmployees = await new UserResource().find({ _id: id });
    if (!foundEmployees || !foundEmployees.length) {
      throw new Error(`No employee with id ${id} was found`);
    }
    const employee = foundEmployees[0];
    let cards: CardDocument[] = [];
    if (employee) {
      // Try to find a card owned by the employee.
      cards = await new CardResource().populateCardUsers({ user: id });
    }
    this.setState({ employee, cards });
  }

  /**
   * Search for the property with the given id and set it as selected
   * NOTE: (old) find is used here for populating the organization field.
   * if none are found, undefined will be set in state
   */
  private async updateProperty(id: string) {
    const foundProperties =
      await new PropertyResource().populatePropertyOrganisation({ _id: id });
    if (!foundProperties.length) {
      return;
    }
    this.setState({ property: foundProperties[0] });
  }

  public render() {
    const Context = this.use();
    return (
      <Route
        path={this.props.path}
        exact={this.props.exact}
        render={(routeProps) => {
          const state = this.calculateState(routeProps);
          return (
            <Context.Provider value={state}>
              {state.loading ? (
                <div className="ui active centered inline inverted large loader" />
              ) : (
                this.props.children
              )}
            </Context.Provider>
          );
        }}
      />
    );
  }
}
