import * as React from "react";

import { KosmicComponent } from "./abstractComponent";

export class ConfirmationButton extends KosmicComponent<
  {
    label: React.ReactNode;
    timeout?: number;
    activeButton: React.ReactNode;
    stopPropagation?: boolean;
    disabled?: boolean;
    loading?: boolean;
  },
  { triggered: boolean }
> {
  private _animate = false;
  state = {
    triggered: false,
  };

  trigger = (e) => {
    this._animate = true;
    this.setState({ triggered: !this.state.triggered });
    if (
      this.state.triggered &&
      this.props.timeout != undefined &&
      this.props.timeout > 0
    ) {
      setTimeout(this.trigger, this.props.timeout || 3000);
    }
    if (this.props.stopPropagation && e) {
      e.stopPropagation();
    }
  };

  componentDidUpdate() {
    if (this._animate && !this.props.disabled) {
      this.domElement.transition("jiggle");
    }
    this._animate = false;
  }

  render() {
    if (this.state.triggered) {
      return this.props.activeButton as any;
    } else
      return (
        <a
          className={
            "ui " +
            (this.props.disabled ? "disabled " : "") +
            (this.props.loading ? "loading " : "") +
            "button " +
            (this.props.className || "")
          }
          onClick={this.trigger}
        >
          {this.props.label}
        </a>
      );
  }
}
