import * as React from "react";
import * as ReactDom from "react-dom";

// Defines the various alert message types.
export type AlertType = "error" | "info" | "success" | "warning";

// Contains information about an alert message.
export interface AlertInfo {
  buttonText: string;
  text: React.ReactNode;
  title: React.ReactNode;
  type: AlertType;
}

// Alert utility class for displaying alerts. This class will revert
// to the default alert() Javascript method if the custom alerts
// cannot be displayed properly.
export class Alert extends React.Component<any, AlertInfo> {
  // Indicates whether an alert is currently being displayed.
  busy: boolean = false;

  // A JQuery reference to the component itself.
  component: JQuery;

  // The Alert singleton instance.
  static instance: Alert;

  // The queue of alerts to display.
  queue: AlertInfo[] = [];

  constructor(props) {
    super(props);
    this.state = {} as AlertInfo;
  }

  close() {
    Alert.instance.component.sidebar("hide");
  }

  componentDidMount() {
    // if (Alert.instance) {
    //     console.warn('There seems to be more than one Alert component');
    // }

    Alert.instance = this;

    this.configureAlert();
  }

  // Not sure if this is needed.
  componentDidUpdate() {
    this.configureAlert();
  }

  configureAlert() {
    this.component = $(ReactDom.findDOMNode(this)!) as any;

    this.component.sidebar({
      closable: false,
      context: this.component.parent().first(),
      dimPage: false,
      transition: "overlay",

      onHidden: () => {
        this.busy = false;

        Alert.instance.showNext();
      },
    });
  }

  render() {
    return (
      <div
        className="top sidebar ui push"
        style={{ background: "transparent", border: "none", boxShadow: "none" }}
      >
        <div
          className={"ui message center aligned page grid " + this.state.type}
          style={{
            boxShadow: "2px 2px 18px -4px rgba(0,0,0,0.72)",
            padding: "32px 32px 18px 32px",
            margin: "42px auto 24px",
            width: "40%",
          }}
        >
          <i className="close icon" onClick={this.close}></i>
          <div className="ui row">
            <h3 className="ui header">{this.state.title}</h3>
          </div>
          <div className="ui row">{this.state.text}</div>
          <div className="ui row">
            <button className="ui large basic button" onClick={this.close}>
              {this.state.buttonText}
            </button>
          </div>
        </div>
      </div>
    );
  }

  static show(
    text: React.ReactNode,
    title: React.ReactNode = "",
    type: AlertType = "info",
    buttonText: string = "OK"
  ) {
    let inst = Alert.instance;

    if (!inst) {
      console.warn("No alert instance exists - using default alert");
      alert(text);
      return;
    }

    inst.queue.push({
      buttonText: buttonText,
      text: text,
      title: title,
      type: type,
    });

    inst.showNext();
  }

  showNext() {
    if (this.busy || this.queue.length == 0) {
      return;
    }

    this.busy = true;

    this.setState(this.queue.shift() as any);

    this.component.sidebar("toggle");
  }
}
