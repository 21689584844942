import * as React from "react";
import { Alert, Field, Form, Input, KosmicComponent, Rules } from "../commons";

import { CardFields } from "../../components/admin/cardFields";
import { Consume, ParamsContext } from "../../contexts";
import { CardDocument, CardResource } from "../../resources/card.resource";
import { UserDocument, UserResource } from "../../resources/user.resource";

interface Props {}
interface State {
  employee: UserDocument;
  cards: CardDocument[];
  edited: boolean;
}

export class EmployeeDetailsView extends KosmicComponent<Props, State> {
  @Consume(ParamsContext)
  private params!: ParamsContext;

  constructor(props: Props) {
    super(props);
    this.state = { edited: false } as any;
  }

  componentDidMount() {
    let { employee, cards } = this.params;
    console.log(employee);
    if (employee == undefined) {
      throw new Error("No employee was found");
    }
    this.setState({ employee, cards });
  }

  editEmployee = async (_, resolve, reject) => {
    try {
      const cardResource = new CardResource();
      // Update all cards (will create new ones if needed)
      await Promise.all(
        this.state.cards.map((card) => cardResource.updateDocument(card))
      );

      // Remove removed cards from db
      const removedCards = this.params.cards.filter(
        (oldCard) => !this.state.cards.find((card) => card.id == oldCard.id)
      );
      await Promise.all(
        removedCards.map((removedCard) => cardResource.delete(removedCard.id))
      );

      console.log("test");
      // Save employee
      const resource = new UserResource();
      const document: any = resource.createDocument(this.state.employee);
      document.personNr = $('[name="Personnummer"]').val();
      document.passcode = $('[name="Personlig kod"]').val();
      document.firstname = $('[name="Förnamn"]').val();
      document.firstname = $('[name="Förnamn"]').val();
      document.lastname = $('[name="Efternamn"]').val();
      document.phoneNr = $('[name="Telefonnummer"]').val();
      document.email = $('[name="E-post"]').val();
      document.company = $('[name="company"]').val();
      document.orgNr = $('[name="orgNr"]').val();

      await resource.updateDocument(document);

      const employee = await resource.get(this.params.employee!.id);

      this.setState({ employee, edited: false });
      this.params.cards = this.state.cards;
      resolve();
    } catch (err) {
      console.error(err);
      Alert.show(
        "Kontrollera att du har en internetanslutning, om problemet kvarstår, kontakta support.",
        "Det gick inte att spara till databasen",
        "error",
        "försök igen"
      );
      return reject();
    }
  };

  navigateBack = () => this.globals.session.navigateTo("/admin/employees");

  saveUpdatedCards = (cards: CardDocument[]) =>
    this.setState({ cards, edited: true });

  renderBottomButtons() {
    if (this.state.edited) {
      return (
        <div>
          <a onClick={this.navigateBack} className="ui red button">
            <i className="left arrow icon" />
            Avbryt
          </a>
          <a className="ui green submit right floated button">
            <i className="save icon" />
            Spara ändringar
          </a>
        </div>
      );
    }
    return (
      <div onClick={this.navigateBack} className="ui button">
        <i className="left arrow icon" />
        Gå tillbaka
      </div>
    );
  }

  render() {
    const { employee, cards } = this.state;
    if (!employee) {
      return (
        <div>
          <h2>
            <i>...</i>
          </h2>
          <div className="ui loading segment" />
        </div>
      );
    }

    return (
      <div>
        <Form onSubmit={this.editEmployee} className="ui attached segment">
          <Field label="Personnummer">
            <Input
              name="Personnummer"
              icon="user"
              rules={[Rules.ExactLength(10), Rules.IsANumber()]}
              defaultValue={employee.personNr}
              onChange={() => this.setState({ edited: true })}
            />
          </Field>
          <Field label="Förnamn">
            <Input
              name="Förnamn"
              className="required"
              rules={[Rules.NotEmpty()]}
              defaultValue={employee.firstname}
              onChange={() => this.setState({ edited: true })}
            />
          </Field>
          <Field label="Efternamn">
            <Input
              name="Efternamn"
              className="required"
              rules={[Rules.NotEmpty()]}
              defaultValue={employee.lastname}
              onChange={() => this.setState({ edited: true })}
            />
          </Field>
          <br />
          <Field label="Personlig kod">
            <Input
              name="Personlig kod"
              className="required"
              rules={[Rules.IsANumber(), Rules.ExactLength(6)]}
              defaultValue={employee.passcode}
              onChange={() => this.setState({ edited: true })}
            />
          </Field>
          <Field label="Byggverksamhetsbedrivare">
            <Input
              name="company"
              placeholder="företagsnamn"
              defaultValue={employee.company}
              onChange={() => this.setState({ edited: true })}
            />
          </Field>
          <Field>
            <Input
              name="orgNr"
              className="required"
              rules={[Rules.NotEmpty()]}
              placeholder="organisationsnummer"
              defaultValue={this.state.employee.orgNr}
              onChange={() => this.setState({ edited: true })}
            />
          </Field>
          <br />
          <Field label="E-post">
            <Input
              name="E-post"
              defaultValue={employee.email}
              onChange={() => this.setState({ edited: true })}
            />
          </Field>
          <Field label="Telefonnummer">
            <Input
              name="Telefonnummer"
              defaultValue={employee.phoneNr}
              onChange={() => this.setState({ edited: true })}
            />
          </Field>
          <br />
          <CardFields
            employee={employee}
            cards={cards}
            onChange={this.saveUpdatedCards}
          />
          {this.renderBottomButtons()}
        </Form>
      </div>
    );
  }
}
