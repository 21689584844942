import * as React from "react";
import { UserDocument, UserResource } from "../../../resources/user.resource";
import {
  Field,
  Form,
  Input,
  KosmicComponent,
  Rules,
  SubmitButton,
} from "../../commons";
import { AppContext, Consume } from "../context";

interface State {
  /** Toggles between ssn/passcode login and the email/password sign in forms  */
  useEmailLogin: boolean;
  /** The entered ssn number */
  entrySsn: string;
  /** The entered email address */
  entryEmail: string;
  /** The entered password when using email address */
  entryPassword: string;
}

/** View for signing in to the app */
export class LoginView extends KosmicComponent<{}, State> {
  state: State = {
    useEmailLogin: false,
    entryEmail: "",
    entryPassword: "",
    entrySsn: "",
  };

  @Consume(AppContext)
  protected app!: AppContext;

  /** Handles the submitting of the login form */
  private async onSubmit(values, resolve, reject) {
    try {
      const { entryEmail, entrySsn, entryPassword, useEmailLogin } = this.state;

      // Sign in using email
      if (useEmailLogin) {
        const foundUser = await this.globals.session.authenticate(
          entryEmail,
          entryPassword
        );

        // No user found
        if (!foundUser) {
          throw new Error("No user found");
        }

        // Successfully signed in
        await resolve();
        await this.app.updateState({
          selectedEmployee: foundUser as UserDocument,
        });
        return;
      }

      // Sign in using ssn + passcode
      else {
        // TODO: this can't be open here... Why do we need to get the passcode to check the passcode on the client???
        const foundUser = await new UserResource().findOneUser({
          personNr: entrySsn,
        });

        // No user found
        if (!foundUser) {
          throw new Error("No user found");
        }

        // No passcode set
        if (!foundUser.passcode || !foundUser.passcode.length) {
          throw new Error("Can't sign in a user without a passcode");
        }

        // Show the passcode dimmer
        const success = await this.app.showPasscodeDimmer({
          title: foundUser.name,
          body: "Ange din kod för att logga in",
          passcode: foundUser.passcode,
        });

        // Wrong passcode entered
        if (!success) {
          throw new Error("The wrong passcode was given");
        }

        const resource = new UserResource();

        // Perform an manual login
        const user = await resource.login({
          passcode: foundUser.passcode,
          personNr: foundUser.personNr,
        });

        if (!this.globals.session.authenticateWithDocument(user)) {
          throw new Error(
            "Could not authenticate with the found user document"
          );
        }

        // Successfully signed in
        await resolve();
        await this.app.updateState({ selectedEmployee: user });
        return;
      }
    } catch (err) {
      // Failed to sign in for any reason
      const formElemet = this.domElement.find(".view-form");
      formElemet.transition("shake");
      console.error("Signed in failed", err);
      await resolve();
      return;
    }
  }

  /** Handles changes to the ssn and makes sure its formatted correctly */
  private async onSSNChange(value: string) {
    value = value.replaceAll("-", "").trim();
    this.setState({ entrySsn: value });
  }

  private renderForm() {
    if (this.state.useEmailLogin) {
      return (
        <Form
          className="ui big floating segment view-form"
          onSubmit={this.onSubmit.bind(this)}
        >
          <Field>
            <Input
              key="email"
              icon="user"
              placeholder="Epost adress"
              name="email"
              autoComplete="username"
              value={this.state.entryEmail}
              onChange={(value) => this.setState({ entryEmail: value })}
              rules={[
                Rules.IsAnEmailAddress(
                  "Var snäll och ange en giltlig epostadress"
                ),
                Rules.NotEmpty("Du måste ange din epostadress"),
              ]}
            />
          </Field>
          <Field>
            <Input
              key="password"
              icon="asterisk"
              placeholder="Lösenord"
              name="password"
              protectedCharacters
              autoComplete="current-password"
              value={this.state.entryPassword}
              onChange={(value) => this.setState({ entryPassword: value })}
              rules={[Rules.NotEmpty("Du måste ange ditt lösenord")]}
            />
          </Field>
          <SubmitButton className="big fluid black">Logga in</SubmitButton>
        </Form>
      );
    }

    return (
      <Form
        className="big floating segment view-form"
        onSubmit={this.onSubmit.bind(this)}
      >
        <Field>
          <Input
            key="ssn"
            icon="user"
            name="ssn"
            type="numbser"
            placeholder="ååmmddxxxx"
            value={this.state.entrySsn}
            onChange={(value) => this.onSSNChange(value)}
            rules={[
              Rules.NotEmpty("Du måste ange ditt personnummer"),
              Rules.IsANumber(
                false,
                "Ditt personnummer måste anges med siffror"
              ),
              Rules.ExactLength(
                10,
                "Ange ditt personnummer med 10 siffror (ÅÅMMDDXXXX)"
              ),
            ]}
          />
        </Field>
        <SubmitButton className="big fluid green">Logga In</SubmitButton>
      </Form>
    );
  }

  render() {
    const emailLabel = "din e-postadress";
    const ssnLabel = "ditt personnummer";
    const headerLabel = this.state.useEmailLogin ? emailLabel : ssnLabel;
    const switchLabel = this.state.useEmailLogin ? ssnLabel : emailLabel;

    return (
      <div
        className="ui container"
        style={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="ui center aligned middle aligned grid">
          <div className="column" style={{ maxWidth: "540px" }}>
            <h2 className="ui header" style={{ paddingBottom: "1.5rem" }}>
              <div
                className="content"
                style={{ fontSize: "1.6rem", paddingBottom: "0.5rem" }}
              >
                Logga in som medarbetare
              </div>
              <div className="sub header" style={{ fontSize: "1.4rem" }}>
                Ange {headerLabel}
              </div>
            </h2>
            {this.renderForm()}
            <a
              style={{
                display: "block",
                paddingTop: "2rem",
                fontSize: "1.2rem",
                cursor: "pointer",
              }}
              onClick={() =>
                this.setState({ useEmailLogin: !this.state.useEmailLogin })
              }
            >
              Logga in med {switchLabel}{" "}
            </a>
          </div>
        </div>
      </div>
    );
  }
}
