import * as React from "react";
import {
  Field,
  Form,
  Input,
  MobxComponent,
  Rules,
  SubmitButton,
} from "../commons";

export class LoginForm extends MobxComponent<{
  successRedirect: string;
  className?: string;
}> {
  handleLogin = (values, resolve, reject) => {
    this.globals.session
      .authenticate(values["email"], values["password"])
      .then((user) => {
        if (user) {
          this.globals.session.navigateTo(this.props.successRedirect);
          return resolve();
        }
        return reject();
      })
      .catch((err) => {
        this.domElement.transition("shake");
        return reject();
      });
  };

  render() {
    return (
      <div className={this.props.className}>
        <Form className="large" onSubmit={this.handleLogin.bind(this)}>
          <Field>
            <Input
              icon="user"
              placeholder="Epost adress"
              name="email"
              autoComplete="username"
              rules={[
                Rules.IsAnEmailAddress(
                  "Var snäll och ange en giltlig epostadress"
                ),
                Rules.NotEmpty("Du måste ange din epostadress"),
              ]}
            />
          </Field>
          <Field>
            <Input
              icon="lock"
              placeholder="Lösenord"
              name="password"
              protectedCharacters
              autoComplete="current-password"
              rules={[Rules.NotEmpty("Du måste ange ditt lösenord")]}
            />
          </Field>
          <SubmitButton className="ui fluid large black button">
            Logga in
          </SubmitButton>
        </Form>
      </div>
    );
  }
}
