import Lodash from "lodash";
import * as Mobx from "mobx";
import * as Mongoose from "mongoose";

export type PopulateQuery =
  | string
  | {
      path: string;
      select?: string;
      populate?: PopulateQuery[];
      model?: string;
    };

/** A mongoose resource document */
export interface MongooseDocument extends Mongoose.Document {
  _id: Mongoose.Types.ObjectId;
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  deleted?: boolean;
}

/** A mongoose resource */
export abstract class MongooseResource<D extends MongooseDocument> {
  /** The name of this resource */ public name: string;
  /** The relative path that this resource is accessed from */ public path: string =
    "";
  private restrictedToTheseRoles: string[];
  /** Mongoose schema */ public schema: Mongoose.Schema;
  /** Indicates the parent if this resource is a subtype of another mongoose resource */ public parent: string;

  _populatedFields: any[] = [];

  public get restrictedToAuthenticatedUsers() {
    return !!this.restrictedToTheseRoles;
  }

  public validateAccess(user, ...roles: string[]) {
    if (this.restrictedToAuthenticatedUsers && !user) return false;

    const diff = Lodash.difference(this.restrictedToTheseRoles, roles);

    // Return true if any supplied role was in this resource "restrictedToTheseRoles" list, or the list was empty
    // to begin with
    return diff.length == 0 || diff.length < this.restrictedToTheseRoles.length;
  }

  constructor() {
    this.path = "/api/";
    this.schema = new Mongoose.Schema();

    // Include virtuals when mongoose documents are converted to objects
    this.schema.set("toObject", { virtuals: true });
    this.schema.set("toJSON", { virtuals: true });
  }

  /** Must be given a valid mongoose schema definition */
  protected setSchema(schemaDefinition: Mongoose.SchemaDefinition) {
    this.schema.add(schemaDefinition);
  }

  /** Set fields that should always be populated */
  protected populateField(definition: any) {
    this._populatedFields.push(definition);
  }

  /** Adds a virtual path to the mongoose schema */
  protected addVirtualField(
    path: string,
    getter: (schema: D) => any,
    setter?: (schema: D, value: any) => void
  ) {
    // TODO: (old) This seems to be less than working when returing an object through res.send
    let field = this.schema.virtual(path);
    field = field.get(function () {
      return getter(this as any); // FIXME: (2023) does this work? Type is for Document but resource is passed?
    });
    if (!!setter) {
      field = field.set(function (value) {
        return setter(this as any, value); // FIXME: (2023) does this work? Type is for Document but resource is passed?
      });
    }
  }

  /** Make this resource a subtype of the resource with the given name */
  protected setParentResource(name: string) {
    this.parent = name;
    //this.schema.set('discriminatorKey', '_type');
  }

  /** Returns a new resource document, it is not quarantied to be valid */
  public createDocument(object: Object): D {
    if (RUNTIME_ENVIRONMENT == "node") {
      return object as D; // TODO: (old) this should be improved, on server side we cant create documents without accessing the model
    }

    // TODO: (old) Remove mobx observable, also clones the object
    object = Mobx.isObservable(object) ? Mobx.toJS(object) : object;

    // TODO: (old) remove all extendedObservables as well!

    // Reset all populated fields to ObjectIds
    for (let key in object) {
      // So far I've found that _id is also present in the mongoose _doc and $__ keys, could there be other keys?
      if (object[key] && object[key]._id && key != "_doc" && key != "$__") {
        object[key] = object[key]._id;
      }
    }

    const isNew = object["isNew"];
    const document = new (Mongoose as any).Document(object, this.schema) as D;
    document.isNew = isNew || true;
    return document;
  }

  /** Validates a given object as a valid document conforming to this resource's schema */
  public validateDocument(document: MongooseDocument): Promise<void> {
    return document.validate().catch((err) => {
      Lodash.each(err.errors, (error) => {
        console.error("Document couldn't be validated", error.message);
      });
      throw err;
    });
  }

  // Request methods
  get(id: String | Mongoose.Types.ObjectId, passwordResetToken?: string) {
    // TODO: (old) ugly solution to auth problem
    return this.sendRequest<D>("/get", "post", {
      id: id,
      resetToken: passwordResetToken,
    });
  }
  delete(
    id: String | Mongoose.Types.ObjectId,
    options: {
      /** permanently remove the document record from the database */ removeRecord?: boolean;
    } = {}
  ) {
    return this.sendRequest<boolean>("/delete", "post", { id, ...options });
  }
  restore(id: String | Mongoose.Types.ObjectId) {
    return this.sendRequest<boolean>("/restore", "post", { id });
  }

  find(query: Object, passwordResetToken?: string) {
    // TODO: (old) passwordResetToken = ugly solution to auth problem
    return this.sendRequest<D[]>("/find", "post", {
      query,
      resetToken: passwordResetToken,
    });
  }

  updateDocument(document: D) {
    return new Promise<string>((resolve, reject) => {
      if (!document.validate) {
        document = this.createDocument(document);
      }

      return this.validateDocument(document)
        .then(() =>
          resolve(this.sendRequest<string>("/update", "post", { document }))
        )
        .catch(reject);
    });
  }

  /** Sends a request for retriving a resource */
  protected sendRequest<V>(
    endpoint: string,
    method: "get" | "post" | "delete" | "put",
    data: { [key: string]: any }
  ): Promise<V> {
    // Ignore server side // TODO: (old) is this safe?
    if (RUNTIME_ENVIRONMENT != "browser") {
      return Promise.resolve(undefined as any);
    }

    // Jsonify data body if necessary
    if (method != "get") {
      data = JSON.stringify(data) as any;
    }

    // Build the final url target, check if request is made from outside of the platform. (etc in an embedded ui module)
    // and appends a domain if necessary
    const target = this.path + this.name + endpoint;

    // Debug output
    if (RUNTIME_BRANCH != "master") {
      console.log("Requesting " + target);
    }

    return new Promise<V>((resolve, reject) => {
      $.ajax({
        crossDomain: true,
        url: target,
        method: method,
        dataType: "json",
        contentType: "application/json",
        data: data,
        success: (data) => resolve(data),
        error: (error, errorMessage, statusText) => {
          // Handle empty responses
          if (
            error.status == 200 &&
            errorMessage == "parsererror" &&
            (error.responseText === undefined || !error.responseText.length)
          ) {
            return resolve(undefined as any);
          }
          // Reject errors
          if (!!error.responseJSON) {
            const rejectReason = Lodash.merge(
              new Error(errorMessage),
              error.responseJSON
            );
            return reject(rejectReason);
          }
          if (!!error.responseText) {
            return reject(new Error(error.responseText));
          }
          return reject(new Error(errorMessage));
        },
      });
    });
  }

  /** Sets the name of this resource */
  protected setName(name: string) {
    this.name = name.toLowerCase();
  }

  /** Makes this resource restricted to signed in users, optionally with the supplied roles */
  protected restrictAccess(...roles: string[]) {
    this.restrictedToTheseRoles = roles || [];
  }

  /** Adds a subroute to access this resource */
  protected addToPath(path: string) {
    // Adds all non-empty string to this resource's path
    path.split("/").forEach((section) => {
      if (section) {
        this.path = this.path + path + "/";
      }
    });
  }
}
