import { MongooseDocument, MongooseResource } from "./_abstractResource";
import { Schema, Types } from "mongoose";

export interface OrganizationDocument extends MongooseDocument {
  name: string;
  managers: Types.ObjectId[];
  orgNumber: string;
  taxAgencyPassword: string;
  // Will be treated as false if undefined
  disabledAutoCheckout?: boolean;
  // Should be a date formated string (hh:mm)
  // Will be treated as "20:00" if undefined
  autoCheckoutTime?: string;
}

export class OrganizationResource extends MongooseResource<OrganizationDocument> {
  constructor() {
    super();

    this.setName("organization");

    this.setSchema({
      name: { type: String, required: true, unique: true },
      managers: [{ type: Schema.Types.ObjectId, ref: "default" }],
      orgNumber: { type: String, unique: true },
      taxAgencyPassword: {
        type: String,
        required: true,
        default: "P3rs0nalB0k3n",
      },
      disabledAutoCheckout: { type: Boolean, required: false },
      autoCheckoutTime: { type: String, required: false },
    });
  }
}
