import * as React from "react";
import { MobxComponent } from "./abstractComponent";
import { Alert } from "./alert";

interface Props {
  /** Text to be copied, will be displayed in input field if --> showInputField: true */ textToCopy?: string;
  /** The action text for the button, will not be displayed if --> showInputField: true */ buttonText?:
    | JSX.Element
    | string;
  /** Toggles between - copy button attached to input field & copy button with text */ showInputField?: boolean;
  /** Just the color of the buttons, defaults to the standard grey color */ color?:
    | "primary"
    | "secondary";
  /** The size of the the button/input field, defaults to mini */ size?:
    | "tiny"
    | "mini"
    | "small"
    | "medium"
    | " large"
    | "big"
    | "huge"
    | "massive";
}

export class CopyToClipboardButton extends MobxComponent<Props> {
  private copyText() {
    try {
      var tempInput = $("<input>");
      $("body").append(tempInput);
      tempInput.val(this.props.textToCopy!).select();
      document.execCommand("copy");
      tempInput.remove();
      this.domElement.transition("pulse");
    } catch (err) {
      Alert.show(
        "Var snäll och testa i en annan webbläsare.",
        "Kunde inte kopiera",
        "error"
      );
    }
  }

  private copyTextFromInputField() {
    try {
      const input = this.domElement.children().first();
      input.select();
      document.execCommand("copy");
      $(input).parent().transition("pulse");
      input.blur();
    } catch (err) {
      Alert.show(
        "Var god markera texten och kopiera manuellt.",
        "Kunde inte kopiera",
        "error"
      );
    }
  }

  public render() {
    if (this.props.showInputField) {
      return (
        <div
          className={`ui fluid ${this.props.size || "mini"} action input`}
          style={this.props.style}
        >
          <input type="text" defaultValue={this.props.textToCopy} />
          <button
            className={`ui ${this.props.size || "mini"} ${
              this.props.color
            } icon button ${!this.props.textToCopy && "disabled loading"}`}
            onClick={this.copyTextFromInputField.bind(this)}
          >
            <i className="copy icon" />
          </button>
        </div>
      );
    }
    return (
      <button
        className={`ui ${this.props.size || "mini"} ${
          this.props.color
        } icon button ${!this.props.textToCopy && "disabled loading"}`}
        onClick={this.copyText.bind(this)}
        style={this.props.style}
      >
        <i className="copy icon" />
        &nbsp;{this.props.buttonText}
      </button>
    );
  }
}
