import * as React from "react";

/**
 * Abstract Provider wrapper used to
 * create context providers in kosmic projects
 */
export abstract class Provider<
  Interface,
  Props = {},
  State = {}
> extends React.Component<Props & React.PropsWithChildren, Interface & State> {
  constructor(props: any) {
    super(props);
    this.state = this.initialState();
  }

  /** Use specifices the context to be used by the provider */
  protected abstract use(): React.Context<Interface>;

  /** Used to create the initial state of the provider */
  protected abstract initialState(): Interface & State;

  /** React lifecycle hook, should not be overridden */
  public render() {
    const Context = this.use();
    return (
      <Context.Provider value={this.state as any}>
        {this.props.children}
      </Context.Provider>
    );
  }
}
