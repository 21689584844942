import * as React from "react";
import { ReaderCommunicationProvider } from "../../contexts/readerComs";
import { UserResource } from "../../resources/user.resource";
import { Alert, MobxComponent } from "../commons";
import { AppContext, Consume } from "./context";
import { Dimmer } from "./dimmer";
import {
  CheckInView,
  DocumentationView,
  InspectionView,
  ListOfPropertiesView,
  LoginView,
  PropertyDetailsView,
  PropertyPublicView,
} from "./views";

/** Renders the correct content for the application depending on the state of the context */
export class AppContent extends MobxComponent<{}> {
  @Consume(AppContext)
  protected app: AppContext;

  /** Signs the current user out of the system */
  protected async signOut() {
    const confirmation = await this.app.showQuestionsDimmer({
      title: "Vill du byta användare?",
      body: "Du kommer att loggas ut från den här enheten.",
      continueText: "Logga ut",
      continueColor: "red",
      icon: "red help",
    });

    if (confirmation) {
      await this.globals.session.deauthenticate();
      await this.app.updateState({ selectedEmployee: null });
    }
  }

  /** Locks or unlocks the application to/from the public view */
  protected async lockOrUnlockPublicView() {
    const property = this.app.selectedProperty!;
    const isLocked = this.app.guiLocked;

    // Lock
    if (!isLocked) {
      const confirmation = await this.app.showQuestionsDimmer({
        title: "Är du säker på att du vill låsa appens vy?",
        body: (
          <span>
            När appen är låst kan alla som är på en arbetsplats checka in och ut
            med sin personliga kod. Du måste ange koden för projektet, $
            {property.name} för att komma tillbaka till denna vy igen.
            <br />
            <br />
            Koden är: &nbsp;&nbsp;<b>{property.passcode}</b>
          </span>
        ),
        continueText: "Lås vyn",
        continueColor: "red",
        icon: "red lock",
      });
      if (confirmation) {
        this.app.updateState({ guiLocked: true });
      }
    }
    // Unlock
    else {
      const success = await this.app.showPasscodeDimmer({
        title: property.name,
        body: "Ange platskoden för denna arbetsplats",
        passcode: property.passcode,
      });

      if (success) {
        this.app.updateState({ guiLocked: false });
      }
    }
  }

  /** Renders the correct view depending on the current state of the app context */
  private renderView() {
    const {
      loading,
      selectedEmployee,
      selectedProperty,
      guiLocked,
      guiSigningInManually,
      guiInspectionView,
      guiDocumentationView,
    } = this.app;

    // Sign in views
    if (!selectedEmployee) {
      return <LoginView />;
    }

    // Loading
    if (loading) {
      return <div className="ui large active loader" />;
    }

    // XML generation view
    if (guiInspectionView) {
      return <InspectionView selectedProperty={this.app.selectedProperty} />;
    }

    // Documentation View
    if (guiDocumentationView) {
      return <DocumentationView />;
    }

    // Property views
    if (selectedProperty) {
      if (guiSigningInManually) {
        return <CheckInView />;
      }
      if (guiLocked) {
        return <PropertyPublicView />;
      }
      return <PropertyDetailsView />;
    }

    return <ListOfPropertiesView />;
  }

  /** Renders header contenent depending on the current state of the app context */
  private renderHeader() {
    const { selectedProperty, selectedEmployee, guiLocked } = this.app;

    if (selectedProperty) {
      return (
        <div style={{ position: "relative", width: "100%", padding: "0 1rem" }}>
          {guiLocked && (
            <>
              <i className="ui left floated big red lock icon" />
              <i className="ui right floated big red lock icon" />
            </>
          )}
          <div className="ui center aligned container">
            <h2 style={{ marginBottom: "0.2em", marginTop: "0em" }}>
              {selectedProperty.name}
            </h2>
            <span>{selectedProperty.district}</span>
          </div>
        </div>
      );
    }
    if (selectedEmployee) {
      return (
        <h2 style={{ marginTop: "0.5em" }}>Hej {selectedEmployee.firstname}</h2>
      );
    }

    return <h2 style={{ marginTop: "0.5em" }}>Personalboken</h2>;
  }

  /** Renders the correct items in the bottom bar depending on the current state of the app context  */
  private renderFooter() {
    const {
      loading,
      selectedEmployee,
      selectedProperty,
      guiLocked,
      guiSigningInManually,
      guiInspectionView,
      guiDocumentationView,
    } = this.app;

    // Loading or login view
    if (loading) {
      return null;
    }

    const isMobile = this.globals.device.isMobileSize;

    // Other views
    return (
      <div
        className={`ui bottom fixed ${
          isMobile ? "mini" : ""
        } compact labeled icon menu`}
        style={{
          zIndex: 100,
          background: "rgba(255,255,255,1)",
          overflowX: "auto",
          paddingBottom: this.app.hasNotch ? "1rem" : undefined,
        }}
      >
        <FooterItem
          icon="purple fast backward"
          label="Tillbaka"
          onClick={() =>
            this.app.updateState({
              guiSigningInManually: false,
              guiInspectionView: false,
              guiDocumentationView: false,
            })
          }
          hidden={
            (!selectedProperty || !guiSigningInManually) &&
            !guiInspectionView &&
            !guiDocumentationView
          }
        />
        <FooterItem
          icon="red unlock"
          label="lås up"
          onClick={this.lockOrUnlockPublicView.bind(this)}
          hidden={
            !selectedProperty ||
            !guiLocked ||
            guiSigningInManually ||
            guiInspectionView ||
            guiDocumentationView
          }
        />
        <FooterItem
          icon="red key"
          label="lås vyn"
          onClick={this.lockOrUnlockPublicView.bind(this)}
          hidden={
            !selectedProperty ||
            guiLocked ||
            guiInspectionView ||
            guiDocumentationView
          }
        />
        <FooterItem
          icon="brown map pin"
          label="Välj en annan plats"
          onClick={() => this.app.updateState({ selectedProperty: undefined })}
          disabled={guiLocked}
          hidden={
            !selectedProperty ||
            guiSigningInManually ||
            guiInspectionView ||
            guiDocumentationView
          }
        />
        <FooterItem
          icon="black tasks"
          label="Checka in manuellt"
          onClick={() =>
            this.app.updateState({
              guiSigningInManually: true,
              guiDocumentationView: false,
            })
          }
          hidden={
            !selectedProperty || guiSigningInManually || guiInspectionView
          }
        />
        <div className="right menu">
          <FooterItem
            icon="black question circle"
            label="Hjälp"
            onClick={() => {
              this.app.updateState({ guiDocumentationView: true });
            }}
            hidden={
              guiSigningInManually ||
              guiInspectionView ||
              guiDocumentationView ||
              !selectedProperty
            }
          />
          <FooterItem
            icon="green balance scale"
            label="Skatteinspektion"
            onClick={() => {
              this.app.updateState({ guiInspectionView: true });
            }}
            hidden={
              guiSigningInManually ||
              guiInspectionView ||
              guiDocumentationView ||
              !selectedEmployee ||
              !new UserResource().documentHasRole(selectedEmployee, {
                type: "organization.manager",
              })
            }
          />
          <FooterItem
            icon="black settings"
            label="Admin Vy"
            onClick={() => this.globals.session.navigateTo("/admin")}
            hidden={
              !selectedEmployee ||
              !new UserResource().documentHasRole(selectedEmployee, {
                type: "organization.manager",
              }) ||
              guiLocked ||
              guiSigningInManually ||
              guiInspectionView
            }
          />
          <FooterItem
            icon="red remove"
            label="Logga ut"
            onClick={() => this.signOut()}
            hidden={
              !selectedEmployee ||
              guiLocked ||
              guiSigningInManually ||
              guiInspectionView
            }
          />
        </div>
      </div>
    );
  }

  private renderViewWrapper() {
    return (
      <ReaderCommunicationProvider>
        {this.renderView()}
      </ReaderCommunicationProvider>
    );
  }

  render() {
    return (
      <div className="pushable" style={CSS.root}>
        <Dimmer />
        <Alert />
        <div
          className="ui top three items centered fixed labeled top fixed icon menu"
          style={{
            ...CSS.header,
            ...(this.app.hasNotch ? CSS.headerNotch : {}),
          }}
        >
          <div className="item" style={{ margin: 0 }}>
            {this.renderHeader()}
          </div>
        </div>
        <div
          id="scroll-container"
          className="pusher"
          style={{
            ...CSS.scrollContainer,
            ...(this.app.hasNotch ? CSS.scrollContainerNotch : {}),
          }}
        >
          {this.renderViewWrapper()}
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}

/** Helper component to render items in the bottom bar */
function FooterItem(props: {
  /** Handler for the on click event */
  onClick?: () => any;
  /** Semantic UI icon and modifiers */
  icon: string;
  /** Text to render */
  label: string;
  /** Disables the item */
  disabled?: boolean;
  /** Hides the item from rendering */
  hidden?: boolean;
}) {
  return props.hidden ? null : (
    <a
      onClick={!props.disabled ? props.onClick : undefined}
      className="item"
      style={{ opacity: props.disabled ? 0.5 : 1 }}
    >
      <i className={`${props.icon} icon`}></i>
      <span>{props.label}</span>
    </a>
  );
}

const CSS: { [key: string]: React.CSSProperties } = {
  root: {
    background: "#eee",
    color: "#000",
  },
  header: {
    zIndex: 100,
    paddingTop: "1rem",
    height: "80px",
  },
  headerNotch: {
    paddingTop: "3rem",
    height: "110px",
  },
  scrollContainer: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    padding: "8rem 1rem",
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
  },
  scrollContainerNotch: {
    padding: "10rem 1rem",
  },
};
