import Moment from "moment-timezone";
import * as React from "react";
import { CardDocument, CardResource } from "../../resources/card.resource";
import {
  CardReadingDocument,
  CardReadingResource,
} from "../../resources/cardReading.resource";
import { UserDocument, UserResource } from "../../resources/user.resource";
import { Dropdown, DropdownItem, KosmicComponent, Table } from "../commons";

interface State {
  readings: CardReadingDocument[];
  loaded: boolean;
  /**
   * This map contains information
   * regarding the rfids referenced in readings.
   * If undefined it means the info has not been loaded
   * yet, if false the card does not exist otherwise a card.
   */
  cardMap: { [rfid: string]: undefined | false | CardDocument };
  employees: UserDocument[];
}
interface Props {
  reader: string;
}

export class CardReadingTable extends KosmicComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loaded: false,
      readings: [],
      cardMap: {},
      employees: [],
    };
  }

  async componentDidMount() {
    const readings = await new CardReadingResource().find({
      readerId: this.props.reader,
      createdAt: {
        $gte: Moment().startOf("day").toDate(),
        $lt: Moment().endOf("day").toDate(),
      },
    });

    const employees = await new UserResource().find({});
    await this.setState({ readings, employees, loaded: true });

    // Incrementaly update the cardmap
    for await (const cardMap of this.fetchCards()) {
      await this.setState({ cardMap });
    }
  }

  private async *fetchCards() {
    const { readings, cardMap } = this.state;
    for (const reading of readings) {
      const cards = await new CardResource().populateCardUsers({
        rfid: reading.rfid,
      });

      if (cards.length) cardMap[reading.rfid] = cards[0];
      else cardMap[reading.rfid] = false;

      yield cardMap;
    }
  }

  private async createCardForEmployee(rfid: string, employeeId: string) {
    if (!window.confirm("Vill du skapa ett kort för användaren du valt?")) {
      return;
    }
    const { cardMap } = this.state;
    const resource = new CardResource();
    const document = resource.createDocument({
      user: employeeId,
      rfid,
    });
    await resource.updateDocument(document);
    const card = (await resource.find({ rfid }))[0];
    cardMap[rfid] = card;
    this.setState({ cardMap });
  }

  private renderCardInformation(rfid: string) {
    const { employees, cardMap } = this.state;
    if (cardMap[rfid] == false)
      return (
        <Dropdown
          name="employee"
          searchable
          placeholder="Välj en ägare"
          onChange={this.createCardForEmployee.bind(this, rfid)}
        >
          {employees.map((emp) => (
            <DropdownItem
              value={emp._id.toString()}
              key={emp._id.toString().toString()}
            >
              {`${emp.firstname} ${emp.lastname}`}
            </DropdownItem>
          ))}
        </Dropdown>
      );
    if (cardMap[rfid] == undefined) return <div className="ui active loader" />;

    const card = cardMap[rfid] as CardDocument;
    return <span>{`${card.user.firstname} ${card.user.lastname}`}</span>;
  }

  render() {
    if (!this.state.loaded) {
      return <div className="ui active inline loader" />;
    }
    if (!this.state.readings.length) {
      return (
        <div className="ui warning message">
          <p>Inga kortläsningar har gjorts idag med den här läsaren.</p>
        </div>
      );
    }
    return (
      <Table
        dataList={this.state.readings}
        headers={[
          {
            title: "Tid vid läsning",
            sortBy: "createdAt",
            defaultSortDirection: "desc",
          },
          { title: "RFID-nummer" },
          { title: "Kort registrerat vid läsning?" },
          { title: "Kortägare" },
        ]}
        initSortColumn="createdAt"
        renderRow={(props) => (
          <tr key={props.id}>
            <td>{Moment(props.createdAt).format("llll")}</td>
            <td>{props.rfid}</td>
            <td>
              {props.isCardKnown ? (
                <i className="ui green thumbs up icon" />
              ) : (
                <i className="ui red thumbs down icon" />
              )}
            </td>
            <td>{this.renderCardInformation(props.rfid)}</td>
          </tr>
        )}
      />
    );
  }
}
