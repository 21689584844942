import * as React from "react";
import Lodash from "lodash";
import { Input, KosmicComponent, Alert, ConfirmationButton } from "../commons";
import { Link } from "../navigation";
import { ProtectedTableCell } from "../../components/commons/protectedTableCell";

import {
  PropertyDocument,
  PropertyResource,
} from "../../resources/property.resource";

interface Props {
  /** Indicates that the view should list only archived properties */ showArchived?: boolean;
}

interface State {
  /** The list of properties to display */
  properties: PropertyDocument[];
  /** Indicates that properties are being loaded */
  loading: boolean;
  /** Free text filter filled in by the user */
  filter: string;
}

/** Lists all properties belonging to the current organization */
export class ListOfPropertiesView extends KosmicComponent<Props, State> {
  state: State = {
    loading: true,
    filter: "",
    properties: [],
  };

  /** Fetches all properties to display and store them in state */
  private async fetchProperties() {
    this.setState({ loading: true });
    const organization = this.globals.session.currentUser!.organization;
    const properties = await new PropertyResource().find({ organization });
    this.setState({
      loading: false,
      properties: Lodash.sortBy(properties, (property) => property.name),
    });
  }

  /** Determines if a property should be rendered based on the entered search filter */
  private determineDocumentInclusion(property: PropertyDocument) {
    // Show based on archivation
    const visible = !!this.props.showArchived == !!property.archived;
    // Trim and normalize the entered search filter
    const search = this.state.filter.toLowerCase().trim();
    // Combined and normalize the document fields to search in
    const data =
      `${property.name}|${property.classification}|${property.district}`.toLowerCase();
    // If no search string has been entered, true will be returned, otherwise we search in the data string for the inclusion of search
    return visible && (!search.length || Lodash.includes(data, search));
  }

  /**
   * Archives a property.
   */
  private async toggleArchiveProperty(property: PropertyDocument) {
    try {
      const resource = new PropertyResource();
      await resource.toggleArchived(property);
    } catch (err) {
      Alert.show(
        `Kunde inte ${
          this.props.showArchived ? "aktivera" : "arkivera"
        } projektet.`,
        "Ett fel uppstod",
        "error"
      );
    } finally {
      await this.fetchProperties();
    }
  }

  componentDidMount() {
    // Fetch properties when the view is loaded
    this.fetchProperties();
  }

  get toggleArchivedProjectButton() {
    if (this.props.showArchived) {
      return (
        <Link
          to="/admin/properties"
          className="ui compact labeled icon button"
          style={{ marginBottom: "10px", float: "right" }}
        >
          <i className="eye icon" />
          Visa aktiva projekt
        </Link>
      );
    }
    return (
      <Link
        to="/admin/properties/archived"
        className="ui compact labeled icon button"
        style={{ marginBottom: "10px", float: "right" }}
      >
        <i className="eye icon" />
        Visa arkiverade projekt
      </Link>
    );
  }

  render() {
    const { showArchived } = this.props;

    if (this.state.loading) {
      return <div className="ui active huge inverted loader" />;
    }

    return (
      <div>
        <h2>{showArchived && "Arkiverade"} Projekt</h2>
        {this.toggleArchivedProjectButton}
        <Link
          to="/admin/properties/add"
          className="ui compact labeled icon green button"
          style={{ marginBottom: "10px", float: "right" }}
        >
          <i className="plus icon" />
          Lägg till ett projekt
        </Link>
        <p>
          Här listas alla inlagda projekt{showArchived ? " som arkiverats" : ""}
        </p>
        <br />
        <Input
          icon="search"
          placeholder="Sök efter ett projekt"
          onChange={(value) => this.setState({ filter: value })}
          value={this.state.filter}
          name="propertyFilterInput"
          fluid
        />
        <table className="ui selectable table">
          <thead>
            <tr>
              <th className="four wide">Namn</th>
              <th className="four wide">Område</th>
              <th className="two wide">Klassificering</th>
              <th className="one wide">Kod</th>
              <th className="one wide">Personallista</th>
              <th className="four wide"></th>
            </tr>
          </thead>
          <tbody>
            {Lodash(this.state.properties)
              .filter((property) => this.determineDocumentInclusion(property))
              .map((property) => (
                <tr key={property.id}>
                  <td>{property.name}</td>
                  <td>{property.district}</td>
                  <td>{property.classification}</td>
                  <ProtectedTableCell content={property.passcode} />
                  <td>{property.crew.length} personer</td>
                  <td>
                    <Link
                      to={"/admin/properties/" + property._id}
                      className="ui compact tiny basic icon button"
                    >
                      <i className="edit icon" /> Redigera
                    </Link>
                    <Link
                      to={"/admin/properties/statistics/" + property._id}
                      className="ui compact tiny basic icon button"
                    >
                      <i className="bar chart icon" /> Statistik
                    </Link>
                    <Link
                      to={"/admin/properties/inspection/" + property._id}
                      className="ui compact tiny basic icon button"
                    >
                      <i className="balance scale icon" /> Skatteinspektion
                    </Link>
                    <ConfirmationButton
                      className="compact tiny icon basic"
                      timeout={3000}
                      label={
                        <>
                          <i
                            className={
                              showArchived ? "undo icon" : "archive icon"
                            }
                          />{" "}
                          {showArchived ? "Aktivera" : "Arkivera"}
                        </>
                      }
                      activeButton={
                        <button
                          onClick={this.toggleArchiveProperty.bind(
                            this,
                            property
                          )}
                          className="ui compact tiny red icon button"
                        >
                          Är du säker?
                        </button>
                      }
                    />
                  </td>
                </tr>
              ))
              .value()}
          </tbody>
        </table>
      </div>
    );
  }
}
