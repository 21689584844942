import Lodash from "lodash";
import Moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import { ParamsContext } from "../../contexts";
import {
  ObservationDocument,
  ObservationResource,
} from "../../resources/observation.resource";
import { UserDocument, UserResource } from "../../resources/user.resource";
import { ProtectedTableCell } from "../commons/protectedTableCell";
import { Link } from "../navigation";

// Takes minutes and string formats the value.
const formatter = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainder = Math.floor(minutes % 60);

  return hours > 0
    ? `${hours} timmar & ${remainder} minuter`
    : `${remainder} minuter`;
};

export const PropertyStatisticsView: React.FC = () => {
  const params = useContext<ParamsContext>(ParamsContext as any);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [periodStart, setPeriodStart] = useState(new Date());
  const [periodEnd, setPeriodEnd] = useState(new Date());
  const [observations, setObservations] = useState<ObservationDocument[]>([]);
  const [users, setUsers] = useState<UserDocument[]>([]);

  // Sets the start of the periodStart.
  const setPeriod = (year, month) => {
    year = !year || isNaN(parseFloat(year)) ? Moment().year() : parseInt(year);
    month =
      !month || isNaN(parseFloat(month))
        ? Moment().month()
        : Math.max(0, Math.min(11, parseInt(month) - 1));
    setPeriodStart(Moment().year(year).month(month).startOf("month").toDate());
  };

  const totalTimeOfEmployee = (employee: UserDocument) => {
    return formatter(
      Lodash.sumBy(
        Lodash.filter(
          observations,
          (observation) => observation.employee.toString() == employee.id
        ),
        (observation) =>
          Moment.duration(
            Moment(observation.end || new Date()).diff(observation.beginning)
          ).asMinutes()
      )
    );
  };

  const nrOfCheckinsOfEmployee = (employee: UserDocument) => {
    return Lodash.filter(
      observations,
      (observation) => observation.employee.toString() == employee.id
    ).length;
  };

  // Calculates periodEnd value auto..
  useEffect(() => {
    setPeriodEnd(Moment(periodStart).add(1, "month").toDate());
  }, [periodStart]);

  useEffect(() => {
    try {
      setLoading(true);
      // Observation fetching
      const startOfPeriod = Moment(periodStart).startOf("month");
      const endOfPeriod = Moment(periodStart).endOf("month");
      const includeOngoing = Moment().isSame(periodStart, "M"); // If we are viewing the current month
      const query = {
        property: params.property!.id,
        $or: [
          {
            beginning: {
              $gte: startOfPeriod.toDate(),
              $lt: endOfPeriod.toDate(),
            },
          },
          { end: { $gte: startOfPeriod.toDate(), $lt: endOfPeriod.toDate() } },
        ],
      };

      if (includeOngoing) {
        query.$or.push({ end: { $exists: false } } as any);
      }
      new ObservationResource()
        .find(query)
        .then((observations) => {
          return observations.map((observation) => {
            observation.beginning = Moment.max(
              Moment(observation.beginning),
              startOfPeriod
            ).toDate();
            observation.end = Moment.min(
              Moment(observation.end),
              endOfPeriod
            ).toDate();
            return observation;
          });
        })
        .then((observations) => {
          setObservations(observations);
          return observations;
        })
        .then((observations) => {
          const userIds = Lodash.uniq(
            Lodash.map(observations || [], "employee")
          );
          return new UserResource().find({ _id: { $in: userIds } });
        })
        .then((users) => {
          setUsers(users);
        })
        .then(() => {
          setLoading(false);
          setError(false);
        });
    } catch (err) {
      console.error(err);
      setLoading(false);
      setError(true);
    }
  }, [periodStart]);

  useEffect(() => {
    setPeriod(params.year, params.month);
  }, [params]);

  const totalCheckins = observations.length;
  const nextPeriod = Moment(periodStart).add(1, "month");
  const prevPeriod = Moment(periodStart).subtract(1, "month");
  const nextMonthURL = `/admin/properties/statistics/${
    params.property!._id
  }/${nextPeriod.format("YYYY")}/${nextPeriod.format("MM")}`;
  const previousMonthURL = `/admin/properties/statistics/${
    params.property!._id
  }/${prevPeriod.format("YYYY")}/${prevPeriod.format("MM")}`;
  const totalMinutes = Lodash.sumBy(observations, (observation) =>
    Moment.duration(
      Moment(observation.end || new Date()).diff(observation.beginning)
    ).asMinutes()
  );
  const currentDatetime: string = Moment().format("dddd DD MMMM YYYY HH:mm");
  const selectedMonth = Moment(periodStart).format("MMMM");
  const selectedYear = Moment(periodStart).format("YYYY");

  return (
    <div>
      <h2>
        Statistik för {params.property!.name} under {selectedMonth}{" "}
        {selectedYear}
      </h2>
      <p>
        Visar sammanställd aktivitet under månaden.Sidan hämtades{" "}
        {currentDatetime}
      </p>
      <Link to={previousMonthURL} className="ui labeled button">
        <i className="arrow left icon" /> föregående månad
      </Link>
      <Link
        to={nextMonthURL}
        style={{ float: "right" }}
        className="ui right labeled button"
      >
        nästa månad <i className="arrow right icon" />
      </Link>
      <table className="ui celled table stackable">
        <thead>
          <tr>
            <th>Namn</th>
            <th>Personnumer</th>
            <th>Incheckningar</th>
            <th>Tid</th>
          </tr>
        </thead>
        <tbody>
          {error ? (
            <tr>
              <td colSpan={4}>
                <div className="ui fluid error message">
                  Statistiken gick inte att hämta
                </div>
              </td>
            </tr>
          ) : loading ? (
            <tr>
              <td colSpan={4}>
                <div className="ui active centered inline loader" />
              </td>
            </tr>
          ) : (
            users.map((employee) => (
              <EmployeeStatisticsRow
                key={employee.id + periodStart!.toString()}
                employee={employee}
                nrOfCheckins={nrOfCheckinsOfEmployee(employee)}
                totalTime={totalTimeOfEmployee(employee)}
              />
            ))
          )}
          <tr>
            <td colSpan={2} style={{ textAlign: "right" }}>
              <strong>Totalt: &nbsp; </strong>
            </td>
            <td>
              <strong>{totalCheckins} st incheckningar</strong>
            </td>
            <td>
              <strong>{formatter(totalMinutes)}</strong>
            </td>
          </tr>
        </tbody>
      </table>

      <h3>
        Total <i>{formatter(totalMinutes)}</i> arbetad tid på detta projekt
        under {Moment(periodStart).format("MMMM")}
      </h3>
    </div>
  );
};

const EmployeeStatisticsRow: React.FC<{
  employee: UserDocument;
  nrOfCheckins: number;
  totalTime: string;
}> = (props) => {
  return (
    <tr key={props.employee.id}>
      <td>{props.employee.name}</td>

      <ProtectedTableCell content={props.employee.personNr} />

      <td>{props.nrOfCheckins ? props.nrOfCheckins + " st" : ""}</td>
      <td>{props.totalTime}</td>
    </tr>
  );
};
