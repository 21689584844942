import * as React from "react";
import * as ReactRouter from "react-router";
import { MobxComponent } from "../commons/abstractComponent";

export class SecureRoute extends MobxComponent<
  {
    /** (Optional) the route to redirect to when not authenticated */ redirectTo?: string;
    /** (Optional) the role needed to access this route */ restrictedTo?: any;
  } & ReactRouter.RouteProps
> {
  state = {
    /** Indicates if we have determined if the user is authroized or still working */ resolved:
      false,
    /** True if we are authorized */ authorized: false,
  };

  componentDidMount() {
    this.checkAuthentication();
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState(
      {
        resolved: false,
        uncertain: true,
      },
      () => this.checkAuthentication()
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Only re-render when successfully resolved
    return nextState.resolved;
  }

  checkAuthentication() {
    return this.globals.session
      .validateAuthentication(this.props.restrictedTo)
      .then(
        () => {
          return true;
        },
        (err) => {
          // TODO: (old) error handling
          // console.error("An error occured when determining if authenticated in a secure route at:", this.props.path)
          // console.error(err)
          // Not logging here as business errors are expected

          return false;
        }
      )
      .then(
        (result) =>
          new Promise<void>((resolve) =>
            this.setState({ resolved: true, authorized: result }, () =>
              resolve()
            )
          )
      );
  }

  render() {
    // TODO: (old) This will not return anything for serverside rendering - should be fixed
    // when we implement proper codesplitting (I imagine a secure route would always be an split point)
    // Render a spinner before being resolved, or on serverside rendering
    if (RUNTIME_ENVIRONMENT == "node" || !this.state.resolved) {
      return <div className="ui active loader" />;
    }

    return this.state.authorized ? (
      <ReactRouter.Route
        exact={this.props.exact}
        path={this.props.path}
        render={this.props.render}
        component={this.props.component}
      >
        {this.props.children}
      </ReactRouter.Route>
    ) : (
      <ReactRouter.Redirect
        exact={this.props.exact}
        to={this.props.redirectTo || "/login"}
      />
    );
  }
}
