import Lodash from "lodash";
import * as React from "react";
import { KosmicComponent } from "../commons";
import { Link } from "../navigation";

import { Consume, ParamsContext } from "../../contexts";
import {
  PropertyDocument,
  PropertyResource,
} from "../../resources/property.resource";
import { RoleResource } from "../../resources/role.resource";
import { UserDocument } from "../../resources/user.resource";
import { ProtectedTableCell } from "../commons/protectedTableCell";

class EmployeeInfo extends KosmicComponent<{
  employee: UserDocument;
  property: PropertyDocument;
}> {
  @Consume(ParamsContext)
  private params!: ParamsContext;

  renderWhileLoading() {
    return <tr />;
  }

  removeEmployee = (e) => {
    e.preventDefault();
    new PropertyResource()
      .removeEmployee(this.props.property, this.props.employee._id)
      .then(async () => {
        await new RoleResource().updateUserRole({
          user: this.props.employee._id,
          roleName: "Crew",
          organization: this.globals.session.currentUser!.organization,
          property: this.props.property._id,
          add: false,
        });
      })
      .then(() => {
        this.domElement.transition({
          animation: "fade",
          onComplete: this.domElement.remove,
        });
      });
  };

  render() {
    var star;

    if (
      Lodash.some(
        this.props.employee.roles,
        (role) =>
          role.type == "property.manager" &&
          role.property == this.params.property!._id
      )
    ) {
      star = (
        <div className="ui compact small yellow label">
          <i className="star icon" /> Platsansvarig
        </div>
      );
    }

    return (
      <tr key={this.props.employee.id}>
        <td>
          {this.props.employee.name}
          &nbsp;&nbsp;
          {star}
        </td>
        <ProtectedTableCell content={this.props.employee.personNr} />
        <td>{this.props.employee.company}</td>
        <td style={{ textAlign: "right" }}>
          <Link
            to={"/admin/employees/" + this.props.employee.id}
            className="ui compact basic icon button"
          >
            <i className="write icon" />
            Redigera
          </Link>
          <a
            href=""
            className="ui compact basic icon button"
            onClick={this.removeEmployee}
          >
            <i className="remove icon" />
            Ta bort från personallistan
          </a>
        </td>
      </tr>
    );
  }
}

interface Props {}
interface State {
  crewList?: UserDocument[];
}

export class PropertyCrewList extends KosmicComponent<Props, State> {
  public state: State = {};

  @Consume(ParamsContext)
  private params!: ParamsContext;

  componentDidMount() {
    new PropertyResource()
      .populatePropertyCrew({ _id: this.params.property!._id })
      .then((properties) => {
        const crewList = properties
          ? (Lodash.sortBy(properties[0].crew, [
              "lastname",
              "firstname",
            ]) as any)
          : [];
        this.setState({ crewList });
      });
  }

  render() {
    const { crewList } = this.state;
    const property = this.params.property!;

    const employees =
      crewList && crewList.length ? (
        crewList.map((employee) => (
          <EmployeeInfo
            key={employee.id}
            property={property}
            employee={employee}
          />
        ))
      ) : (
        <tr>
          <td colSpan={4}>
            {" "}
            Det finns ingen personal listad på {property.name}{" "}
          </td>
        </tr>
      );

    return (
      <div style={{ paddingTop: "60px" }}>
        <h3>Personallistan för projektet</h3>
        <p style={{ marginBottom: "10px", marginTop: "10px" }}>
          <Link
            to={"/admin/properties/" + property.id + "/crew/edit"}
            className="ui compact green labeled icon button"
            style={{ float: "right", marginBottom: "10px" }}
          >
            <i className="list icon" /> Redigera listan
          </Link>
          Anställda som listas här räknas som ordinarie personal och syns alltid
          på projektets skärm för snabb incheckning
        </p>
        <table className="ui celled table stackable">
          <thead>
            <tr>
              <th>Namn</th>
              <th className="three wide">Personnummer</th>
              <th>Företag</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{employees}</tbody>
        </table>
      </div>
    );
  }
}
