import * as React from "react";
import { DefaultComponentProps } from "../abstractComponent";
import { FormStatus } from "./form";

/** Default properties for button components */
export interface ButtonProps extends DefaultComponentProps {
  /** Called when the button is pressed */ onClick?: () => void;
  /** Indicates if this component is currently performing an blocking action */ loading?: boolean;
  /** Indicates if this component is currently disabled from futher input */ disabled?: boolean;
}

/**
 * A button element
 */
export const Button: React.FunctionComponent<ButtonProps> = (props) => {
  const form = React.useContext(FormStatus);

  // Set status
  const loading = form.loading || props.loading;
  const disabled = form.disabled || form.loading || props.disabled;

  let className =
    "ui button " +
    (!!props.className ? props.className : "") +
    (loading || props.loading ? " loading" : "") +
    (disabled || props.disabled ? " disabled" : "");

  return (
    <div style={props.style} className={className} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

/**
 * A button element that automatically submits a form
 */
export const SubmitButton: React.FunctionComponent<ButtonProps> = (props) => {
  const form = React.useContext(FormStatus);

  // Set status
  const loading = form.loading || props.loading;
  const disabled =
    form.disabled || form.loading || props.disabled || !form.valid;

  let className =
    "ui submit button " +
    (!!props.className ? props.className : "") +
    (loading || props.loading ? " loading" : "") +
    (disabled || props.disabled ? " disabled" : "");

  return (
    <div style={props.style} className={className} onClick={props.onClick}>
      {props.children}
    </div>
  );
};
