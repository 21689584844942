import * as React from "react";
import { KosmicComponent } from "../../commons";
import { Consume, DeviceProvider, IDevice } from "../../../contexts";
import { DimmerProps } from "./dimmerProps";

export interface Props {
  /** The title to render */
  title: string;
  /** The body text to render */
  body: React.ReactNode;
  /** The icon to render */
  icon: string;
  /** The text to display on the continue button */
  continueText?: string;
  /** The color of the continue button */
  continueColor?: string;
}

/** Renders the contents of a question dimmer modal */
export class QuestionDimmerContent extends KosmicComponent<
  Props & DimmerProps
> {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  render() {
    const { title, icon, body, continueText, continueColor } = this.props;
    const h1FontSize: string =
      this.device.size == "desktop"
        ? "2.2em"
        : this.device.size == "tablet"
        ? "1.8em"
        : "1.2em";
    const h2FontSize: string = this.device.size == "tablet" ? "1.5em" : "1em";
    const iconSize: string = this.device.size == "desktop" ? "huge" : "large";

    return (
      <div
        className="ui two column centered middle aligned grid"
        style={{ padding: "3em" }}
      >
        <div className="row" style={{ textAlign: "center" }}>
          <h1>
            <i className={`${iconSize} ${icon} icon`} />
          </h1>
        </div>
        <div className="row" style={{ textAlign: "center" }}>
          <h1 style={{ fontSize: h1FontSize }}>{title}</h1>
        </div>
        <div className="row" style={{ textAlign: "center" }}>
          <h2
            style={{
              textAlign: "center",
              maxWidth: "700px",
              fontSize: h2FontSize,
            }}
          >
            {body}
          </h2>
        </div>
        <div className="row" style={{ textAlign: "center" }}>
          <a
            className={"huge ui button"}
            style={{ fontSize: h2FontSize, pointerEvents: "all" }}
            onTouchEnd={() => this.props.onHide(false)}
            onClick={() => this.props.onHide(false)}
          >
            Avbryt
          </a>
          <a
            className={"huge ui button " + (continueColor || "green")}
            style={{ fontSize: h2FontSize, pointerEvents: "all" }}
            onTouchEnd={() => this.props.onHide(true)}
            onClick={() => this.props.onHide(true)}
          >
            {continueText || "Fortsätt"}
          </a>
        </div>
        <div className="row" style={{ height: "2rem" }} />
      </div>
    );
  }
}
