import * as React from "react";
import { MobxComponent } from "../commons";
import { Types } from "mongoose";

import { PrivilegeType } from "../../schemas/privilege";

export class Maybe extends MobxComponent<{
  if: PrivilegeType;
  property?: Types.ObjectId;
}> {
  render() {
    if (
      this.globals.session.currentUser &&
      this.globals.session.userHasRole({
        type: this.props.if,
        property: this.props.property,
        organization: this.globals.session.currentOrganizationId,
      })
    ) {
      return <div>{this.props.children}</div>;
    }

    return <span />;
  }
}
