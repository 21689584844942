import * as React from "react";
import { MobxComponent } from "../commons";
import { NavLink, Route, Redirect, Switch } from "../navigation";
import { StatisticsView } from "./systemStatisticsView";
import { AddOrganizationView } from "./addOrganizationView";

export class SystemAdminViews extends MobxComponent<{}> {
  render() {
    return (
      <div style={{ marginTop: "-30px" }}>
        <div
          className="ui secondary inverted menu"
          style={{ paddingRight: "45px" }}
        >
          <div className="right menu">
            <NavLink
              className="item"
              activeClassName="active"
              to="/admin/system/statistics"
            >
              <i className="line chart icon" /> Systemstatistik{" "}
            </NavLink>
            <NavLink
              className="item"
              activeClassName="active"
              to="/admin/system/neworg"
            >
              <i className="plus icon" /> Lägg till en ny organisation{" "}
            </NavLink>
          </div>
        </div>
        <Switch>
          <Redirect exact from="/admin/system" to="/admin/system/statistics" />
          <Route path="/admin/system/statistics" component={StatisticsView} />
          <Route path="/admin/system/neworg" component={AddOrganizationView} />
        </Switch>
      </div>
    );
  }
}
