import * as React from "react";
import { MobxComponent, Transition, CookieConsentBanner } from "../commons";
import { LoginForm } from "../commons/loginForm";

/** View for signing in to the admin interface */
export class LoginView extends MobxComponent<{}> {
  componentDidMount() {
    $("body").attr("id", "admin");
  }

  render() {
    return (
      <Transition in animation="fly up">
        <div className="ui container">
          <div style={{ height: 100 }} />
          <div className="ui middle aligned center aligned grid">
            <div className="column" style={{ maxWidth: "540px" }}>
              <h1 style={{ textAlign: "center", color: "#1d292f" }}>
                Personalboken
              </h1>
              <h3 style={{ textAlign: "center", width: "100%" }}>
                Logga in som administratör
              </h3>
              <LoginForm
                successRedirect="/admin"
                className="ui piled segment"
              />
            </div>
          </div>
          <CookieConsentBanner />
        </div>
      </Transition>
    );
  }
}
