import * as Mongoose from "mongoose";
import { createBrowserHistory, BrowserHistory } from "history";

import { UserDocument, UserResource } from "../resources/user.resource";

class SessionManager {
  private _identifier: string;
  private _userResource: UserResource = new UserResource();
  private _serverUser: UserDocument | undefined;
  private _history: BrowserHistory;

  private _inMemoryStore: {
    user?: UserDocument;
    organization: any;
  };

  /** The current organization id if authenticated */
  get currentOrganizationId(): Mongoose.Types.ObjectId {
    if (this.currentOrganization && this.currentOrganization._id) {
      return this.currentOrganization._id;
    }
    if (this.currentOrganization && !this.currentOrganization._id) {
      return this.currentOrganization;
    }
    return "missing" as any;
  }

  get currentOrganization(): {
    _id: Mongoose.Types.ObjectId;
    id: string;
    name: string;
    admins: any;
  } & any {
    return !!this.currentUser
      ? this.currentUser.organization
      : (undefined as any);
  }

  /** The current user object if authenticated */
  get currentUser(): UserDocument | undefined {
    return this._serverUser || this._inMemoryStore?.user;
  }

  /** The current history object */
  get currentHistory(): BrowserHistory {
    return this._history;
  }
  /** The current history object */
  set currentHistory(history: BrowserHistory) {
    this._history = history;
  }

  public updateLocalStorage() {
    window.localStorage.setItem(
      this._identifier,
      JSON.stringify(this._inMemoryStore)
    );
  }

  public setCustomUserResource(resource: any) {
    this._userResource = resource;
  }

  constructor(options: { identifier: string; serverUser?: UserDocument }) {
    this._serverUser = options.serverUser;
    this._identifier = options.identifier;
    this._history = createBrowserHistory();

    this._inMemoryStore = {
      user: undefined as any,
      organization: undefined as any,
    };
    this._inMemoryStore = localStorage.getItem(this._identifier)
      ? JSON.parse(window.localStorage.getItem(this._identifier)!)
      : this._inMemoryStore;
  }

  /**
   * Checks if The current user has the required access and return true/false, false if not authenticated
   *  Use strict to ignore hierarchy
   */
  userHasRole(requiredRole: any): boolean {
    if (RUNTIME_ENVIRONMENT == "node") {
      return (
        !!this._serverUser &&
        this._userResource.documentHasRole(this._serverUser, requiredRole)
      );
    } else {
      if (!this._inMemoryStore.user) {
        return false;
      }
      return this._userResource.documentHasRole(
        this._inMemoryStore.user,
        requiredRole
      );
    }
  }

  /** Validates an ongoing authentication session, checks against a user role if specified */
  validateAuthentication(requiredRole?: string): Promise<boolean> {
    if (RUNTIME_ENVIRONMENT == "node") {
      return new Promise<boolean>((resolve, reject) => {
        if (this._serverUser) {
          return requiredRole && !this.userHasRole(requiredRole)
            ? reject(new Error("Roles does not match"))
            : resolve(true);
        } else reject(new Error("Not signed in"));
      });
    } else {
      if (!this.currentUser) {
        return Promise.reject(new Error("No local userdata"));
      }
      const query = this.getUrlSearchParams();
      const username = query.get("username") || undefined;
      const password = query.get("password") || undefined;
      return this._userResource
        .validateAuthentication(requiredRole, username, password)
        .then((value) => {
          return Promise.resolve(value); //TODO: (old) error handling?
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
  }

  /** Also validates an ongoing authentication session, but only checks against the server, removes the local storage if it fails */
  isAuthenticated(): Promise<boolean> {
    if (RUNTIME_ENVIRONMENT == "node") {
      throw new Error("dont");
    } else {
      return this._userResource
        .isAuthenticated()
        .then(() => {
          return Promise.resolve(true);
        })
        .catch((err) => {
          return Promise.resolve(false);
        });
    }
  }

  authenticateWithDocument(user: UserDocument, org?: any): boolean {
    if (!user) return false;
    this._inMemoryStore.user = user;
    if (org) {
      this._inMemoryStore.organization = org;
    }
    this.updateLocalStorage();
    return true;
  }

  /** Begins an authenticated session as a user, optionally redirects to a given path to a when done */
  authenticate(
    username: string,
    password: string,
    path?: string
  ): Promise<UserDocument> {
    if (this._serverUser) {
      return Promise.resolve(this._serverUser);
    } else {
      return this._userResource
        .login({ username: username, password: password })
        .then((user) => {
          this._inMemoryStore.user = user;
          this.updateLocalStorage();
          if (user && path && this._history) {
            this._history.push(path);
          }

          return user;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
  }

  /** Navigates to the route at the given path  */
  navigateTo(path: string): void {
    return this._history ? this._history.push(path) : undefined;
  }

  /** Navigates to the route at the given path without updating the history  */
  changePathTo(path: string): void {
    return this._history ? this._history.replace(path) : undefined;
  }

  /** Destroy any ongoing authenticated session, optionally redirects to a given path afterwards */
  deauthenticate(path?: string): Promise<boolean> {
    if (RUNTIME_ENVIRONMENT == "node") {
      delete this._serverUser;
      return Promise.resolve(true);
    }

    let promise = !this.currentUser
      ? Promise.resolve(true)
      : this._userResource.logout();

    promise
      .catch((err) => {
        // If the request fails we assumes it was because of a 401 error, and we are already signed out
        return true;
      })
      .then((success) => {
        if (success) {
          delete this._inMemoryStore.user;
          delete this._inMemoryStore.organization;
          this.updateLocalStorage();

          if (path) {
            // TODO: (old) This redirect exists because the modal acts wierd (locks up) when page not refreshes after logout.
            window.location.href = "/";
            //this.navigateTo(path)
          }
        }
      });
    return promise;
  }

  private getUrlSearchParams(): URLSearchParams {
    return new URLSearchParams(window.location.search);
  }
}

/** Global Session Manager Instance */
export const GlobalSessionManager = new SessionManager({
  identifier: "personalboken-sessions",
});
