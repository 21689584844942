import React, { useState, useEffect, useRef } from "react";

export function ConfirmButton({ action }) {
  const [triggered, setTriggered] = useState(false);
  const buttonRef = useRef(null);

  const trigger = () => {
    setTriggered(!triggered);
    if (!triggered) {
      setTimeout(() => setTriggered(false), 3000);
    }
  };

  useEffect(() => {
    // Apply the transition using the ref
    if (buttonRef.current) {
      // Replace 'your-transition-class' with your actual transition class
      $(buttonRef.current).transition("jiggle");
    }
  }, [triggered]);

  return (
    <button
      ref={buttonRef}
      className={`${triggered ? "red" : "basic"} fluid icon ui button`}
      onClick={triggered ? action : trigger}
    >
      <i className="sign out icon" />
      &nbsp;{triggered ? "Är du säker?" : "checka ut"}
    </button>
  );
}
