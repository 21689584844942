import * as React from "react";
import { MobxComponent } from "../commons";

//TODO: (old) unused in admin, can it be released?
export class LogoutButton extends MobxComponent<{
  path: string;
  className?: string;
}> {
  handleLogout = (event) => {
    event.preventDefault();
    this.globals.session.deauthenticate(this.props.path);
  };

  render() {
    return (
      <div
        className={"ui tiny red button " + this.props.className}
        onClick={this.handleLogout}
      >
        Logga ut
      </div>
    );
  }
}
