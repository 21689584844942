import * as React from "react";
import Moment from "moment-timezone";
import { KosmicComponent } from "../../commons";

import { UserDocument } from "../../../resources/user.resource";
import {
  ObservationResource,
  ObservationDocument,
} from "../../../resources/observation.resource";
import { AppContext, Consume } from "../context";
import { ReaderCommunicationContext } from "../../../contexts/readerComs";
import { CheckinType } from "../../../schemas/checkInType";

interface Props {
  /** The property to render in the card */
  employee: UserDocument;
}

interface State {
  /** The datetime this user was first signed in to the selected property, if checked in */
  checkedInSince: Date | undefined;
  /** Indicates that we are currently updating the status of this card */
  loading: boolean;
  /** Indicates that this employee is signed in somewhere else */
  unavailable: boolean;
}

export class EmployeeCard extends KosmicComponent<Props, State> {
  state: State = {
    checkedInSince: undefined,
    loading: true,
    unavailable: false,
  };

  @Consume(AppContext)
  protected app!: AppContext;

  @Consume(ReaderCommunicationContext)
  private readerComs!: ReaderCommunicationContext;

  /** Callback called when the employee card is pressed */
  private async onPress() {
    const property = this.app.selectedProperty!;
    const { unavailable, loading } = this.state;
    const { employee } = this.props;
    let success = false;

    // The card is unclickable if the employee is unavailable or the card is loading
    if (unavailable || loading) {
      success = false;
    }
    // If the application is in a public mode, the passcode needs to be entered to toggle the
    // check-in status of an employee, if it exists
    else if (
      this.app.guiLocked &&
      employee.passcode &&
      employee.passcode.length
    ) {
      // FIXME (old) Some state update makes this component to
      // unmount.
      success = await this.app.showPasscodeDimmer({
        title: employee.name,
        body: `Ange din personliga kod för att checka ${
          this.isCheckedIn ? "ut" : "in"
        }`,
        passcode: employee.passcode,
      });
    } else {
      success = true;
    }

    // Switch the current checked-in status of the employee and reload the card
    if (success) {
      await new ObservationResource().switch(
        property,
        employee,
        CheckinType.ipad
      );
      await this.updateStatus();
      this.domElement.transition("bounce");
    }
    // Shake the card on failure
    else {
      this.domElement.transition("shake");
    }
  }

  /** Refreshes the status of this card */
  private async updateStatus() {
    const { employee } = this.props;
    const property = this.app.selectedProperty!;
    const resource = new ObservationResource();

    this.setState({ loading: true });

    try {
      const observation = await resource.whereIsTheEmployee(employee);
      // If the employee is checked in elsewhere, its unavailable here
      if (observation && observation.property.toString() != property.id) {
        this.setState({
          loading: false,
          unavailable: true,
          checkedInSince: undefined,
        });
      }
      // Otherwise we are checked in at this location
      else if (observation) {
        this.setState({
          loading: false,
          unavailable: false,
          checkedInSince: observation.beginning,
        });
      } else {
        this.setState({
          loading: false,
          unavailable: false,
          checkedInSince: undefined,
        });
      }
    } catch (err) {
      console.error("Failed to update the status of an employee", err);
      this.domElement.transition("shake");
      this.setState({ loading: false, checkedInSince: undefined });
    }
  }

  /** Indicates that this employee is a checkedIn at the selected property */
  private get isCheckedIn() {
    return this.state.checkedInSince ? true : false;
  }

  private onObservation = (observation: ObservationDocument) => {
    if (observation.employee == this.props.employee._id) {
      this.updateStatus();
    }
  };

  componentWillUnmount() {
    // Remove listener for observation updates
    this.readerComs.removeObservationListener(this.onObservation);
  }

  async componentDidMount() {
    // Setup listener for observation updates
    this.readerComs.addObservationListener(this.onObservation);
    // Update the status of this card when its mounted
    await this.updateStatus();
  }

  render() {
    const { loading, unavailable, checkedInSince } = this.state;
    const { employee } = this.props;

    let primaryColor, secondaryColor, metaElement, company, profileImage;

    company = this.props.employee.company;
    profileImage = "profile_dark";

    // Loading
    if (loading) {
      primaryColor = "grey";
      secondaryColor = "grey";
      profileImage = "profile_light";

      metaElement = (
        <div className="meta" style={{ color: primaryColor }}>
          <h4>
            <div className="ui active inline loader" /> kontrollerar status...
          </h4>
        </div>
      );
    }
    // Not currently available on this property
    else if (unavailable) {
      primaryColor = "grey";
      secondaryColor = "grey";
      profileImage = "profile_light";

      metaElement = (
        <div className="meta" style={{ color: primaryColor }}>
          <h4>Incheckad på annan plats</h4>
        </div>
      );
    }
    // Checked in at this property
    else if (this.isCheckedIn) {
      primaryColor = "green";
      secondaryColor = "black";
      profileImage = "profile_green";

      metaElement = (
        <div className="meta" style={{ color: primaryColor }}>
          <h4>
            <i className="check circle icon" />
            {Moment(checkedInSince).calendar()}
          </h4>
        </div>
      );
    }
    // Not checked in
    else {
      primaryColor = "grey";
      secondaryColor = "grey";
      profileImage = "profile_light";

      metaElement = (
        <div className="meta" style={{ color: primaryColor }}>
          <h4>utcheckad</h4>
        </div>
      );
    }

    return (
      <div
        className={"ui clearing fluid link card " + primaryColor}
        style={{ paddingBottom: 30 }}
        onClick={this.onPress.bind(this)}
      >
        <div className="content" style={{ color: secondaryColor }}>
          <img
            className="left floated mini ui image"
            src={"/static/img/" + profileImage + ".png"}
          />
          <div className="header" style={{ color: primaryColor }}>
            <h3>{employee.name}</h3>
          </div>
          {metaElement}

          <span>{company}</span>
        </div>
      </div>
    );
  }
}
