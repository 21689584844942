import Lodash from "lodash";
import * as React from "react";
import {
  Alert,
  DefaultPredicate,
  Input,
  KosmicComponent,
  SearchableList,
} from "../commons";
import { Link } from "../navigation";

import { Consume, ParamsContext } from "../../contexts";
import { PropertyResource } from "../../resources/property.resource";
import { RoleResource } from "../../resources/role.resource";
import { UserDocument, UserResource } from "../../resources/user.resource";

function employeeSearchPredicate(text, item: UserDocument) {
  return DefaultPredicate(
    text,
    item.firstname.toString() +
      item.lastname.toString() +
      item.personNr.toString() +
      (item.company || "").toString()
  );
}

class EmployeeItem extends KosmicComponent<
  { document: UserDocument },
  { isLoading: boolean; employee: UserDocument }
> {
  @Consume(ParamsContext)
  private params!: ParamsContext;

  constructor(props) {
    super(props);
    this.state = { isLoading: false, employee: props.document };
  }

  private get employee() {
    return this.state.employee;
  }

  private get isCrewMember(): Boolean {
    return Lodash.includes(this.params.property!.crew, this.employee._id);
  }

  private get isManager(): Boolean {
    return new UserResource().documentHasRole(this.employee, {
      type: "property.manager",
      property: this.params.property!._id,
    });
  }

  private get isOrgManager(): Boolean {
    return new UserResource().documentHasRole(this.employee, {
      type: "organization.manager",
      organization: this.params.property!.organization._id,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ employee: nextProps.document });
  }

  private removeFromPropertyCrewList = async (e) => {
    e.preventDefault();

    const property = this.params.property!;
    property.crew = property.crew.filter(
      (id) => id.toString() != this.employee.id
    );
    await this.setState({ isLoading: true });

    await new PropertyResource().updateDocument(property);

    const updatedUser = await new RoleResource().updateUserRole({
      user: this.employee._id,
      roleName: "Crew",
      organization: this.globals.session.currentUser!.organization,
      property: property._id,
      add: false,
    });

    await this.params.updateState({ property });
    await this.setState({ isLoading: false });
    this.employee.rolesV2 = updatedUser.rolesV2;
  };

  private addToPropertyCrewList = async (e) => {
    e.preventDefault();

    const property = this.params.property!;
    property.crew.push(this.employee._id);
    property.crew = Lodash.uniq(property.crew);
    await this.setState({ isLoading: true });

    await new PropertyResource().updateDocument(property);

    const updatedUser = await new RoleResource().updateUserRole({
      user: this.employee._id,
      roleName: "Crew",
      organization: this.globals.session.currentUser!.organization,
      property: property._id,
      add: true,
    });

    await this.params.updateState({ property });
    await this.setState({ isLoading: false });
    this.employee.rolesV2 = updatedUser.rolesV2;
  };

  private removeAsManager = async (e) => {
    try {
      const userResource = new UserResource();
      e.preventDefault();
      const { employee } = this.state;

      const updatedEmployee = userResource.createDocument(employee);
      updatedEmployee.roles = Lodash.filter(
        employee.roles,
        (role: any) =>
          !(
            role.type == "property.manager" &&
            role.property == this.params.property!._id
          )
      );

      await this.setState({ isLoading: true });

      await userResource.updateDocument(updatedEmployee);

      const updatedUser = await new RoleResource().updateUserRole({
        user: this.employee._id,
        roleName: "Property Manager",
        organization: this.globals.session.currentUser!.organization,
        property: this.params.property!._id,
        add: false,
      });

      employee.roles = updatedEmployee.roles;
      employee.rolesV2 = updatedUser.rolesV2;
    } catch (err) {
      Alert.show(
        "Det gick inte att spara rollen för den anställde",
        "Ett fel har uppstått",
        "error"
      );
    } finally {
      await this.setState({ isLoading: false });
    }
  };

  private addAsManager = async (e) => {
    try {
      e.preventDefault();
      const userResource = new UserResource();
      const { employee } = this.state;

      const updatedEmployee = userResource.createDocument(employee);
      updatedEmployee.roles.push({
        type: "property.manager",
        property: this.params.property!._id,
        organization: this.params.property!.organization._id,
      });

      await this.setState({ isLoading: true });

      await userResource.updateDocument(updatedEmployee);

      const updatedUser = await new RoleResource().updateUserRole({
        user: this.employee._id,
        roleName: "Property Manager",
        organization: this.globals.session.currentUser!.organization,
        property: this.params.property!._id,
        add: true,
      });

      employee.roles = updatedEmployee.roles;
      employee.rolesV2 = updatedUser.rolesV2;
    } catch (err) {
      Alert.show(
        "Det gick inte att spara rollen för den anställde",
        "Ett fel har uppstått",
        "error"
      );
    } finally {
      await this.setState({ isLoading: false });
    }
  };

  private get CrewStatusButton() {
    if (this.state.isLoading) {
      return (
        <div
          className="ui inline active loader"
          style={{ width: "100%", textAlign: "center" }}
        />
      );
    }
    if (this.isCrewMember) {
      return (
        <div
          onClick={this.removeFromPropertyCrewList}
          className="ui fluid green icon button"
        >
          <i className="checkmark icon" /> I personallistan
        </div>
      );
    }
    return (
      <div
        onClick={this.addToPropertyCrewList}
        className="ui fluid basic green labeled icon button"
      >
        <i className="plus icon" /> Lägg till i personallistan
      </div>
    );
  }

  private get ManagerStatusButton() {
    if (this.state.isLoading) {
      return (
        <div
          className="ui inline active loader"
          style={{ width: "100%", textAlign: "center" }}
        />
      );
    }
    if (this.isOrgManager) {
      return (
        <div className="ui fluid basic orange icon button">
          <i className="star icon" />
          <i className="star icon" />
          <i className="star icon" /> Administratör
        </div>
      );
    }
    if (this.isManager) {
      return (
        <div
          onClick={this.removeAsManager}
          className="ui fluid yellow icon button"
        >
          <i className="star icon" /> Platsansvarig
        </div>
      );
    }
    return (
      <div
        onClick={this.addAsManager}
        className="ui fluid basic labeled icon button"
      >
        <i className="plus icon" />
        Gör till platsansvarig
      </div>
    );
  }

  render() {
    if (!this.employee) {
      return <tr />;
    }

    return (
      <tr key={this.employee.id} style={{ position: "relative" }}>
        <td>{this.employee.name}</td>
        <td>{this.employee.company}</td>
        <td>{this.CrewStatusButton}</td>
        <td>{this.ManagerStatusButton}</td>
      </tr>
    );
  }
}

interface Props {}
interface State {
  items: UserDocument[];
  filter: string;
}

export class EditPropertyCrewListView extends React.Component<Props, State> {
  @Consume(ParamsContext)
  private params!: ParamsContext;

  state = {
    items: [],
    filter: "",
  };

  componentDidMount(): void {
    const property = this.params.property!;
    new UserResource()
      .find({ organization: property.organization })
      .then((items) => this.setState({ items }));
  }

  /** Determines if a employee should be rendered based on the entered search filter */
  private determineDocumentInclusion(employee: UserDocument) {
    // Trim and normalize the entered search filter
    const search = this.state.filter.toLowerCase().trim();
    // Combined and normalize the document fields to search in
    const data =
      `${employee.id}|${employee.company}|${employee.name}`.toLowerCase();
    // If no search string has been entered, true will be returned, otherwise we search in the data string for the inclusion of search
    return !search.length || Lodash.includes(data, search);
  }

  render() {
    const { property } = this.params;
    const { items } = this.state;

    let wrapper = (props) => {
      return (
        <div>
          <table className="ui celled selectable stackable table">
            <thead>
              <tr>
                <th>Namn</th>
                <th>Företag</th>
                <th className="four wide">I personallistan</th>
                <th className="three wide">Platsansvarig</th>
              </tr>
            </thead>
            <tbody>{props.children}</tbody>
          </table>
        </div>
      );
    };

    return (
      <div style={{ paddingTop: "60px" }}>
        <h3>Personallistan för projektet</h3>
        <p style={{ marginBottom: "10px", marginTop: "10px" }}>
          <Link
            to={"/admin/properties/" + property!.id + "/crew"}
            className="ui compact orange labeled icon button"
            style={{
              float: "right",
              marginBottom: "10px",
              marginRight: "-1px",
            }}
          >
            <i className="left arrow icon" /> Sluta redigera
          </Link>
          välj vilka användare som ska tillhöra projektet och vilka som ska vara
          platsansvariga
        </p>
        <Input
          style={{ width: "100%", marginBottom: "10px" }}
          icon="search"
          placeholder="Sök"
          onChange={(value) => this.setState({ filter: value })}
          value={this.state.filter}
          name="propertyFilterInput"
          fluid
        />
        <SearchableList
          sortByFields={["firstname", "lastname"]}
          chunkSize={100}
          wrapper={wrapper}
          template={(props) => <EmployeeItem document={props.item} />}
          items={items.filter((item) => this.determineDocumentInclusion(item))}
          predicate={employeeSearchPredicate}
          showDefaultSearchBar={true}
        />
      </div>
    );
  }
}
