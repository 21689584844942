import Lodash from "lodash";
import { Schema, Types } from "mongoose";
import "../schemas/taxAgencyXmlInterfaces";
import { MongooseDocument, MongooseResource } from "./_abstractResource";

import { OrganizationDocument } from "./organization.resource";
import { UserDocument } from "./user.resource";

type ManualSignInResponse = { employee: UserDocument; newUser: boolean };

export interface PropertyDocument extends MongooseDocument {
  organization: Types.ObjectId & OrganizationDocument;
  archived?: boolean;
  name: string;
  district?: string;
  classification: string;
  passcode: string;
  taxAgencyIdentification?: string;
  crew: Types.ObjectId[];
}

export class PropertyResource extends MongooseResource<PropertyDocument> {
  static IsAPropertyDocument(object: any): object is PropertyDocument {
    return !!object && "classification" in object;
  }

  constructor() {
    super();
    this.setName("property");

    this.setSchema({
      organization: {
        type: Schema.Types.ObjectId,
        ref: "organization",
        required: true,
      },
      name: { type: String, required: true },
      archived: { type: Boolean },
      district: { type: String },
      classification: { type: String, required: true },
      passcode: { type: String, required: true },
      taxAgencyIdentification: { type: String },
      crew: [{ type: Schema.Types.ObjectId, ref: "default" }],
    });
  }

  toggleArchived(property: PropertyDocument) {
    return this.sendRequest<boolean>("/toggleArchived", "post", {
      id: property.id,
    });
  }

  findAccessableProperties() {
    return this.sendRequest<Array<PropertyDocument>>("/accessable", "post", {});
  }

  employeesAtLocation(propertyId: Types.ObjectId) {
    return this.sendRequest<UserDocument[]>("/employeesAtLocation", "post", {
      propertyId: propertyId,
    });
  }

  manualSignIn(
    property: PropertyDocument,
    employee: {
      personNr: string;
      firstname: string;
      lastname: string;
      company: string;
      orgNr?: string;
    }
  ) {
    // NOTE: (old) this method will not result in websocket clients being updated after a user signs in the the property
    // not fixed in the ongoing-hotfix, but should be refactored so that the card reader resource/receiver pair handles all updates of
    // observations
    return this.sendRequest<ManualSignInResponse>("/manual/signin", "post", {
      propertyId: property._id,
      ssn: employee.personNr,
      firstname: employee.firstname,
      lastname: employee.lastname,
      company: employee.company,
      orgNr: employee.orgNr,
    });
  }

  addEmployee(property: PropertyDocument, employeeId: Types.ObjectId | String) {
    property.crew = Lodash.filter(
      property.crew as Types.ObjectId[],
      (id) => id.toString() != employeeId.toString()
    );
    (property.crew as Types.ObjectId[]).push(
      new Types.ObjectId(employeeId.toString())
    );

    return this.updateDocument(property);
  }
  removeEmployee(property: PropertyDocument, employeeId: Types.ObjectId) {
    property.crew = Lodash.filter(
      property.crew as Types.ObjectId[],
      (id) => id.toString() != employeeId.toString()
    );

    return this.updateDocument(property);
  }

  populatePropertyOrganisation(query: Object) {
    return this.sendRequest<PropertyDocument[]>(
      "/populatePropertyOrganisation",
      "post",
      { query }
    );
  }

  populatePropertyCrew(query: Object) {
    return this.sendRequest<PropertyDocument[]>(
      "/populatePropertyCrew",
      "post",
      { query }
    );
  }
}
