import * as React from "react";
import { PropertyResource } from "../../resources/property.resource";
import { RoleResource } from "../../resources/role.resource";
import { KosmicComponent } from "../commons";

interface State {
  _onceValidated: boolean;
}
interface Props {}

export class AddPropertyView extends KosmicComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { _onceValidated: false };
  }

  createProperty = async () => {
    const locationForm = $("#locationForm");
    locationForm.form("validate form");

    await this.setState({ _onceValidated: true });

    if (!locationForm.form("is valid")) {
      return;
    }

    const resource = new PropertyResource();
    const roleResource = new RoleResource();

    const document = resource.createDocument({
      organization: this.globals.session.currentUser!.organization,
      name: $("#name").val(),
      district: $("#district").val(),
      classification: $("#classification").val(),
      passcode: $("#passcode").val(),
      crew: [],
      taxAgencyIdentification: $("#taxAgencyIdentification").val(),
    });

    const managerRoleDocument = roleResource.createDocument({
      name: "Property Manager",
      organization: this.globals.session.currentUser!.organization,
      property: document._id,
      privileges: [
        "observation.*",
        "property.*",
        "user.read",
        "card.*",
        "organization.read",
      ],
    });

    const crewRoleDocument = roleResource.createDocument({
      name: "Crew",
      organization: this.globals.session.currentUser!.organization,
      property: document._id,
      privileges: ["property.read"],
    });

    await roleResource.updateDocument(managerRoleDocument);
    await roleResource.updateDocument(crewRoleDocument);

    const promise = resource.updateDocument(document);

    promise.catch((err) => {
      console.error(err);
    });
    promise.then(() => {
      this.globals.session.navigateTo(
        "/admin/properties/" + document._id.toString()
      );
    });
  };

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    $("#locationForm").form({
      fields: {
        locationame: {
          identifier: "name",
          rules: [
            {
              type: "minLength[2]",
              prompt:
                "Projektet måste ha en unik identifikation på minst 2 karaktärer",
            },
          ],
        },
        district: {
          identifier: "district",
          rules: [
            {
              type: "empty",
              prompt: "Du måste ange ett område för projektet",
            },
          ],
        },
        classification: {
          identifier: "classification",
          rules: [
            {
              type: "empty",
              prompt: "Du måste ange en klassificering för projektet",
            },
          ],
        },
        passcode: {
          identifier: "passcode",
          rules: [
            {
              type: "integer",
              prompt: "Projektets kod får bara innehålla siffror",
            },
            {
              type: "exactLength[6]",
              prompt: "Projektets kod måste vara sex siffror lång",
            },
          ],
        },
        taxAgencyIdentification: {
          identifier: "taxAgencyIdentification",
          rules: [
            {
              type: "empty",
              prompt:
                "Du måste ange ett identifikationsnummer för Skatteverket",
            },
            {
              type: "regExp[/PL\\d{4}[A-Z]{2}\\d{5}/]",
              prompt:
                "Identifikationsnumret måste följa skatteverkets specifikation",
            },
          ],
        },
      },
    });
  }

  handleChange = () => {
    if (this.state._onceValidated) {
      $("#locationForm").form("validate form");
    }
  };

  navigateBack = () => this.globals.session.navigateTo("/admin/properties");

  render() {
    return (
      <div id="locationForm" className="ui form">
        <h2>
          <i>Lägg till ett nytt projekt</i>
        </h2>
        <div className="ui attached segment">
          <div className="field">
            <label>Projektidentifikation</label>
            <input
              type="text"
              onChange={this.handleChange}
              placeholder="identifikation för projektet"
              id="name"
            />
          </div>
          <div className="field">
            <input
              type="text"
              onChange={this.handleChange}
              placeholder="område"
              id="district"
            />
          </div>
          <div className="field">
            <input
              type="text"
              onChange={this.handleChange}
              placeholder="klassificering"
              id="classification"
            />
          </div>
          <div className="field">
            <input
              type="text"
              onChange={this.handleChange}
              placeholder="kod"
              id="passcode"
            />
          </div>
          <div className="field">
            <input
              type="text"
              placeholder="identifikationsnummer för Skatteverket"
              onChange={this.handleChange}
              id="taxAgencyIdentification"
            />
          </div>
          <div className="ui error message"></div>
        </div>
        <div className="ui two bottom attached buttons">
          <div onClick={this.navigateBack} className="ui red button">
            <i className="left arrow icon" />
            Avbryt
          </div>
          <div onClick={this.createProperty} className="ui green button">
            <i className="save icon" />
            Spara som nytt projekt
          </div>
        </div>
      </div>
    );
  }
}
