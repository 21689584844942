import * as React from "react";
import { KosmicComponent } from "../../commons";
import { AppContext, Consume } from "../context";
import { PropertyCard } from "../commons/propertyCard";

/** Lists all accessable properties for the current user */
export class ListOfPropertiesView extends KosmicComponent<{}> {
  @Consume(AppContext)
  protected app!: AppContext;

  render() {
    const properties = this.app.availableProperties;
    return (
      <div className="ui middle aligned content">
        <h2 className="ui header">
          <i className="brown map pin icon" />
          <div className="content">
            <span>Välj projekt</span>
            <div className="sub header">
              Du har tillgång till {properties.length} projekt.
            </div>
          </div>
        </h2>
        <div className="large vertical fluid ui menu">
          {properties.map((property) => (
            <PropertyCard key={property.id} property={property} />
          ))}
        </div>
        <div
          style={{ float: "right", paddingRight: "3em" }}
          className="sub header"
        >
          Använd dessa för att checka in och ut{" "}
          <i className="pointing up icon" />
        </div>
      </div>
    );
  }
}
