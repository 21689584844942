import * as React from "react";
import Cookie from "js-cookie";

import { Transition } from "./transition";

const styles: { [key: string]: React.CSSProperties } = {
  root: {
    position: "fixed",
    bottom: "0.8em",
    left: "50%",
    transform: "translateX(-50%)",
    width: "calc(100% - 1.6em)",
    maxWidth: "44em",
    zIndex: 100000,
  },
  text: {
    color: "#2A2A2A",
    fontSize: "0.9em",
    textAlign: "left",
    margin: 0,
    paddingRight: "1.5em",
  },
};

/**
 * Banner for allowing the user to consent to our use of cookie
 * Neccessary for being compliant with the GDPR regulation.
 */
export class CookieConsentBanner extends React.Component<
  {},
  { hasConsent: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      hasConsent:
        RUNTIME_ENVIRONMENT == "browser"
          ? !!Cookie.get("cookie-consent") || false
          : false,
    };
  }

  consendToUsage = () => {
    // Consent given is valid for a year
    Cookie.set("cookie-consent", "true");
    this.setState({ hasConsent: true });
  };

  get policyUrl() {
    const hostname = window.location.hostname;
    const policyHostname =
      RUNTIME_BRANCH == "local" ? "localhost:1337" : "www.hiveandfive.se";

    return `http://${policyHostname}/policies/privacy?ref=${hostname}&lang=sv`;
  }

  get shouldDisplay() {
    if (RUNTIME_ENVIRONMENT != "browser") {
      return false;
    }
    if (this.state.hasConsent) {
      return false;
    }
    try {
      return window.self == window.top;
    } catch (e) {
      return false;
    }
  }

  render() {
    if (!this.shouldDisplay) {
      return null;
    }

    return (
      <div style={styles.root}>
        <Transition in animation="fly up">
          <Transition animation="bounce" delay={10000} duration={1000}>
            <div
              className="ui floating icon message"
              style={{ backgroundColor: "#FFFFFFF8" }}
            >
              <i className="close icon" onClick={this.consendToUsage} />
              <span style={styles.text}>
                Vi använder webbkakor för bästa möjliga upplevelse. Om du
                fortsätter innebär det att du accepterar att kakor
                används.&nbsp;&nbsp;&nbsp;
                <a target="_blank" href={this.policyUrl}>
                  Läs mer.
                </a>
              </span>
            </div>
          </Transition>
        </Transition>
      </div>
    );
  }
}
