import * as React from "react";
import * as ReactDom from "react-dom";
import * as MobxReact from "mobx-react";
import Moment from "moment-timezone";

import { GlobalDeviceManager, GlobalSessionManager } from "../../stores";

/** Default properties for all components */
export interface DefaultComponentProps {
  style?: React.CSSProperties;
  className?: string;
  children?: any;
  /** Only available on route components */ location?: any;
}

/**
 * The base component for all other components in the submodule
 */
export abstract class Component<Props, State = {}> extends React.Component<
  Props & DefaultComponentProps,
  State
> {
  /** The DOM/JQuery node for this component when mounted */
  protected get domElement() {
    return $(ReactDom.findDOMNode(this) as Element);
  }

  render() {
    return null as any;
  }
}

/**
 * The observing base component for all other components in the submodule
 */
@MobxReact.observer
export class MobxComponent<Props, State = {}> extends Component<Props, State> {
  declare context: any; // NOTE: (old) added this to resolve type errors in editor

  protected get globals() {
    return {
      device: GlobalDeviceManager,
      session: GlobalSessionManager,
    };
  }

  protected formatDate(date: Date, format: string) {
    return Moment(date).tz("Europe/Stockholm").format(format);
  }
}

/** This is used for typeguarding between the prototype of mobxcomponents, and other components. */
(MobxComponent.prototype as any).isAMobxComponent = true;

/**
 * Brand New MobxComponent, now 100% mobx free!
 */
export class KosmicComponent<Props, State = {}> extends Component<
  Props,
  State
> {
  declare context: any; // NOTE: (old) added this to resolve type errors in editor

  /**
   * Await state is a wrapper around Component.setState
   * which makes it a async call.
   */
  protected awaitState<K extends keyof State>(
    state: Pick<State, K> | State
  ): Promise<State> {
    return new Promise((resolve) => {
      this.setState(state, () => {
        resolve(state as any);
      });
    });
  }

  protected get globals() {
    return {
      device: GlobalDeviceManager,
      session: GlobalSessionManager,
    };
  }
  protected formatDate(date: Date, format: string) {
    return Moment(date).tz("Europe/Stockholm").format(format);
  }
}
