import React from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { SemanticDatepickerProps } from "react-semantic-ui-datepickers/dist/types";

interface Props {
  setDateRange: (dateRange: Date[]) => void;
}

const RangedDatePicker = ({ setDateRange }: Props) => {
  const onChange = (_, data: SemanticDatepickerProps) =>
    setDateRange(data.value as Date[]);

  return (
    <SemanticDatepicker
      locale="sv-SE"
      onChange={(_, data) => {
        onChange(_, data);
      }}
      type="range"
      datePickerOnly
      format="YYYY-MM-DDTHH:mm:ss"
      inline
      showToday={false}
      clearable
      allowOnlyNumbers
      clearOnSameDateClick
    />
  );
};

export default RangedDatePicker;
