import { includes } from "lodash";
import * as React from "react";
import {
  CardReaderDocument,
  CardReaderResource,
} from "../../resources/reader.resource";
import { KosmicComponent, Table } from "../commons";
import { CardReaderForm } from "./cardReaderForm";
import { CardReadingTable } from "./readingsTable";

interface State {
  readers: CardReaderDocument[];
  expandedRows: string[];
  editingReader: boolean;
  editedDocument?: CardReaderDocument;
}
interface Props {}

export class CardReaderView extends KosmicComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      readers: [],
      expandedRows: [],
      editingReader: false,
    };
  }

  async componentDidMount() {
    const organization = this.globals.session.currentOrganizationId;
    const readers = await new CardReaderResource().populateProperty({
      organization,
    });
    this.setState({ readers });
  }

  toggleExpandRow(id: string) {
    let expandedRows = [...this.state.expandedRows];

    if (this.isExpanded(id)) {
      expandedRows = expandedRows.filter((row) => row !== id);
    } else {
      expandedRows.push(id);
    }

    this.setState({ expandedRows });
  }

  isExpanded(id: string) {
    return includes(this.state.expandedRows, id);
  }

  render() {
    const { editingReader, editedDocument } = this.state;
    if (editingReader) {
      // A document is being edited or a new one has been requested.
      const cancel = () =>
        this.setState({ editingReader: false, editedDocument: undefined });
      const submit = () => location.reload();
      return (
        <>
          <h2>{editedDocument ? "Ändra" : "Skapa"} kortläsare</h2>
          <p>Här kan du ändra eller skapa en ny kortläsare.</p>
          <div className="ui segment">
            <CardReaderForm
              document={editedDocument}
              onCancel={cancel}
              onSubmit={submit}
            />
          </div>
        </>
      );
    }
    return (
      <div>
        <h2>Kortläsare</h2>
        <p>
          Här listas alla aktiva kortläsare. Det går att lägga till, ta bort och
          ändra kortläsare, samt se historiken över vilka kort en som har lästs
          av en kortläsare.
        </p>
        <Table
          dataList={this.state.readers}
          headers={[
            { title: "Namn", sortBy: "label" },
            { title: "Projekt" },
            { title: "" },
          ]}
          initSortColumn="label"
          renderRow={(reader) => (
            <React.Fragment key={reader.id}>
              <tr>
                <td>{reader.label}</td>
                <td>{reader.property ? reader.property.name : ""}</td>
                <td style={{ textAlign: "right" }}>
                  <button
                    className="ui basic icon button"
                    onClick={() =>
                      this.setState({
                        editedDocument: reader,
                        editingReader: true,
                      })
                    }
                  >
                    <i className="edit icon" /> Redigera
                  </button>
                  <button
                    className="ui primary icon button"
                    onClick={() => this.toggleExpandRow(reader.id)}
                  >
                    <i className="book icon" />
                    &nbsp;
                    {this.isExpanded(reader.id) ? "Göm" : "Visa"} dagens
                    läsningar
                  </button>
                </td>
              </tr>
              {this.isExpanded(reader.id) && (
                <tr style={{ borderTop: "none" }}>
                  <td colSpan={4} style={{ borderTop: "none" }}>
                    <CardReadingTable reader={reader.id} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          )}
        />
      </div>
    );
  }
}
