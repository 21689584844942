import Lodash from "lodash";
import * as React from "react";
import { Alert, ConfirmationButton, Input, KosmicComponent } from "../commons";
import { Link } from "../navigation";

import { UserDocument, UserResource } from "../../resources/user.resource";
import { ProtectedTableCell } from "../commons/protectedTableCell";

interface Props {
  showArchived?: boolean;
}
interface State {
  employees: UserDocument[];
  loading: boolean;
  filter: string;
}

export class ListOfEmployeesView extends KosmicComponent<Props, State> {
  state: State = {
    employees: [],
    loading: true,
    filter: "",
  };

  /** Fetches all employees for the current organization and store them in state */
  private async fetchEmployees() {
    await this.setState({ loading: true });

    const employees = await new UserResource().find(
      {
        deleted: this.props.showArchived,
      },
      undefined
      // ,true NOTE: (2023) I think this toggled between archived and active employees
    );

    this.setState({
      employees: Lodash.sortBy(employees, ["lastname", "firstname"]),
      loading: false,
    });
  }

  /** Determines if a employee should be rendered based on the entered search filter */
  private determineDocumentInclusion(employee: UserDocument) {
    // Show based on archivation
    const visible = !!this.props.showArchived == !!employee.deleted;
    // Trim and normalize the entered search filter
    const search = this.state.filter.toLowerCase().trim();
    // Combined and normalize the document fields to search in
    const data =
      `${employee.id}|${employee.company}|${employee.name}`.toLowerCase();
    // If no search string has been entered, true will be returned, otherwise we search in the data string for the inclusion of search
    return visible && (!search.length || Lodash.includes(data, search));
  }

  /**
   * Archives a user.
   */
  private async toggleArchiveEmployee(employee: UserDocument) {
    const { showArchived } = this.props;
    const resource = new UserResource();
    try {
      if (showArchived) {
        await resource.restore(employee._id);
      } else {
        await resource.delete(employee.id, { removeRecord: false });
      }
    } catch (err) {
      Alert.show(
        `Kunde inte ${showArchived ? "aktivera" : "arkivera"} personal.`,
        "Ett fel uppstod",
        "error"
      );
    } finally {
      await this.fetchEmployees();
    }
  }

  componentDidMount() {
    // Fetch employees when the view is loaded
    this.fetchEmployees();
  }

  // NOTE: instead of fetching all employees we base the fetch on the showArchived prop.
  // After the last update find() did not return deleted: true defaults.
  componentDidUpdate(prevProps: Props): void {
    //Fetch archived employees
    if (prevProps.showArchived !== this.props.showArchived)
      this.fetchEmployees();
  }

  get toggleArchivedEmployeeButton() {
    if (this.props.showArchived) {
      return (
        <Link
          to="/admin/employees"
          className="ui compact labeled icon button"
          style={{ marginBottom: "10px", float: "right" }}
        >
          <i className="eye icon" />
          Visa aktiv personal
        </Link>
      );
    }
    return (
      <Link
        to="/admin/employees/archived"
        className="ui compact labeled icon button"
        style={{ marginBottom: "10px", float: "right" }}
      >
        <i className="eye icon" />
        Visa arkiverad personal
      </Link>
    );
  }

  private renderEmployee(employee: UserDocument) {
    const { showArchived } = this.props;
    return (
      <tr key={employee.id}>
        <td>{employee.company}</td>
        <td>{employee.name}</td>
        <ProtectedTableCell content={employee.personNr} />
        <td>
          <Link
            to={"/admin/employees/" + employee.id}
            className="ui compact tiny basic icon button"
          >
            <i className="edit icon" /> Redigera
          </Link>
          <Link
            to={"/admin/employees/statistics/" + employee._id}
            className="ui compact tiny basic icon button"
          >
            <i className="bar chart icon" /> Statistik
          </Link>
          <ConfirmationButton
            className="tiny compact icon basic"
            timeout={3000}
            label={
              <>
                <i className={showArchived ? "undo icon" : "archive icon"} />{" "}
                {showArchived ? "Aktivera" : "Arkivera"}
              </>
            }
            activeButton={
              <button
                onClick={this.toggleArchiveEmployee.bind(this, employee)}
                className="ui compact tiny red icon button"
              >
                Är du säker?
              </button>
            }
          />
        </td>
      </tr>
    );
  }

  render() {
    const { showArchived } = this.props;
    return (
      <div>
        <h2>{showArchived && "Arkiverade"} Personer</h2>
        {this.toggleArchivedEmployeeButton}
        <Link
          to="/admin/employees/add"
          className="ui compact labeled icon green button"
          style={{ marginBottom: "10px", float: "right" }}
        >
          <i className="plus icon" />
          Lägg till en person
        </Link>
        <p>
          Visar alla personer som{" "}
          {showArchived
            ? "har arkiverats"
            : "någon gång loggat in eller registrerats"}
        </p>
        <br />
        <Input
          icon="search"
          placeholder="Sök efter en person"
          onChange={(value) => this.setState({ filter: value })}
          value={this.state.filter}
          name="personalFilterInput"
          fluid
        />
        <table className="ui celled selectable stackable table">
          <thead>
            <tr>
              <th>Företag</th>
              <th>Namn</th>
              <th className="three wide">Personnummer</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {this.state.employees
              .filter((employee) => this.determineDocumentInclusion(employee))
              .map(this.renderEmployee.bind(this))}
          </tbody>
        </table>
      </div>
    );
  }
}
