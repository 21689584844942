import * as React from "react";
import { range, map } from "lodash";
import { KosmicComponent } from "../../commons";
import { Consume, DeviceProvider, IDevice } from "../../../contexts";
import { DimmerProps } from "./dimmerProps";

export interface Props {
  /** The title to render */
  title: string;
  /** The body text to render */
  body: string;
  /** The passcode to match to resolve successfully */
  passcode: string;
}

interface State {
  /** The currently entered passcode */
  entry: string;
}

/** Renders the contents of a passcode dimmer modal */
export class PasscodeDimmerContent extends KosmicComponent<
  Props & DimmerProps,
  State
> {
  state: State = {
    entry: "",
  };

  @Consume(DeviceProvider.Context)
  private device: IDevice;

  async componentDidMount() {
    // Imidiantly return a success if no passcode was given
    if (!this.props.passcode || !this.props.passcode.length) {
      this.props.onHide(true);
    }
  }

  /** Callback for when a number button is pressed */
  private async onNumberPressed(value: number) {
    const { passcode } = this.props;
    let { entry } = this.state;

    // Do not enter more than the allowed number of characters
    if (entry.length >= passcode.length) {
      return;
    }

    // Update the entry value
    entry = `${entry}${value}`;
    this.setState({ entry });

    // Do nothing more until all characters have been entered
    if (entry.length < passcode.length) {
      return;
    }
    // Resolve and hide the dimmer when done
    this.props.onHide(entry == passcode);
  }

  /** Callback for when the erase/cancel button is pressed */
  private async onErasePressed() {
    const { entry } = this.state;

    // Close the dimmer if there are no characters left to erase
    if (!entry.length) {
      return this.props.onHide(false);
    }

    // Erase the latest entired character
    this.setState({ entry: entry.slice(0, -1) });
  }

  render() {
    const { passcode, title, body } = this.props;
    const { entry } = this.state;
    const h1FontSize: string =
      this.device.size == "desktop"
        ? "2.2em"
        : this.device.size == "tablet"
        ? "1.8em"
        : "1.6em";
    const h2FontSize: string =
      this.device.size == "desktop"
        ? "2em"
        : this.device.size == "tablet"
        ? "1.5em"
        : "1.4em";
    const buttonSize: string =
      this.device.size == "desktop"
        ? "massive"
        : this.device.size == "tablet"
        ? "huge"
        : "large";
    const cancelIcon = entry.length ? "left arrow" : "cancel";

    return (
      <div
        className="ui three column centered middle aligned grid"
        style={{ padding: "1em" }}
      >
        <div className="row" style={{ textAlign: "center" }}>
          <h1 style={{ fontSize: h1FontSize }}>{title}</h1>
        </div>
        <div className="row" style={{ textAlign: "center" }}>
          <h2 style={{ fontSize: h2FontSize }}>{body}</h2>
        </div>
        <div className="row" style={{ textAlign: "center" }}>
          <h2>
            {map(range(0, entry.length), (i) => (
              <i className="inverted circle icon" key={i} />
            ))}
            {map(range(entry.length, passcode.length), (i) => (
              <i className="inverted thin circle outline icon" key={i} />
            ))}
          </h2>
        </div>
        <div className="row">
          <div className={`${buttonSize} ui buttons`}>
            <PasscodeEntryButton
              value={1}
              onClick={this.onNumberPressed.bind(this, 1)}
            />
            <PasscodeEntryButton
              value={2}
              onClick={this.onNumberPressed.bind(this, 2)}
            />
            <PasscodeEntryButton
              value={3}
              onClick={this.onNumberPressed.bind(this, 3)}
            />
          </div>
        </div>
        <div className="row">
          <div className={`${buttonSize} ui buttons`}>
            <PasscodeEntryButton
              value={4}
              onClick={this.onNumberPressed.bind(this, 4)}
            />
            <PasscodeEntryButton
              value={5}
              onClick={this.onNumberPressed.bind(this, 5)}
            />
            <PasscodeEntryButton
              value={6}
              onClick={this.onNumberPressed.bind(this, 6)}
            />
          </div>
        </div>
        <div className="row">
          <div className={`${buttonSize} ui buttons`}>
            <PasscodeEntryButton
              value={7}
              onClick={this.onNumberPressed.bind(this, 7)}
            />
            <PasscodeEntryButton
              value={8}
              onClick={this.onNumberPressed.bind(this, 8)}
            />
            <PasscodeEntryButton
              value={9}
              onClick={this.onNumberPressed.bind(this, 9)}
            />
          </div>
        </div>
        <div className="row">
          <div className={`${buttonSize} ui buttons`}>
            <PasscodeEntryButton
              value={0}
              onClick={this.onNumberPressed.bind(this, 0)}
            />
            <PasscodeEntryButton
              value={
                <i className={cancelIcon + " icon"} style={{ margin: 0 }} />
              }
              onClick={this.onErasePressed.bind(this)}
            />
          </div>
        </div>
        <div className="row" style={{ height: "2rem" }} />
      </div>
    );
  }
}

/** Helper function for rendering a input button in the dimmer content */
function PasscodeEntryButton(props: {
  /** Numeric value or react element or to render */
  value: number | React.ReactNode;
  /** onClick callback */
  onClick: () => Promise<void>;
  /** Optional class name override */
  className?: string;
}) {
  /** Determines if the onTouchEnd is available */
  const hasTouchEnd = "onTouchEnd" in document.documentElement;
  /** Wraps the callback method preventing default behaivors */
  async function onInteraction(event: React.SyntheticEvent<HTMLAnchorElement>) {
    event.preventDefault();
    event.stopPropagation();
    await props.onClick();
  }
  return (
    <a
      style={{
        width: "86px",
        background: "#000",
        color: "#fff",
        pointerEvents: "all",
        textAlign: "center",
      }}
      className={"inverted ui password button " + props.className || ""}
      onTouchEnd={hasTouchEnd ? onInteraction : undefined}
      onClick={hasTouchEnd ? undefined : onInteraction}
    >
      {props.value}
    </a>
  );
}
