import * as React from "react";
import { Icon } from "./_icon";
import { FormMemberProps, FormStatus } from "./form";
import { TextFormatRule } from "./rules";

interface DropdownProps extends FormMemberProps {
  /** A list of rules that applies to this component */ rules?: TextFormatRule[];
  /** An icon to display inside the input */ icon?: Icon;
  /** A placeholder shown inside the input when otherwise empty */ placeholder?: string;
  /** The value set for when it hasn't been otherwise modified */ defaultValue?:
    | string
    | number;
  /** Makes the dropdown take up the whole width of it's parent container */ fluid?: boolean;
  /** Multiple items can be selected */ multiple?: boolean;
  /** if items can be filtered through search  */ searchable?: boolean;
  /** Override style properties */ style?: object;
  onChange?: (value: string) => void;
}

export const Dropdown: React.FunctionComponent<DropdownProps> = (props) => {
  const [isLoading, setLoading] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(false);
  const form = React.useContext(FormStatus);
  const _domElement = React.useRef(null);
  // Set status
  const loading = isLoading || form.loading || props.loading;
  const disabled =
    isDisabled || isLoading || form.disabled || form.loading || props.disabled;

  // Lifecycle
  React.useEffect(() => {
    $(_domElement.current as any).dropdown({
      onChange: (value) => {
        form.addValue(props.name, value);
      },
    });
    if (props.rules) {
      form.addValidationRules(props.name, props.rules, !!props.optional);
    }
  }, []);

  React.useEffect(() => {
    if (props.defaultValue) {
      $(_domElement.current as any).dropdown("refresh"); // Check for changed children
      $(_domElement.current as any).dropdown(
        "set exactly",
        String(props.defaultValue).split(",")
      );
    }
  }, [props.defaultValue]);

  // Set default properties for the input element
  const inputProps = {
    disabled: disabled,
    type: "hidden",
    name: props.name,
    placeholder: props.placeholder,
  };

  const style = props.style || {};
  const className =
    "ui selection dropdown " +
    (props.className || "") +
    (props.fluid ? "fluid " : "") +
    (props.multiple ? "multiple " : "") +
    (props.searchable ? "search " : "") +
    (loading ? "loading " : "") +
    (disabled ? "disabled " : "");

  return (
    <div className={className} style={style} ref={_domElement}>
      <input {...inputProps} name={props.name} />
      {props.icon && <i className={props.icon + " ui left icon"} />}
      <i className="dropdown icon" />
      <div className="default text">{props.placeholder}</div>
      <div className="menu">{props.children}</div>
    </div>
  );
};

export class DropdownItem extends React.Component<
  { className?: string; value?: string | number; children?: any },
  any
> {
  render() {
    return (
      <div
        className={"item " + (this.props.className || "")}
        data-value={this.props.value || this.props.children}
      >
        {this.props.children || this.props.value}
      </div>
    );
  }
}
