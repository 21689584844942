import * as React from "react";
import { ReaderCommunicationContext } from "../../../contexts/readerComs";
import { CardResource } from "../../../resources/card.resource";
import { PropertyResource } from "../../../resources/property.resource";
import { UserDocument, UserResource } from "../../../resources/user.resource";
import {
  Button,
  Field,
  Fields,
  Form,
  Input,
  KosmicComponent,
  Modal,
  Rules,
  SubmitButton,
} from "../../commons";
import { AppContext, Consume } from "../context";

export interface State {
  /** Search status */
  search: {
    /** Indicates that a search is being performed */
    loading: boolean;
    /** Any user document found when searching */
    foundUser: UserDocument | undefined;
  };
  /** Manual information entered, as opposite to search results */
  values: {
    personNr: string;
    firstname: string;
    lastname: string;
    company: string;
    cardNumber: string;
    orgNr?: string;
    id06?: string;
  };
  showId06Field: boolean;
}

/** View for checking in or creating a new user manually at the selected property */
export class CheckInView extends KosmicComponent<{}, State> {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        loading: false,
        foundUser: undefined,
      },
      values: {
        personNr: "",
        firstname: "",
        lastname: "",
        company: "",
        cardNumber: "",
        orgNr: undefined,
        id06: undefined,
      },
      showId06Field: true,
    };
    this.onBorasCardClick = this.onBorasCardClick.bind(this);
  }

  private newUserModal: Modal;

  @Consume(AppContext)
  protected app!: AppContext;

  @Consume(ReaderCommunicationContext)
  private readerCom!: ReaderCommunicationContext;

  /** Returns the correct form values either from a search result or manually entered information */
  private get formValues(): State["values"] {
    const { values: entryValues } = this.state;
    const { foundUser } = this.state.search;

    // Get the correct form values by merging any found user document with manualy entered information
    return {
      personNr: entryValues.personNr,
      firstname: foundUser ? foundUser.firstname : entryValues.firstname,
      lastname: foundUser ? foundUser.lastname : entryValues.lastname,
      company: foundUser ? foundUser.company || "" : entryValues.company,
      orgNr: foundUser ? foundUser.orgNr : entryValues.orgNr,
      cardNumber: this.readerCom.manualSignInRFID
        ? this.readerCom.manualSignInRFID
        : entryValues.cardNumber,
      id06: entryValues.id06,
    };
  }

  /** Handles the submitting of the check in form */
  private async onSubmit(_, resolve, reject) {
    const { selectedProperty } = this.app;

    try {
      const { employee: checkedInUser, newUser } =
        await new PropertyResource().manualSignIn(
          selectedProperty!,
          this.formValues
        );

      // Failed to find or create the specified user
      if (!checkedInUser) {
        throw new Error("Failed to find or create a user");
      }

      // Check if user has a card, or has requsted one
      const cards = await new CardResource().populateCardUsers({
        userId: checkedInUser._id,
      });
      if (this.formValues.cardNumber && !cards.length) {
        const values = {
          user: checkedInUser,
          rfid: this.formValues.cardNumber,
          selfRegistered: true,
          id06: this.formValues.id06,
        };
        const document = new CardResource().createDocument(values);
        await new CardResource().updateDocument(document);
      }

      // If user is new, show a modal with the users information
      if (newUser) {
        setTimeout(
          () => this && this.newUserModal && this.newUserModal.hide(),
          4000
        );
        return this.newUserModal.show();
      }
      // Check in is completed!
      await this.app.updateState({ guiSigningInManually: false });
    } catch (err) {
      // Failed to check in at the selected property
      const formElemet = this.domElement.find(".view-form");
      formElemet.transition("shake");
      console.error("Check in failed", err);
      reject();
      return;
    }
  }

  /** Performs a search for the entered ssn number and autofills the form if a result is found */
  private async onSearch() {
    const personNr = this.state.values.personNr;
    // TODO: (old) what is this magic?
    // var newValue = event.target.value.replace(/[^0-9]/g, '').substring(0, 12);
    // if (newValue.length >= 10 && newValue.substring(0, 2) != "19" && newValue.substring(0, 2) != "20") {
    // 	newValue = "19" + newValue;
    // }
    try {
      this.setState({ search: { loading: true, foundUser: undefined } });

      // TODO: (old) validate ? if (element.form('is valid')) {
      const foundUsers = await new UserResource().find({ personNr: personNr });

      // No matching users found
      if (!foundUsers || !foundUsers.length) {
        throw new Error("No matching users found.");
      }

      // Save the first found user
      this.setState({ search: { loading: false, foundUser: foundUsers[0] } });
      // Also clear error messsages
      $('[name="firstname"]').blur();
      $('[name="lastname"]').blur();
      $('[name="orgNr"]').blur();
      $('[name="company"]').blur();
    } catch (err) {
      // Failed to find any matching user
      const formElemet = this.domElement.find(".view-form");
      formElemet.transition("shake");
      this.setState({ search: { loading: false, foundUser: undefined } });
      console.error("Search failed", err);
      return;
    }
  }

  /** Handles changes to the ssn and makes sure its formatted correctly */
  private async onSSNChange(value: string) {
    value = value.replace("-", "").trim();
    this.setState({ values: { ...this.state.values, personNr: value } });
  }

  //** Handle the changes for the checkbox to show or hide id06 field*/
  private onBorasCardClick = () => {
    const { showId06Field } = this.state;
    this.setState({ showId06Field: !showId06Field }, () => {
      $(this.domElement.find(".submit-button")).removeClass("disabled");
    });
  };

  readonly id06CardRule = Rules.CustomRule({
    validator: (x) => /(^[A-Z]{2}-[0-9]{4}-[0-9]{6}$)|^$/.test(x),
    prompt: "Du måste ange enligt följande format: AA-0000-00000",
  });

  private renderId06Field() {
    if (!this.readerCom.manualSignInRFID) {
      return null;
    }

    const onChange = (value: string) => {
      const { id06 } = this.state.values;
      if (
        (id06 && id06.length == 1 && value.length == 2) ||
        (id06 && id06.length == 6 && value.length == 7)
      ) {
        value += "-";
      }

      this.setState({ values: { ...values, id06: value.toUpperCase() } });
    };

    const values = this.formValues;
    return (
      <div style={{ margin: "2em 0em" }}>
        <div className="ui divider" />
        {this.state.showId06Field && (
          <Field>
            <Input
              className="manualentry"
              name="id06"
              placeholder="ID06 kortnummer (LL-XXXX-XXXXXX)"
              value={values.id06}
              onChange={onChange}
              rules={[
                Rules.NotEmpty("Du måste ange ditt id06 kortnummer"),
                Rules.ExactLength(
                  14,
                  "Ange ditt id06 med 12 tecken (LL-XXXX-XXXXXX)"
                ),
                this.id06CardRule,
              ]}
            />
          </Field>
        )}
        <div className="ui checkbox">
          <input
            type="checkbox"
            name="checkboxBorasCard"
            onClick={this.onBorasCardClick}
          />
          <label>Jag har ett tjänstekort från Borås Stad</label>
        </div>
      </div>
    );
  }

  private renderForm() {
    const { loading, foundUser } = this.state.search;
    const values = this.formValues;

    // Indicates that the form is locked from editing, if we have search results or is searching
    const locked = !!foundUser || loading;

    return (
      <Form
        className="ui floating segment view-form"
        onSubmit={this.onSubmit.bind(this)}
      >
        <Field label="Personnummer">
          <Input
            key="ssn"
            icon={!!foundUser ? "checkmark" : "user"}
            name="ssn"
            placeholder="ååmmddxxxx"
            value={values.personNr}
            onChange={(value) => this.onSSNChange(value)}
            disabled={locked}
            loading={loading}
            rules={[
              Rules.ExactLength(
                10,
                "Ange ditt personnummer med 10 siffror (ÅÅMMDDXXXX)"
              ),
              Rules.NotEmpty("Du måste ange ditt personnummer"),
              Rules.IsANumber(
                false,
                "Ditt personnummer måste anges med siffror"
              ),
            ]}
          />
        </Field>
        <Button
          disabled={locked || values.personNr.length != 10}
          onClick={this.onSearch.bind(this)}
          className="fluid large gray"
        >
          <i className="search icon" />
          Sök upp mig
        </Button>
        <div style={{ height: "1rem" }} />
        <div className="ui divider" />
        <Fields>
          <Field className="eight wide">
            <Input
              className="manualentry"
              name="firstname"
              placeholder="förnamn"
              value={values.firstname}
              onChange={(firstname) =>
                this.setState({ values: { ...values, firstname } })
              }
              disabled={locked}
              rules={[Rules.NotEmpty("Du måste ange namnet på ditt förnamn")]}
            />
          </Field>

          <Field className="eight wide">
            <Input
              className="manualentry"
              name="lastname"
              placeholder="efternamn"
              value={values.lastname}
              onChange={(lastname) =>
                this.setState({ values: { ...values, lastname } })
              }
              disabled={locked}
              rules={[Rules.NotEmpty("Du måste ange ditt efternamn")]}
            />
          </Field>
        </Fields>

        <Field>
          <Input
            className="manualentry"
            name="orgNr"
            placeholder="organisationsnummer"
            value={values.orgNr}
            onChange={(orgNr) =>
              this.setState({ values: { ...values, orgNr } })
            }
            disabled={locked}
            rules={[Rules.NotEmpty("Du måste ange ett organisationsnummer.")]}
          />
        </Field>
        <Field>
          <Input
            className="manualentry"
            name="company"
            placeholder="företagsnamn"
            value={values.company}
            onChange={(company) =>
              this.setState({ values: { ...values, company } })
            }
            disabled={locked}
            rules={[
              Rules.NotEmpty("Du måste ange namnet på ditt primära företag"),
            ]}
          />
        </Field>

        {this.renderId06Field()}

        <SubmitButton
          disabled={loading}
          className="fluid large green submit-button"
        >
          <i className="checkmark icon" />
          {this.readerCom.manualSignInRFID
            ? "Registrera & Checka in"
            : "Checka in"}
        </SubmitButton>
      </Form>
    );
  }

  render() {
    return (
      <div className="ui container">
        <Modal
          header="Du är nu registrerad!"
          ref={(modal) => (this.newUserModal = modal as Modal)}
          onHide={() => {
            this.readerCom.setState({ manualSignInRFID: undefined });
            this.app.updateState({ guiSigningInManually: false });
          }}
          basic
          style={{ textAlign: "center" }}
        >
          <div className="content">
            <p>Din passerkod är de 6 första i ditt personnummer.</p>
            {this.readerCom.manualSignInRFID && (
              <p>Du kan nu även checka in/ut med ditt kort!</p>
            )}
          </div>
        </Modal>

        <div className="ui center aligned middle aligned content">
          <h1 className="ui header">
            <div className="content">
              <span>
                {this.readerCom.manualSignInRFID
                  ? "Självregistrering av kort"
                  : "Checka in med personuppgifter"}
              </span>
              <div className="sub header"></div>
            </div>
          </h1>
          {this.renderForm()}
        </div>
      </div>
    );
  }
}
