import * as React from "react";
import { EmployeeSearchPredicate } from "../../../components/utils";
import { ReaderCommunicationContext } from "../../../contexts/readerComs";
import { KosmicComponent, SearchableList } from "../../commons";
import { EmployeeCard } from "../commons/employeeCard";
import { AppContext, Consume } from "../context";

/** Allows for avanced management of the selected property */
export class PropertyDetailsView extends KosmicComponent<{}> {
  @Consume(AppContext)
  protected app!: AppContext;
  @Consume(ReaderCommunicationContext)
  private readerComs!: ReaderCommunicationContext;

  componentDidMount(): void {
    console.log(this.readerComs);
    let propertyId: string = this.app.selectedProperty!.id;

    this.readerComs.socket.emit("registration", { propertyId });
  }

  componentWillUnmount() {
    let propertyId: string = this.app.selectedProperty!.id;
    this.readerComs.socket.emit("unregister", { propertyId });
  }

  private renderListOfEmployees() {
    const { selectedPropertyEmployees } = this.app;

    if (!selectedPropertyEmployees.length) {
      return <span>Det finns ingen personal på arbetsplatsen</span>;
    }

    return (
      <SearchableList
        showDefaultSearchBar={false}
        predicate={EmployeeSearchPredicate}
        items={selectedPropertyEmployees}
        template={(props) => <EmployeeCard employee={props.item} />}
      />
    );
  }

  render() {
    const property = this.app.selectedProperty!;
    const organization = this.app.selectedOrganization!;

    return (
      <div className="ui middle aligned content">
        <h1 className="ui header">
          <div className="content">
            <span>{property.name}</span>
            <div className="sub header">
              <br />
              Från den här vyn kan du checka in och ut all personal som är
              uppskrivna på projektet.
              <br />
              <br />
              Du kan också välja att använda den här enheten som en öppen enhet
              att användas av att personalen för självadministration.
            </div>
          </div>
        </h1>
        <div className="ui large relaxed horizontal list">
          <div className="item">
            <div className="header">Projektet tillhör:</div>
            {organization.name}
          </div>
          <div className="item">
            <div className="header">Klassifikation:</div>
            {property.classification}
          </div>
          <div className="item">
            <div className="header">Område:</div>
            {property.district}
          </div>
        </div>
        <h3 className="ui header">
          <div className="content">
            <span>Projektets personallista</span>
            <div className="sub header">
              Du kan <b>checka in</b> och <b>ut</b> en användare genom att
              klicka på deras kort:
            </div>
          </div>
        </h3>
        {this.renderListOfEmployees()}
      </div>
    );
  }
}
